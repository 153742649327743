export const isDefined = <T>(
	value?: T
): value is T => {
	return typeof value != "undefined"
}

export const isString = (
	value?: any
): value is string => {
	return typeof value == "string"
}

export const isNumber = (
	value?: any
): value is number => {
	return typeof value == "number"
}

export const isBoolean = (
	value?: any
): value is boolean => {
	return typeof value == "boolean"
}