import React from "react"
import { observer } from "mobx-react"

import "styles/views/homepage/components/users-list"

import { PublicUserData } from "typings/api/User"

import Meta from "stores/Meta"

import UserCardSearch from "components/Global/User/cards/Search"

export interface HomepageUsersListProps {
	users?: PublicUserData[]
}

export interface HomepageUsersListState {

}

@observer
export default
class HomepageUsersList
extends React.Component<HomepageUsersListProps, HomepageUsersListState> {
	render() {
		const { users } = this.props
		const { tabletBook, mobileLarge } = Meta.screenConditions
		const maxCount = tabletBook && !mobileLarge
			? 9
			: 10

		const usersToRender = users
			? users.length < maxCount
				? [...users, ...[...Array(maxCount - users.length)] as undefined[]]
				: users.slice(0, maxCount)
			: [...Array(maxCount)] as undefined[]

		return <>
			<div className="c-homepage-users-list">
				{(usersToRender).map((user, i) => {
					return <UserCardSearch
						key={i}
						user={user}
						index={i}
						invisible={!user && !!users}
					/>
				})}
			</div>
		</>
	}
}