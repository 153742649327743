import Auth from "stores/Auth"
import Confirms from "stores/Confirms"
import Meta from "stores/Meta"

import Logger from "utils/entities/Logger"

const logger = new Logger("sw.ts")

const serviceWorkerPath = `/service.worker.js`

const promptUpdate = async (
	registration: ServiceWorkerRegistration
) => {
	await Confirms.create(
		async () => registration.waiting?.postMessage("skipWaiting"),
		{
			title: "Update",
			description: `New version is available. Update now?`,
		}
	)
}

export const postMessageToWorker = (
	signal: string,
	data: any
) => {
	navigator.serviceWorker.controller?.postMessage({
		signal,
		data,
	})
}

export const register = async () => {
	logger.info("*register()* has been executed")
	logger.info(`Service worker URI is *${serviceWorkerPath}*`)

	if (!("serviceWorker" in navigator))
		return logger.error("Service workers *are not supported* by this browser")

	const alreadyRegistered = !!navigator.serviceWorker.controller

	if (alreadyRegistered)
		logger.warn("Service worker is *already registered*")
	else
		logger.info("No installed service workers found, registering...")

	const registration = alreadyRegistered
		? await navigator.serviceWorker.ready
		: await navigator.serviceWorker.register(serviceWorkerPath)

	if (alreadyRegistered)
		logger.warn("Succesfully retrieved registration of current service worker")

	if (registration.waiting) {
		if (alreadyRegistered)
			promptUpdate(registration)
		else
			registration.waiting.postMessage("skipWaiting")
	}

	registration.addEventListener("updatefound", () => {
		logger.info("Update found")
		registration.installing?.addEventListener("statechange", () => {
			if (registration.waiting) {
				if (navigator.serviceWorker.controller)
					promptUpdate(registration)
				else
					logger.info("Performing first installation")
			}
		})
	})

	navigator.serviceWorker.addEventListener("controllerchange", () => {
		window.location.reload()
	})

	navigator.serviceWorker.addEventListener("message", message => {
		logger.log("Message recieved from service worker", message)
		const content = message.data
		if (typeof content == "object" && typeof content.data?.tabId == "string" && content.data.tabId != Meta.tabId) {
			logger.log(`Applying *${content.signal}* signal`)
			switch (content.signal) {
				case "identity_changed":
					Auth.externalUpdate(content.data.account)
					break
				case "logout":
					Auth.externalUpdate()
					break
			}
		}
	})
}