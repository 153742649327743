import { action, makeObservable, observable } from "mobx"
import { generatePath } from "react-router-dom"
import { UserType } from "typings/Auth"
import { SettingsTab } from "typings/Settings"

export type TransitionBlocker =
	| "dirtySettings"

type ActiveBlocker = {
	type: TransitionBlocker
	confirm: () => void
	cancel: () => void
}

class RoutingTransitionsManager {
	private debugHeader
		: string
		= "[Route Transition Manager]"

	@action
	private setActiveBlocker = (
		blocker: ActiveBlocker
	) => {
		this.activeBlocker = blocker
	}

	@action
	private clearActiveBlocker = () => {
		this.activeBlocker = undefined
	}

	constructor() {
		makeObservable(this)
	}

	@observable
	activeBlocker?
		: ActiveBlocker
		= undefined

	blockers
		: TransitionBlocker[]
		= []

	setTransitionBlocker = (
		type: TransitionBlocker
	) => {
		if (this.blockers.includes(type))
			return console.hey(`${this.debugHeader}: Unable to set blocker *${type}*, because it's already been set.`)

		this.blockers.push(type)
	}

	unsetTransitionBlocker = (
		type: TransitionBlocker
	) => {
		const indexOf = this.blockers.indexOf(type)
		if (indexOf < 0)
			return console.hey(`${this.debugHeader}: Unable to unset blocker *${type}*, because it's already been unset.`)

		this.blockers.splice(indexOf, 1)
		if (this.activeBlocker?.type == type)
			this.clearActiveBlocker()
	}

	performBlockers = async (): Promise<boolean> => {
		var result: boolean = true
		for await (let blocker of this.blockers) {
			result = await new Promise<boolean>(resolve => {
				this.setActiveBlocker({
					type: blocker,
					confirm: () => resolve(true),
					cancel: () => resolve(false),
				})
			})
			if (!result) {
				this.clearActiveBlocker()
				break
			}
		}

		return result
	}
}

class Routing {
	transitionsManager = new RoutingTransitionsManager()

	scopes = {
		legal: "/legal",
		faq: "/faq",
		user: "/user",
		account: "/me",
	}

	patterns = {
		__playground: "/__playground",

		homepage: "/",
		signup: "/signup",
		search: "/search",

		legal: {
			terms: `${this.scopes.legal}/terms-of-use`,
			privacy: `${this.scopes.legal}/privacy-policy`,
			press: `${this.scopes.legal}/press`,
		},

		faq: {
			home: `${this.scopes.faq}`,
			tab: `${this.scopes.faq}/:tab`
		},

		account: {
			home: `${this.scopes.account}`,
			orders: {
				list: `${this.scopes.account}/orders`,
				item: `${this.scopes.account}/orders/:order`,
			},
			settings: {
				home: `${this.scopes.account}/settings`,
				tab: `${this.scopes.account}/settings/:tab`
			}
		},
		user: {
			profile: `${this.scopes.user}/:id`,
			order: `${this.scopes.user}/:id/order`
		},
	}

	get compile() {
		const {
			__playground,
			homepage,
			signup,
			search,

			legal,
			faq,

			account,
			user,
		} = this.patterns

		return {
			__playground: () => __playground,

			homepage: () => homepage,
			signup: () => signup,
			search: () => search,
			
			legal: {
				terms: () => legal.terms,
				privacy: () => legal.privacy,
				press: () => legal.press,
			},

			faq: {
				home: () => faq.home,
				tab: (tab: UserType) => generatePath(faq.tab, { tab })
			},
			
			account: {
				home: () => account.home,
				orders: {
					list: () => generatePath(account.orders.list),
					item: (order: string) => generatePath(account.orders.item, { order }),
				},
				settings: {
					home: () => generatePath(account.settings.home),
					tab: (tab: SettingsTab) => generatePath(account.settings.tab, { tab })
				},
			},

			user: (id: string) => {
				return {
					profile: () => generatePath(user.profile, { id }),
					order: () => generatePath(user.order, { id })
				}
			},
		}
	}
}

export default new Routing()