import React from "react"

import { OrderData } from "typings/api/Orders"

import OrderBadge from "../../../Badge"
import OrderStatusDescription from "../StatusDescription"

export interface OrderDefaultContentProps {
	order: OrderData
}

export interface OrderDefaultContentState {

}

export default
class OrderDefaultContent
extends React.Component<OrderDefaultContentProps, OrderDefaultContentState> {
	render() {
		const { order } = this.props
		return <>
			<div className="badges">
				<OrderBadge
					label="From"
					appearance={["outline", "compact"]}
				>
					{order.from}
				</OrderBadge>
				<OrderBadge
					label="To"
					appearance={["outline", "compact"]}
				>
					{order.to}
				</OrderBadge>
				{order.confidential &&
					<OrderBadge
						appearance={["info", "compact"]}
					>
						<i className="fas fa-lock" /> <span>
							Keep order confidential
						</span>
					</OrderBadge>
				}
			</div>
			<div>
				<label className="u-input-label">
					Order description
				</label>
				<p className="otc-text">
					{order.description}
				</p>
			</div>
			<OrderStatusDescription
				order={order}
			/>
		</>
	}
}