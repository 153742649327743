import React from "react"

import "styles/views/account/views/orders/components/badge"

export type OrderBadgeAppearance =
	| "positive"
	| "warning"
	| "negative"
	| "info"
	| "compact"
	| "outline"

export interface OrderBadgeProps
extends PropsWithChildren {
	appearance?: OrderBadgeAppearance[]
	label?: React.ReactNode
}

export interface OrderBadgeState {

}

export default
class OrderBadge
extends React.Component<OrderBadgeProps, OrderBadgeState> {
	render() {
		const { label, appearance } = this.props
		return <>
			<div className={`c-order-badge ${appearance?.join(" ")}`}>
				{label &&
					<div className="ob-label">
						{label}:
					</div>
				}
				<div className="ob-content">
					{this.props.children}
				</div>
			</div>
		</>
	}
}