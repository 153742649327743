import React from "react"
import { RouteComponentProps, Link } from "react-router-dom"

import "styles/views/not-found"

import Routing from "stores/Routing"
import Helmet from "components/Global/Helmet"

export interface NotFoundProps
extends RouteComponentProps<any> {
	
}

export interface NotFoundState {

}

export default
class NotFound
extends React.Component<NotFoundProps, NotFoundState> {
	render() {
		const { staticContext } = this.props
		if (staticContext)
			staticContext.statusCode = 404

		const title = "You picked the wrong house, fool!"
		const description = "You're not supposed to be on this page. It's better to go somewhere else."
		
		return <>
			<Helmet
				title={title}
				description={description}
			/>
			<main className="v-not-found u-fs-view u-fade-in">
				<h1>
					{title}
				</h1>
				<p className="u-dimmed">
					{description}
				</p>
				<div className="u-actions">
					<Link
						to={Routing.compile.homepage()}
						className="u-button default"
					>
						<div className="ub-inner-wrapper">
							<span>To Homepage</span>
						</div>
					</Link>
				</div>
			</main>
		</>
	}
}