import React from "react"
import { createRoot } from "react-dom/client"
import { Router } from "react-router-dom"
import { createBrowserHistory } from "history"
import * as sw from "utils/sw"

import { DefaultData } from "typings"

import Routing from "stores/Routing"

import Provider from "./Provider"
import Search from "stores/Search"
import Main from "stores/Main"

const root = createRoot(document.getElementById("__root")!)

const history = createBrowserHistory({
	getUserConfirmation: async (_, callback) => {
		const allowTransition = await Routing.transitionsManager.performBlockers()
		callback(allowTransition)
	}
})

const defaultData = (window as any).__APP_DEFAULT_DATA__ as DefaultData | undefined

Search.useHistory(history)
root.render(
	<Router history={history}>
		<Provider
			defaultData={defaultData}
		/>
	</Router>
)

if (!Main.isDev)
	sw.register()