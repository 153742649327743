import React from "react"
import { observer } from "mobx-react"
import { Prompt } from "react-router"
import Routing from "stores/Routing"
import RouteTransitionConfirm from "./components/Confirm"

export interface RouteTransitionConfirmsProps {

}

export interface RouteTransitionConfirmsState {

}

@observer
export default
class RouteTransitionConfirms
extends React.Component<RouteTransitionConfirmsProps, RouteTransitionConfirmsState> {
	render() {
		const { transitionsManager } = Routing
		return <>
			<Prompt message="x" />
			{transitionsManager.activeBlocker &&
				<RouteTransitionConfirm
					{...transitionsManager.activeBlocker}
				/>
			}
		</>
	}
}