import React from "react"
import { RouteComponentProps } from "react-router-dom"

export interface LegalPrivacyViewProps
extends RouteComponentProps<any> {

}

export interface LegalPrivacyViewState {

}

export default
class LegalPrivacyView
extends React.Component<LegalPrivacyViewProps, LegalPrivacyViewState> {
	render() {
		return <>
			<main className="v-legal-view privacy">
				<h1>Privacy policy</h1>
			</main>
		</>
	}
}