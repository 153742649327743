import * as Superagent from "superagent"
import { noop } from "utils"
import Logger from "utils/entities/Logger"

export interface AbortableRequest<T> {
	request: (requestData: any) => Promise<T>
	abort: () => void
}

export type RequestWrapperMethod =
	| "post"
	| "get"
	| "put"
	| "delete"

export interface RequestWrapperProps {
	method: RequestWrapperMethod
}

export class RequestWrapper<Req, Res>
implements AbortableRequest<Res> {
	static HOST
		: string
		= process.env.HOST || ""

	constructor(
		private readonly __url: string,
		private readonly props: RequestWrapperProps = {
			method: "get"
		}
	) {}

	private logger
		= new Logger("API")

	private requestInstance?
		: Superagent.SuperAgentRequest

	private get url() {
		return `${RequestWrapper.HOST}${this.__url}`
	} 

	private get method() {
		return this.props.method
	}

	private get logName(): string {
		return `${this.method.toUpperCase()} ${this.url}`
	}

	private handleRequest = (
		request: Superagent.SuperAgentRequest
	): Promise<Res> => {
		return request
			.then((response) => {
				this.logger.ok(`*${this.logName}* ${response.status}`, response.body)
				return response.body as Res
			})
			.catch(error => {
				if (error.code != "ABORTED")
					this.logger.error(`*${this.logName}* ${error.status}`, error.response?.body)

				throw error
			})
			.finally(() => this.requestInstance = undefined)
	}

	private applyMethod = () => {
		if (this.method == "get")
			this.requestInstance = Superagent.get(this.url)
		else
			this.requestInstance = Superagent[this.method](this.url)
	}

	sendFile = (
		file: File,
		onProgress: (
			event: ProgressEvent
		) => void = noop
	) => {
		this.abort()
		this.applyMethod()

		return this.handleRequest(
			this.requestInstance!
				.attach(file.name, file as any)
				.on("progress", onProgress)
		)
	}

	request = (
		requestData: Req
	) => {
		this.abort()

		this.applyMethod()
		return this.handleRequest(
			this.requestInstance![this.method == "get" ? "query" : "send"](requestData as any)
		)
	}

	abort = () => {
		if (!this.requestInstance)
			return

		this.requestInstance.abort()
		this.logger.warn(`*${this.logName}* was aborted`)
	}
}