import React from "react"

import "styles/components/ui/scroll-area"

import { SelectOption } from "typings/Form"
import { Animator } from "utils/entities/Animator"
import { Scroller } from "utils/entities/Scroller"

export
interface ScrollAreaProps<T> {
	items: SelectOption<T>[]
	defaultSelected?: T
	onSelect?: (
		value: T,
	) => void
	renderItem?: (
		item: SelectOption<T>,
		isSelected: boolean
	) => React.ReactNode
}

export
interface ScrollAreaState<T> {
	selected: T
}

export default
class ScrollArea<T>
extends React.Component<ScrollAreaProps<T>, ScrollAreaState<T>> {
	private itemHeight
		: number
		= 40

	private wrapper?
		: HTMLDivElement

	private animator
		: Animator

	private scroller
		: Scroller

	private get topGap(): number {
		if (!this.wrapper)
			return 0

		return parseFloat(getComputedStyle(this.wrapper).paddingTop || "0")
	}

	private setup = () => {
		if (!this.wrapper)
			return

		this.scroller = new Scroller(this.wrapper)
		this.animator = new Animator()

		const { items } = this.props
		const selectedIndex = items.findIndex(item => item.value == this.state.selected)

		this.scroller.scrollTop = this.topGap + selectedIndex * this.itemHeight + this.itemHeight / 2 - this.wrapper.offsetHeight / 2
	}

	private releaseSetup = () => {
		this.animator.stop()
		this.scroller = null!
		this.animator = null!
	}

	state
		: ScrollAreaState<T>
		= {
			selected: this.props.defaultSelected || this.props.items[0].value,
		}

	componentDidMount() {
		this.setup()
	}

	componentWillUnmount() {
		this.releaseSetup()
	}

	// 6

	select = (
		value: T,
	) => {
		this.setState({
			selected: value,
		})

		this.props.onSelect?.(value)

		if (!this.wrapper)
			return

		const newIndex = this.props.items.findIndex(item => item.value == value)
		this.animator.stop()
		this.animator.start(
			{
				from: this.scroller.scrollTop,
				to: this.topGap + newIndex * this.itemHeight + this.itemHeight / 2 - this.wrapper.offsetHeight / 2,
			},
			value => this.scroller.scrollTop = value
		)
	}

	render() {
		const { selected } = this.state
		const {
			renderItem = (item, isSelected) => {
				return <>
					<span>{item.label}</span>
					{isSelected &&
						<i className="fas fa-check" />
					}
				</>
			}
		} = this.props

		return <>
			<div
				className="c-scroll-area"
			>
				<div
					ref={r => this.wrapper = r!}
					className="scar-inner-wrapper"
				>
					{this.props.items.map(item => {
						const isSelected = item.value == selected
						return <div
							key={item.value as any}
							className={`scar-item ${isSelected ? "selected" : ""}`}
							onClick={() => this.select(item.value)}
						>
							{renderItem(item, isSelected)}
						</div>
					})}
				</div>
			</div>
		</>
	}
}