import React from "react"
import { observer } from "mobx-react"

import "styles/views/user/views/profile/components/videos/preview"

import VideoProfile from "stores/instantiable/VideoProfile"

import ContentPlaceholder from "components/UI/Decorations/Static/ContentPlaceholder"
import SimplePreloader from "components/UI/Preloaders/Simple"
// import Video from "components/Media/Video"
import UserAllVideos from "./All"
import { Link } from "react-router-dom"
import Routing from "stores/Routing"
import VideoGrid from "components/Media/VideoGrid"

export interface UserVideosPreviewProps {
	videoProfile: VideoProfile
	canManage?: boolean
}

export interface UserVideosPreviewState {
	isAllShown: boolean
}

@observer
export default
class UserVideosPreview
extends React.Component<UserVideosPreviewProps, UserVideosPreviewState> {
	state
		: UserVideosPreviewState
		= {
			isAllShown: false,
		}

	showAll = () => {
		this.setState({
			isAllShown: true,
		})
	}

	hideAll = () => {
		this.setState({
			isAllShown: false,
		})
	}

	render() {
		const { videoProfile, canManage } = this.props
		const manageVideosLink = canManage
			? <Link
				to={Routing.compile.account.settings.tab("content")}
				className="u-link"
			>
				<i className="fas fa-pen" />
				<span>
					Manage videos
				</span>
			</Link>
			: null

		return <>
			<div className="u-paper c-user-videos-intro">
				{!videoProfile.isReady
					? <ContentPlaceholder type="video">
						<SimplePreloader />
					</ContentPlaceholder>
					: videoProfile.list.length == 0
						? <ContentPlaceholder type="video">
							<span>
								No videos yet
							</span>
							{manageVideosLink}
						</ContentPlaceholder>
						: <>
							<div className="up-section-summary">
								<div className="count">
									Videos ({videoProfile.list.length})
								</div>
								<div className="actions">
									{canManage &&
										<>
											{manageVideosLink}
											<div className="u-delimiter" />
										</>
									}
									{videoProfile.list.length > 3 &&
										<div
											className="u-link"
											onClick={this.showAll}
										>
											See all
										</div>
									}
								</div>
							</div>
							<div className="uvi-videos-scrollable">
								<VideoGrid
									videos={videoProfile.list.slice(0, 3)}
									strictHeight={240}
								/>
								{/* <div className="uvi-videos">
									{videoProfile.list.slice(0, 3).map(video => {
										return <Video
											key={video.id}
											video={video}
										/>
									})}
								</div> */}
							</div>
							{this.state.isAllShown &&
								<UserAllVideos
									videos={videoProfile.list}
									onClose={this.hideAll}
								/>
							}
						</>
				}
			</div>
		</>
	}
}