import * as Superagent from "superagent"
import { EventEmitter } from "utils/entities/EventEmitter"

type UploaderEventDataReference = {
	success: string[]
	progress: number
	error: any
	cancel: void
}

type UploaderEventType = keyof UploaderEventDataReference

const uploaderEventTypes: UploaderEventType[] = [
	"success",
	"progress",
	"error",
	"cancel",
]

export default
class Uploader
extends EventEmitter<UploaderEventType, UploaderEventDataReference> {
	constructor() {
		super(uploaderEventTypes)
	}

	requests
		: Superagent.SuperAgentRequest[]
		= []
	
	upload = (
		files: FileList | File[]
	) => {
		const progress = [...Array(files.length)].map(() => 0)

		const updateProgress = (
			index: number,
			percent: number,
		) => {
			progress[index] = percent
			const sumProgress = progress.reduce((acc, item) => acc + item, 0)
			const maxProgress = progress.length * 100
			this.emit("progress", sumProgress / maxProgress * 100)
		}

		this.requests = [...files].map((file, i) => {
			return Superagent.post("/api/file")
				.attach(file.name, file as any)
				.on("progress", event => updateProgress(i, event.percent || 0))
		})

		Promise.all(this.requests).then(responses => {
			this.emit("success", responses.map(response => response.body.data.url))
		}).catch(error => {
			this.emit("error", error.status)
		})
	}

	cancel = () => {
		this.requests.forEach(request => request.abort())
		this.emit("cancel")
	}
}