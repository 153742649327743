import { action, computed, makeObservable, observable, toJS } from "mobx"

import { defaultFilters } from "consts/filters"
import { History } from "history"

import { SearchFilters } from "typings/Search"
import { objectToQueryString, parseQuery } from "utils/search"
import Routing from "./Routing"

class SearchStore {
	private history?
		: History

	@observable
	private _filters
		: SearchFilters
		= {}

	@computed
	private get searchString(): string {
		return objectToQueryString(toJS(this._filters))
	}

	private pushHistory = () => {
		const query = this.searchString
		console.log(query, !query)

		if (!query)
			return

		this.history?.push(Routing.compile.search() + query)
	}

	private internalSetFilters = (
		newFilters: SearchFilters
	) => {
		this.filtersVersion++
		
		this._filters = newFilters
	}

	constructor() {
		makeObservable(this)
	}

	filtersVersion
		: number
		= 0

	@computed
	get filters(): SearchFilters {
		return this._filters
	}

	@computed
	get defaultizedFilters(): SearchFilters {
		return {
			...defaultFilters,
			...toJS(this._filters),
		}
	}

	@action
	restoreStateFromQuery = (
		query: string
	) => {
		if (query != this.searchString)
			this.internalSetFilters({
				...defaultFilters,
				...parseQuery(query),
			})
	}

	@action
	setFilters = (
		newFilters: Partial<SearchFilters>
	) => {
		this.internalSetFilters({
			...toJS(this._filters),
			...newFilters,
		})

		this.pushHistory()
	}

	@action
	setSingleFilter = <K extends keyof SearchFilters = keyof SearchFilters>(
		key: K,
		value: SearchFilters[K]
	) => {
		this.setFilters({ [key]: value })
	}

	@action
	clearFilters = () => {
		this.internalSetFilters({})
	}

	useHistory = (
		history: History
	) => {
		this.history = history
	}
}

export default new SearchStore()