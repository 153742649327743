import React from "react"
import { v4 as uuid } from "uuid"

import "styles/components/forms/inputs/simple"
import "styles/components/forms/inputs/number"

import { FormElementInputLikeProps, InvalidationKey } from "typings/Form"
import { checkValidation } from "utils/forms"
import { noop } from "utils"

export interface NumberInputProps
extends Omit<FormElementInputLikeProps<string>, "defaultValue"> {
	min?: number
	max?: number
	allowNegative?: boolean
	allowFloat?: boolean
	startDecoration?: React.ReactNode
	endDecoration?: React.ReactNode
	defaultValue: number | undefined
	onBeforeChange?: (nextValue: string) => string
}

export interface NumberInputState {
	value: string,
	invalid?: InvalidationKey
}

export default
class NumberInput
extends React.Component<NumberInputProps, NumberInputState> {
	static defaultProps
		: Partial<NumberInputProps>
		= {
			onBeforeChange: value => value,
			renderInvalidMessage: key => key
		}

	private valueInput
		: HTMLInputElement

	private id
		: string
		= this.props.id || uuid()

	private lastInvalidationKey
		: InvalidationKey
		= "valueMissing"

	state
		: NumberInputState
		= {
			value: typeof this.props.defaultValue == "undefined"
				? ""
				: `${this.props.defaultValue}`,
			invalid: undefined
		}

	componentDidUpdate(
		prevProps: NumberInputProps,
		prevState: NumberInputState,
	) {
		// TODO check if validation influence props have changed
		// and, if yes, remove this.state.invalid
		prevProps
		prevState
	}

	handleNumericValue = (
		value: string
	): string => {
		const { allowFloat, allowNegative } = this.props
		const isNegative = allowNegative ? value.startsWith("-") : false
		let clearValue = value.replace(/-/g, "")
		clearValue = allowFloat ? clearValue.split(".").slice(0, 2).join(".") : clearValue.replace(/\./g, "")
		clearValue = clearValue.replace(/[^0-9\.]/g, "")
		return isNegative
			? `-${clearValue}`
			: clearValue
	}

	handleChange = (
		event: React.FormEvent<HTMLInputElement>
	) => {
		if (this.props.disabled)
			return
			
		var { value } = event.currentTarget
		value = this.props.onBeforeChange!(value)
		value = this.handleNumericValue(value)

		if (this.valueInput) {
			const valueSetter = Object.getOwnPropertyDescriptor(
				window.HTMLInputElement.prototype,
				"value",
			)
	
			valueSetter?.set?.call(this.valueInput, value)
			const manualEvent = new Event("change", { bubbles: true })
			this.valueInput.dispatchEvent(manualEvent)
		}

		this.setState({
			value,
			invalid: undefined
		})
	}

	handleInvalid = (
		event: React.FormEvent<HTMLInputElement>
	) => {
		event.preventDefault()

		const invalidationKey = checkValidation(event.currentTarget)
		this.lastInvalidationKey = invalidationKey || this.lastInvalidationKey
		this.setState({
			invalid: invalidationKey
		})
	}

	render() {
		const { value, invalid } = this.state
		const {
			required,
			min,
			max,
			startDecoration,
			endDecoration,
		} = this.props

		const isInvalid = invalid
		const isFilled = !!value

		return <>
			<div 
				className={`c-simple-input c-number-input ${
					isInvalid ? "invalid" : "" } ${
					isFilled ? "filled" : "" 
				}`}
			>
				{this.props.label &&
					<label
						htmlFor={this.id}
						className="u-input-label"
					>
						<span className="label-content">
							{this.props.label}
						</span>
					</label>
				}
				
				<div className="si-input-wrapper">
					<div
						className={`u-input ${
							isInvalid ? "invalid" : "" } ${
							isFilled ? "filled" : ""
						}`}
					>
						{startDecoration &&
							<div className="start-decoration">
								{startDecoration}
							</div>
						}
						<input
							ref={r => this.valueInput = r!}
							type="number"
							name={this.props.name}
							required={required}
							value={value}
							min={min}
							max={max}
							onChange={noop}
							onInvalid={this.handleInvalid}
							style={{
								display: "none",
							}}
						/>
						<input 
							id={this.id}
							value={value}
							placeholder={this.props.placeholder}
							onChange={this.handleChange}
							disabled={this.props.disabled}
							inputMode="numeric"
						/>
					</div>
					{endDecoration &&
						<div className="end-decoration">
							{endDecoration}
						</div>
					}
				</div>

				<div 
					className={`u-invalidation-message ${
						isInvalid ? "" : "hidden"
					}`}
				>
					{this.props.renderInvalidMessage?.(this.lastInvalidationKey)}
				</div>
			</div>
		</>
	}
}