import { RequestWrapper } from "api/Request"
import { observable, makeObservable, computed, action, reaction, toJS, IReactionDisposer } from "mobx"
import { ApiResponseData, PaginationRequest, PaginationResponse } from "typings/api"
import Logger from "utils/entities/Logger"
import { ModalMarker } from "./ModalMarker"

export class PaginatedListProps<F, R extends ApiResponseData<PaginationResponse>, L> {
	filters: F

	/** Default value: 10 */
	pageSize?: number

	request: RequestWrapper<PaginationRequest & F, R>
	listFromResponse: (
		response: R
	) => L[]
}

export class PaginatedList<F, R extends ApiResponseData<PaginationResponse>, L> {
	private logger
		= new Logger(`${this.storeName}`)

	private _pageSize
		: number
	
	@observable
	private _pagination
		: {
			pageNumber: number
			totalPages: number
			totalEntries: number
		}
		= {
			pageNumber: 1,
			totalPages: 1,
			totalEntries: 0,
		}

	@observable
	private _filters
		: F
		= this.props.filters

	@observable
	private _list
		: L[]
		= []

	private fetchDisposer?
		: IReactionDisposer

	@action
	private updateTotal = (
		totalEntries: number,
		totalPages: number,
	) => {
		this.logger.info(`Total items: *${totalEntries}*, total pages: *${totalPages}*`)
		this._pagination.totalEntries = totalEntries
		this._pagination.totalPages = totalPages
	}

	@action
	private setList = (
		list: L[]
	) => {
		if (this._pagination.pageNumber == 1) {
			this._list = list
		} else {
			this._list = [
				...this._list,
				...list,
			]
		}

		this.logger.info(`*${this._list.length.pluralize("item", "items")}* are shown`)
	}

	constructor(
		private readonly storeName: string,
		private readonly props: PaginatedListProps<F, R, L>,
	) {
		makeObservable(this)
		this._pageSize = props.pageSize || 10
	}

	loading
		= new ModalMarker(true)

	get pageSize() {
		return this._pageSize
	}

	@computed
	get totalEntries(): number {
		return this._pagination.totalEntries
	}

	@computed
	get filters() {
		return this._filters
	}

	@computed
	get list() {
		return this._list
	}

	@computed
	get hasNextPage(): boolean {
		return this.list.length < this._pagination.totalEntries
	}

	@action
	updateFilter = <T extends keyof F>(
		key: T,
		value: F[T],
	) => {
		this._filters[key] = value
		this._pagination.pageNumber = 1
		this._list = []

		if (typeof value == "object")
			this.logger.log(`Filter *${key as any}* updated with complex value`, value)
		else
			this.logger.log(`Filter *${key as any}* updated with value *${value}* (${typeof value})`)
		this.logger.log(`Resetting to the *page 1*`)
	}

	@action
	nextPage = () => {
		if (!this.hasNextPage)
			return this.logger.error(`Failed to get next page, there are *no pages left* to fetch`)

		this._pagination.pageNumber++

		this.logger.log(`Page is set to *${this._pagination.pageNumber}*`)
	}

	firstRun = () => {
		this.fetchDisposer = reaction(
			() => `${this._pagination.pageNumber}` + JSON.stringify(this._filters),
			() => {
				this.logger.info(
					`Fetching *${this._pageSize.pluralize("item", "items")}* at page *${this._pagination.pageNumber}* with filters`,
					toJS(this._filters),
				)
				this.props.request.abort()

				this.loading.show()
				this.props.request.request({
					...toJS(this._filters),
					pageNumber: this._pagination.pageNumber,
					pageSize: this._pageSize,
				}).then(response => {
					this.logger.ok(`Items succesfully fetched!`)
					this.setList(this.props.listFromResponse(response))
					this.updateTotal(response.data.totalEntries, response.data.totalPages)
				}).catch(error => {
					if (error.code != "ABORTED")
						this.logger.error(`Failed to fetch items - *${error}*`)
				}).finally(this.loading.hide)
			},
			{
				fireImmediately: true,
			}
		)
	}

	reset = () => {
		this.logger.warn("*Resetting store*. Any further actions will not be tracked.")
		this.props.request.abort()
		this.fetchDisposer?.()
	}
}