import React from "react"
import { Element as Tooltip } from "@sounds.of.limbo/tooltip"

import "styles/views/account/views/orders/components/card/components/header"

import Main from "stores/Main"

import { OrderData, OrderStatus } from "typings/api/Orders"
import { PublicUserData } from "typings/api/User"

import AvatarRound from "components/Global/User/avatars/Round"
import TextPlaceholder from "components/UI/Decorations/Static/TextPlaceholder"
import OrderExpirationTimer from "./ExpirationTimer"
import { dateToDateString, getTimeString } from "utils/date"
import Rating from "components/UI/Decorations/Static/Rating"

export interface OrderCardHeaderProps {
	order: OrderData
	user?: PublicUserData
	isExpanded?: boolean
	onUpdateStatus: (
		newStatus: OrderStatus
	) => void
}

export interface OrderCardHeaderState {

}

export default
class OrderCardHeader
extends React.Component<OrderCardHeaderProps, OrderCardHeaderState> {
	render() {
		const { order, user } = this.props
		const { occasions } = Main.dictionaries
		return <>
			<header className="c-order-card-header">
				<div className="coc-left">
					<div className="coc-main-content">
						<div className="coc-avatar">
							<AvatarRound
								src={user?.avatar}
							/>
						</div>
						<div className="coc-text">
							<h2>
								{user?.fullname ||
									<TextPlaceholder
										width="80%"
									/>
								}
							</h2>
							<div className="occasion">
								{!occasions.list
									? <TextPlaceholder
										width="60%"
										height={10}
									/>
									: `Occasion: ${occasions.getListByIds([order.occasion])[0]?.label}`
								}
							</div>
							<div className="price">
								<span>
									{order.price.formatThousands()}
								</span>
								{order.rating &&
									<Rating
										value={order.rating}
										tooltip={order.review}
										formatting={value => value}
									/>
								}
							</div>
						</div>
					</div>
				</div>

				<div className="coc-right">
					{(["new", "pending"] as OrderStatus[]).includes(order.status) &&
						<OrderExpirationTimer
							expiresAt={new Date(order.expiredAt).getTime()}
							onExpired={() => this.props.onUpdateStatus("expired")}
						/>
					}
					<Tooltip
						element="div"
						elementProps={{
							className: `coc-status ${order.status}`
						} as React.HTMLAttributes<HTMLDivElement>}
						content={order.status == "rejected"
							? order.rejectionReason || "No rejection reason provided"
							: order.status == "expired"
								? `Expired at ${
									dateToDateString(new Date(order.expiredAt))
								}, ${
									getTimeString(new Date(order.expiredAt))
								}`
								: undefined
						}
					>
						{order.status == "completed" &&
							<i className="fas fa-check" />
						}
						{order.status.capitalize()}
					</Tooltip>
				</div>

				{!this.props.isExpanded &&
					<div className="coc-go">
						<i className="fas fa-chevron-right"/>
					</div>
				}
			</header>
		</>
	}
}