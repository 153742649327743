import React from "react"

import "styles/views/user/views/profile/components/reviews/preview"

import api from "api"

import { Review } from "typings/api/Reviews"

import ContentPlaceholder from "components/UI/Decorations/Static/ContentPlaceholder"
import SimplePreloader from "components/UI/Preloaders/Simple"
import UserReviewItem from "./Item"
import UserAllReviews from "./All"
import { noop } from "utils"

export interface UserReviewsPreviewProps {
	userId: DBID
}

export interface UserReviewsPreviewState {
	reviews?: Review[]
	totalReviews?: number
	showAllReviews: boolean
}

export default
class UserReviewsPreview
extends React.Component<UserReviewsPreviewProps, UserReviewsPreviewState> {
	private pageSize
		: number
		= 2

	private listReq
		= api.reviews.list()

	state
		: UserReviewsPreviewState
		= {
			reviews: undefined,
			totalReviews: undefined,
			showAllReviews: false,
		}

	componentDidMount() {
		this.listReq.request({
			accountId: this.props.userId,
			pageNumber: 1,
			pageSize: this.pageSize
		}).then(response => {
			this.setState({
				reviews: response.data.reviews,
				totalReviews: response.data.totalEntries,
			})
		}).catch(noop)
	}

	componentWillUnmount() {
		this.listReq.abort()
	}

	showAllReviews = () => {
		this.setState({
			showAllReviews: true,
		})
	}

	hideAllReviews = () => {
		this.setState({
			showAllReviews: false,
		})
	}

	render() {
		const { totalReviews, reviews, showAllReviews } = this.state
		return <>
			<div className="u-paper c-user-reviews-intro">
				{!reviews
					? <div className="u-center">
						<SimplePreloader color="black" />
					</div>
					: !totalReviews
						? <ContentPlaceholder type="review">
							No reviews yet
						</ContentPlaceholder>
						: <>
							<div className="up-section-summary">
								<div className="count">
									Reviews ({totalReviews})
								</div>
								{totalReviews > 3 &&
									<div
										className="actions"
										onClick={this.showAllReviews}
									>
										<div className="u-link">
											See all
										</div>
									</div>
								}
							</div>
							<div className="uri-content">
								{reviews?.map(review => {
									return <UserReviewItem
										key={review.id}
										item={review}
									/>
								})}
							</div>
						</>
				}
			</div>
			{showAllReviews &&
				<UserAllReviews
					userId={this.props.userId}
					onClose={this.hideAllReviews}
				/>
			}
		</>
	}
}