import React from "react"
import Meta from "stores/Meta"

import "styles/components/forms/misc/datepicker"

import { FormElementDefaultProps, InvalidationKey } from "typings/Form"
import { noop } from "utils"
import { dateToDateString, getISODate, isoDateStringToDateString, parseDate } from "utils/date"
import { isEventFiredInsideElement } from "utils/dom"
import { checkValidation } from "utils/forms"
import { getWindowScrollTop } from "utils/window"
import DatepickerSelect from "./DatepickerSelect"

export interface DatepickerProps
extends FormElementDefaultProps<string> {
	label?: React.ReactNode
	placeholder?: string
	maxDate?: Date
	minDate?: Date
	renderInvalidMessage: (
		invalidationKey: InvalidationKey,
		customInvalidation?: string
	) => React.ReactNode
}

export interface DatepickerState {
	focused?: boolean
	selected?: Date
	inputValue: string
	invalid?: InvalidationKey
	showSelect: boolean
}

export default
class Datepicker
extends React.Component<DatepickerProps, DatepickerState> {
	static defaultProps = {
		renderInvalidMessage: (
			invalidationKey: InvalidationKey,
			customInvalidation?: string
		) => {
			return customInvalidation || invalidationKey
		}
	}

	private getInnerValue = (
		date?: Date
	): string => {
		return date
			? getISODate(date)
			: ""
	}

	private _forceUpdate = () => {
		this.forceUpdate()
	}

	private setData = (
		inputValue: string,
		selected?: Date,
		customValidity: string = ""
	) => {
		this.input?.setCustomValidity(customValidity)
		this.setState({
			inputValue,
			selected,
			invalid: undefined,
		})

		const valueSetter = Object.getOwnPropertyDescriptor(
			window.HTMLInputElement.prototype,
			"value",
		)

		valueSetter?.set?.call(this.input, this.getInnerValue(selected))
		const event = new Event("input", { bubbles: true })
		this.input?.dispatchEvent(event)
	}

	private get selectPosition(): React.CSSProperties {
		const box = this.wrapper.getBoundingClientRect()
		const scrollTop = getWindowScrollTop()

		return {
			top: scrollTop + box.top + box.height,
			right: document.body.offsetWidth - box.right,
		}
	}

	private isDateOverflow = (
		date: Date
	): boolean => {
		const { maxDate } = this.props
		if (!maxDate)
			return false
		return date > maxDate
	}

	private isDateUnderflow = (
		date: Date
	): boolean => {
		const { minDate } = this.props
		if (!minDate)
			return false
		return date < minDate
	}

	private isDateInRange = (
		date: Date
	): boolean => {
		return !this.isDateOverflow(date)
			&& !this.isDateUnderflow(date)
	}

	constructor(props: DatepickerProps) {
		super(props)

		const { defaultValue } = props
		const value = defaultValue ? isoDateStringToDateString(defaultValue) : undefined

		this.state = {
			focused: false,
			selected: value ? parseDate(value) : undefined,
			inputValue: value || "",
			invalid: undefined,
			showSelect: false,
		}
	}

	wrapper
		: HTMLDivElement

	input?
		: HTMLInputElement

	lastInvalidationKey
		: InvalidationKey
		= "valueMissing"

	componentDidMount() {
		document.addEventListener("click", this.handleDocumentClick)
		window.addEventListener("resize", this._forceUpdate)
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.handleDocumentClick)
		window.removeEventListener("resize", this._forceUpdate)
	}

	handleDocumentClick = (
		event: MouseEvent
	) => {
		if (!isEventFiredInsideElement(event.target, this.wrapper))
			this.hideSelect()
	}

	handleInvalid = (
		event: React.FormEvent<HTMLInputElement>
	) => {
		event.preventDefault()

		const invalidationKey = checkValidation(event.currentTarget)
		this.lastInvalidationKey = invalidationKey || this.lastInvalidationKey
		this.setState({
			invalid: invalidationKey
		})
	}

	handleInput = (
		plainValue: string
	) => {
		const value = plainValue.trim()
		
		if (!value)
			return this.setData(value, undefined)

		const date = parseDate(value)
		this.setData(
			value,
			date,
			!date
				? "badFormat"
				: this.isDateUnderflow(date)
					? "dateUnderflow"
					: this.isDateOverflow(date)
						? "dateOverflow"
						: ""
		)
	}

	selectDate = (
		date: Date
	) => {
		this.setData(dateToDateString(date), date)
		this.hideSelect()
	}

	showSelect = () => {
		this.setState({
			showSelect: true
		})
	}

	hideSelect = () => {
		this.setState({
			showSelect: false
		})
	}

	render() {
		const { selected, invalid, inputValue } = this.state
		const isFilled = !!selected
		const isInvalid = !!invalid

		return <>
			<div
				className={`c-datepicker ${
					isFilled ? "filled" : "" } ${
					isInvalid ? "invalid" : ""
				}`}
			>
				{this.props.label &&
					<label className="u-input-label">
						<span className="label-content">
							{this.props.label}
						</span>
					</label>
				}
				{this.props.name &&
					<input
						ref={r => this.input = r!}
						type="text"
						name={this.props.name}
						required={this.props.required}
						value={this.getInnerValue(selected) || inputValue}
						onChange={noop}
						onInvalid={this.handleInvalid}
						style={{
							display: "none"
						}}
					/>
				}
				<div
					ref={r => this.wrapper = r!}
					className="dp-input-wrapper"
				>
					<input
						type="text"
						placeholder={this.props.placeholder}
						className={`u-input ${ isFilled ? "filled" : "" }`}
						value={inputValue}
						onChange={event => this.handleInput(event.currentTarget.value)}
						onFocus={() => {
							if (!Meta.screenConditions.mobileLarge)
								this.showSelect()
						}}
					/>
					<i
						className="far fa-calendar-alt"
						onClick={this.showSelect}
					/>
				</div>
				<p className={`u-invalidation-message ${isInvalid ? "" : "hidden"}`}>
					{this.props.renderInvalidMessage(
						this.lastInvalidationKey, 
						this.lastInvalidationKey == "customError"
							? this.input?.validationMessage
							: undefined
					)}
				</p>
			</div>
			{this.state.showSelect &&
				<DatepickerSelect
					selected={selected}
					position={this.selectPosition}
					onSelect={this.selectDate}
					rangeChecker={this.isDateInRange}
				/>
			}
		</>
	}
}