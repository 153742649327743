import { SearchFilters, SearchSorting } from "typings/Search"

export const sortingOptions: {
	[key in SearchSorting]: string
} = {
	popular: "Popular",
	priceHighLow: "Price: High -> Low",
	priceLowHigh: "Price: Low -> High",
	mostReviewed: "Most reviewed",
	topRated: "Top Rated"
}

export const defaultFilters: SearchFilters = {
	priceFrom: 1,
	priceTo: 200,
	response: 1,
	type: "talents",
	sort: "popular",
}