import React from "react"

import "styles/views/homepage/components/sections/main"

import Main from "stores/Main"

import { preloadImage } from "utils/dom"

import Helmet from "components/Global/Helmet"

export interface HomepageMainSectionProps {

}

export interface HomepageMainSectionState {
	isBackgroundLoaded: boolean
}

export default
class HomepageMainSection
extends React.Component<HomepageMainSectionProps, HomepageMainSectionState> {
	private textContent
		= {
			title: "Become a start for your Talent",
			description: "Monetize your skill, knowledge or fame and get fun",
		}

	private backgroundImage
		: string
		= "https://i.picsum.photos/id/229/1920/1080.jpg?hmac=91yNBChMcXwGT0kjFdwLDWoTHwuiJD_LxW606lJz968"
		
	private backgroundKey
		: number
		= 1

	private abortBackgroundPreload?
		: () => void

	state
		: HomepageMainSectionState
		= {
			isBackgroundLoaded: false
		}

	componentDidMount() {
		const abortPromise = new Promise<void>(resolve => this.abortBackgroundPreload = resolve)

		preloadImage(this.backgroundImage, abortPromise)
			.then(() => {
				this.backgroundKey++
				this.setState({
					isBackgroundLoaded: true
				})
			})
	}

	componentWillUnmount() {
		this.abortBackgroundPreload?.()
	}

	render() {
		const { title, description } = this.textContent

		return <>
			<Helmet
				title={title}
				description={description}
			/>
			<section className="c-homepage-main">
				<div className="hm-background-wrapper">
					{this.state.isBackgroundLoaded &&
						<img
							key={this.backgroundKey}
							src={this.backgroundImage}
							alt={`${Main.projectName} homepage background`}
							className="animate"
							crossOrigin="anonymous"
						/>
					}
				</div>
				<div className="hm-content ho-wrapper">
					<h1>
						{title}
					</h1>
					<p>
						{description}
					</p>
				</div>
			</section>
		</>
	}
}