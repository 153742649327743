import React from "react"
import { observer, Observer } from "mobx-react"
import { Route, RouteComponentProps, Switch, Link, Redirect } from "react-router-dom"

import "styles/views/account/views/settings"

import { SettingsItemDefaultProps, SettingsTab } from "typings/Settings"

import Routing from "stores/Routing"

import LinearTabs from "components/UI/Tabs/Linear"

import ProfileSettings from "./views/Profile"
import AccountSettings from "./views/Account"
import UserBadge from "components/Global/User/Badge"
import VideosSettings from "./views/Videos"
import Auth from "stores/Auth"
import { createOptions } from "utils/select"
import { creatorTabs, settingsTabsLabels, userTabs } from "consts/settings"
import { UserData } from "typings/api/User"
import api from "api"
import Helmet from "components/Global/Helmet"
import SimplePreloader from "components/UI/Preloaders/Simple"

export interface SettingsViewProps
extends RouteComponentProps<{ tab: string }> {

}

export interface SettingsViewState {

}

@observer
export default
class SettingsView
extends React.Component<SettingsViewProps, SettingsViewState> {
	private updateReq
		= api.account.update()

	private tabs = createOptions(
		Auth.isCreator ? creatorTabs : userTabs,
		settingsTabsLabels,
	)

	private get routeTab(): string | undefined {
		return this.props.match.params?.tab
	}

	private get isRouteValid(): boolean {
		return !!this.tabs.find(item => item.value == this.routeTab)
	}

	private get currentTab(): SettingsTab {
		if (!this.isRouteValid)
			return this.tabs[0].value

		return this.routeTab as SettingsTab
	}

	componentWillUnmount() {
		this.updateReq.abort()
	}

	handleSubmit = (
		data: Partial<UserData>
	) => {
		return new Promise<void>((resolve, reject) => {
			this.updateReq.request(data)
				.then(() => {
					Auth.update(data)
					resolve()
				})
				.catch(reject)
		})
	}

	render() {
		const { tab: compileTab } = Routing.compile.account.settings

		const defaultProps: SettingsItemDefaultProps = {
			onSubmit: this.handleSubmit
		}

		const { user } = Auth

		return <>
			{!this.isRouteValid &&
				<Redirect to={Routing.compile.account.settings.tab(this.currentTab)} />
			}

			<Helmet
				title={`${this.currentTab.capitalize()} settings`}
			/>

			<main className="v-settings u-grey-view u-fade-in">
				<div className="vs-inner-wrapper">
					<header>
						<UserBadge user={user!}>
							Settings
						</UserBadge>
						{Auth.isCreator &&
							<Link
								className="u-external-link"
								to={Routing.compile.user(user!.id).profile()}
							>
								<i className="fas fa-external-link-alt" />
								<span>
									View your public profile
								</span>
							</Link>
						}
					</header>
					<LinearTabs
						tabs={this.tabs}
						controlled
						currentTab={this.currentTab}
						renderTabWithWrapper={(tab, isActive) => {
							return <Link
								to={compileTab(tab.value)}
								className={`tab ${isActive ? "active" : ""}`}
							>
								{tab.label}
							</Link>
						}}
					>
						{() => null}
					</LinearTabs>

					<Switch>
						<Route
							path={compileTab("profile")}
							exact
							render={props => <ProfileSettings {...props} {...defaultProps} />}
						/>
						<Route
							path={compileTab("account")}
							exact
							render={props => <AccountSettings {...props} {...defaultProps} />}
						/>
						<Route
							path={compileTab("content")}
							exact
							render={props =>
								<Observer>
									{() => {
										if (!Auth.videoProfile?.isReady)
											return <div className="u-center">
												<SimplePreloader color="#2E8ADF" />
											</div>
										return <VideosSettings {...props} />
									}}
								</Observer>
							}
						/>
					</Switch>
				</div>
			</main>
		</>
	}
}