import { isDefined } from "./type"

export const parseValue = (
	value?: string
) => {
	// if key comes without value, consider it as true
	// (just as HTML attribute / React prop without value)
	if (typeof value == "undefined")
		return true

	// Empty or space-only string will produce 0 at Number()
	// So, we have to handle it separately
	if (!value.trim())
		return value

	const isBoolean = value == "true" || value == "false"
	if (isBoolean)
		return value == "true"
	
	const tryNumeric = Number(value)
	if (!isNaN(tryNumeric) || value == "NaN")
		return tryNumeric

	return value
}

export const parseQuery = <T = any>(
	queryString: string,
	treatAsArray: (string | [string, string])[] = []
): T => {
	return queryString
		.replace(/^\?/, "")
		.split("&")
		.map(keyValue => {
			const [ key, value ] = keyValue.split("=").map(value => decodeURIComponent(value.replace(/\+/g, " ")))
			if (!key)
				return {}

			const handledValue = parseValue(value)
			const arrayableData = treatAsArray.find(item => typeof item == "string" ? item == key : item[0] == key)

			return {
				[key]: arrayableData
					? typeof handledValue == "string"
						? !handledValue
							? []
							: handledValue.split(typeof arrayableData == "string" ? "," : arrayableData[1]).map(parseValue)
						: [handledValue]
					: handledValue
			}
		})
		.reduce((acc, item) => {
			return {
				...acc,
				...item,
			}
		}, {} as T)
}

export const objectToQueryString = (
	query: object
): string => {
	return `?${
		Object.keys(query).map(key => {
			const value = query[key]
			if (!isDefined(value))
				return ""
			const handledValue = typeof value == "object"
				? Array.isArray(value)
					? value.join(",")
					: JSON.stringify(value)
				: `${value}`

			return !handledValue
				? ""
				: `${encodeURIComponent(key)}=${encodeURIComponent(handledValue)}`
		}).filter(item => !!item).join("&")
	}`
}