import React from "react"
import "styles/components/ui/smooth-image"

import { preloadImage } from "utils/dom"

import ImagePreloader from "./Preloaders/Image"

export interface SmoothImageProps {
	src: string
	alt?: string
	contain?: boolean
}

export interface SmoothImageState {
	isLoaded: boolean
}

export default
class SmoothImage
extends React.Component<SmoothImageProps, SmoothImageState> {
	private abortPromise
		: () => void
	
	private abortingPromise
		: Promise<void>
		= new Promise((_, reject) => {
			this.abortPromise = reject
		})

	state
		: SmoothImageState
		= {
			isLoaded: false
		}

	componentDidMount() {
		preloadImage(this.props.src, this.abortingPromise)
			.then(() => this.setState({ isLoaded: true }))
	}

	componentWillUnmount() {
		this.abortPromise()
	}

	render() {
		return <>
			<div className="c-smooth-image">
				{this.state.isLoaded
					? <img
						src={this.props.src}
						alt={this.props.alt}
						className="u-fade-in"
						crossOrigin="anonymous"
						draggable={false}
						style={{
							objectFit: this.props.contain
								? "contain"
								: "cover",
						}}
					/>
					: <ImagePreloader color="#0b244460" />
				}
			</div>
		</>
	}
}