import React from "react"
import { Observer, observer } from "mobx-react"
import { RouteComponentProps } from "react-router-dom"

import "styles/views/account/views/orders/views/list"

import { SelectOption } from "typings/Form"

import LinearTabs from "components/UI/Tabs/Linear"
import OrdersList from "./components/List"
import { OrderData, OrdersListResponse, OrderStatus } from "typings/api/Orders"
import Helmet from "components/Global/Helmet"
import { PaginatedList } from "stores/instantiable/PaginatedList"
import api from "api"
import SimpleButton from "components/UI/Buttons/Simple"

export interface AccountOrdersListProps
extends RouteComponentProps<any> {

}

export interface AccountOrdersListState {

}

const orderTabs: SelectOption<OrderStatus | undefined>[] = ([
	undefined,
	"new",
	"pending",
	"completed",
	"expired",
	"rejected",
] as (OrderStatus | undefined)[]).map(item => {
	return {
		label: (item || "all").capitalize(),
		value: item,
	}
})

@observer
export default
class AccountOrdersList
extends React.Component<AccountOrdersListProps, AccountOrdersListState> {
	orders
		: PaginatedList<{ status?: OrderStatus}, OrdersListResponse, OrderData>
		= new PaginatedList(
			"Orders",
			{
				filters: {
					status: undefined
				} as {
					status?: OrderStatus
				},
				request: api.orders.list(),
				pageSize: 10,
				listFromResponse: response => {
					return response.data.orders
				}
			}
		)
		
	componentDidMount() {
		this.orders.firstRun()
	}

	componentWillUnmount() {
		this.orders.reset()
	}

	render() {
		return <>
			<main className="v-account-orders-list u-grey-view u-fade-in">
				<div className="vaol-inner-wrapper">
					<LinearTabs
						tabs={orderTabs}
						controlled
						currentTab={this.orders.filters.status}
						onTabChange={status => this.orders.updateFilter("status", status)}
					>
						{tab => {
							return <Observer>
								{() => {
									return <>
										<Helmet
											title={`${(tab || "all").capitalize()} orders`}
										/>
										<OrdersList
											key={tab}
											status={tab}
											isLoading={this.orders.loading.isShown}
											list={this.orders.list}
											pageSize={this.orders.pageSize}
										/>
										{this.orders.hasNextPage &&
											<div className="load-more u-center">
												<SimpleButton
													loading={this.orders.loading.isShown}
													style="default-invert"
													onClick={this.orders.nextPage}
												>
													Load more
												</SimpleButton>
											</div>
										}
									</>
								}}
							</Observer>
						}}
					</LinearTabs>
				</div>
			</main>
		</>
	}
}