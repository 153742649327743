import React from "react"
import { observer } from "mobx-react"
import { RouteComponentProps } from "react-router-dom"

import "styles/views/user/views/profile"

import Auth from "stores/Auth"
import UserItem from "stores/UserItem"
import VideoProfile from "stores/instantiable/VideoProfile"

import Helmet from "components/Global/Helmet"
import UserCardVertical from "components/Global/User/cards/Vertical"
import SimplePreloader from "components/UI/Preloaders/Simple"
import UserProfileCustomerActions from "./components/actions/Customer"
import UserProfileOwnActions from "./components/actions/Own"
import UserReviewsPreview from "./components/reviews/Preview"
import UserVideosPreview from "./components/videos/Preview"

export interface UserProfileProps
extends RouteComponentProps<{ id: string }> {

}

export interface UserProfileState {

}

@observer
export default
class UserProfile
extends React.Component<UserProfileProps, UserProfileState> {
	private videoProfile
		: VideoProfile | null
		= new VideoProfile(this.props.match.params.id)

	componentDidMount() {
		this.videoProfile?.fill()
	}

	componentWillUnmount() {
		this.videoProfile = null
	}
	
	render() {
		const { user } = UserItem
		if (!user)
			return <>
				<Helmet
					title="Loading..."
				/>
				<main className="u-fs-view">
					<SimplePreloader />
				</main>
			</>

		const isOwnProfile: boolean = user.id == Auth.user?.id

		return <>
			<Helmet
				title={user.fullname}
				description={user.description}
				image={user.avatar}
			/>
			<main className="v-user-profile u-grey-view u-fade-in">
				<div className="up-content-wrapper">
					<div className="up-content">
						<div className="left">
							<UserCardVertical
								user={user}
							/>
							{isOwnProfile
								? <UserProfileOwnActions
									user={user}
								/>
								: !Auth.isCreator
									? <UserProfileCustomerActions
										user={user}
									/>
									: null
							}
						</div>
						<div className="right">
							<div className="u-paper">
								<h2>
									{user.title}
								</h2>
								<p className="main-text">
									{user.description}
								</p>
							</div>

							<UserVideosPreview
								canManage={isOwnProfile && Auth.isCreator}
								videoProfile={this.videoProfile!}
							/>

							<UserReviewsPreview
								userId={user.id}
							/>
						</div>
					</div>
				</div>
			</main>
		</>
	}
}