interface String {
	/**
	 * Transform each word in string to start with the capital letter
	 */
	capitalize: () => string

	/**
	 * Shorten string to specified length and add ellipsis if shorten
	 */
	ellipsis: (
		maxLendth: number
	) => string,
}

String.prototype.capitalize = function() {
	const self = this as string
	return self.split(" ").map(item => {
		const trimmed = item.trim()
		return `${trimmed[0]?.toUpperCase() || ""}${trimmed.slice(1)}`
	}).join(" ")
}

String.prototype.ellipsis = function(
	maxLength: number
) {
	const self = (this as String).toString()
	if (maxLength < 0)
		return self

	const showEllipsis = self.length > maxLength
	return `${self.slice(0, maxLength)}${showEllipsis ? "..." : ""}`
}