import React from "react"
import ReactDOM from "react-dom"

import "styles/components/modals/simple"
import { isEventFiredInsideElement } from "utils/dom"

export interface SimpleModalProps {
	closeOnOutsideClick?: boolean
	classNames?: {
		mainWindow?: string
		outerWrapper?: string
		innerWrapper?: string
	}
	useManualClose?: (close: () => void) => void
	onClose: () => void
	children?: (close: () => void) => React.ReactNode
}

export interface SimpleModalState {
	fadeOut: boolean
}

export default
class SimpleModal
extends React.Component<SimpleModalProps, SimpleModalState> {
	static defaultProps
		: Partial<SimpleModalProps>
		= {
			closeOnOutsideClick: false,
		}

	private innerWrapperElement
		: HTMLDivElement

	private innerClickStart
		: boolean
		= false

	state
		: SimpleModalState
		= {
			fadeOut: false,
		}

	componentDidMount() {
		this.props.useManualClose?.(this.fadeOut)
	}

	fadeOut = () => {
		this.setState({
			fadeOut: true
		})
	}

	handleAnimationEnd = (
		event: React.AnimationEvent<HTMLDivElement>
	) => {
		const { animationName } = event

		if (animationName == "fadeOut")
			this.props.onClose()
	}

	handleMainWindowClick = (
		event: React.MouseEvent<HTMLDivElement>
	) => {
		if (this.props.closeOnOutsideClick)
			if (!isEventFiredInsideElement(event.target, this.innerWrapperElement) && !this.innerClickStart)
				this.fadeOut()

		this.innerClickStart = false
	}

	handleInnerMouseDown = () => {
		this.innerClickStart = true
	}

	render() {
		if (typeof document == "undefined")
			return
			
		const {
			classNames,
		} = this.props

		const { fadeOut } = this.state

		return ReactDOM.createPortal(
			<div
				className={`c-simple-modal u-fade-${
					fadeOut ? "out" : "in" } ${
					classNames?.mainWindow || ""
				}`}
				onClick={this.handleMainWindowClick}
				onAnimationEnd={this.handleAnimationEnd}
			>
				<div className={`sm-outer-wrapper ${classNames?.outerWrapper || ""}`}>
					<div 
						ref={r => this.innerWrapperElement = r!}
						className={`sm-inner-wrapper ${classNames?.innerWrapper || ""}`}
						onMouseDown={this.handleInnerMouseDown}
					>
						{this.props.children?.(this.fadeOut)}
					</div>
				</div>
			</div>,
			document.body
		)
	}
}