import React from "react"

import "styles/components/ui/decorations/interactive/selectable-chip"

export interface SelectableChipProps
extends PropsWithChildren {
	isSelected?: boolean
	onClick?: () => void
}

export interface SelectableChipState {

}

export default
class SelectableChip
extends React.Component<SelectableChipProps, SelectableChipState> {
	render() {
		const { isSelected, onClick } = this.props
		return <>
			<div
				className={`c-selectable-chip ${isSelected ? "selected" : ""}`}
				onClick={onClick}
			>
				<i className={`fa${isSelected ? "s" : "r"} fa-star`} />
				<span>
					{this.props.children}
				</span>
			</div>
		</>
	}
}