import React from "react"

import "styles/views/account/views/orders/components/card/components/status-description"

import Auth from "stores/Auth"

import { OrderData } from "typings/api/Orders"
import { getOrderStatusesArray } from "utils/orders"
import { dateToDateString, getTimeString } from "utils/date"

export interface OrderStatusDescriptionProps {
	order: OrderData
}

export interface OrderStatusDescriptionState {

}

export default
class OrderStatusDescription
extends React.Component<OrderStatusDescriptionProps, OrderStatusDescriptionState> {
	get description(): React.ReactNode {
		const { order } = this.props
		const { isCreator } = Auth

		switch (order.status) {
			case "new":
				return "The creator received your order but not viewed it yet."
			case "pending":
				return "The creator received your order and probably working on it."
			case "expired":
				const at = <strong>
					{
						dateToDateString(new Date(order.expiredAt))
					}, {
						getTimeString(new Date(order.expiredAt))
					}
				</strong>
				return isCreator
					? <>Order is expired at {at}. You are unable to complete this order and will not receive payment for it.</>
					: <>Order is expired at {at}. Creator is unable to complete this order. You will be refunded.</>
			case "rejected":
				const reason = order.rejectionReason
					? <>
						reason: <strong>"{order.rejectionReason}"</strong>
					</>
					: <>
						<strong>no reason</strong>
					</>
				return isCreator
					? <>
						You rejected to complete this order with {reason}. You will not receive payment for this order.
					</>
					: <>
						The creator is rejected to complete your order with {reason}. You will be refunded.
					</>
			default:
				return null
		}
	}

	render() {
		const { order } = this.props
		
		const isNewOrPendingForCreator = Auth.isCreator
			&& getOrderStatusesArray("new", "pending").includes(order.status)

		const isCompleted = order.status == "completed"

		if (isCompleted || isNewOrPendingForCreator)
			return null

		return <>
			<div className={`c-order-status-description ${order.status}`}>
				<p className="status">
					<span>Order status:</span> <strong>{order.status.capitalize()}</strong>
				</p>
				<p className="description">
					{this.description}
				</p>
			</div>
		</>
	}
}