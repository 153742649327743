import React from "react"
import { Link, Redirect, RouteComponentProps } from "react-router-dom"

import "styles/views/user/views/order"

import Routing from "stores/Routing"
import UserItem from "stores/UserItem"

import OrderOccasion from "./components/Occasion"
import Form from "components/Forms/Form"
import SimpleInput from "components/Forms/Inputs/Simple"
import Textarea from "components/Forms/Inputs/Textarea"
import Checkbox from "components/Forms/Misc/Checkbox"
import UserCardHorizontal from "components/Global/User/cards/Horizontal"
import SimplePreloader from "components/UI/Preloaders/Simple"
import { observer } from "mobx-react"
import { OrderFormData } from "typings/api/Orders"
import api from "api"
import { orderErrors } from "consts/text/api"
import NumberInput from "components/Forms/Inputs/Number"
import Auth from "stores/Auth"
import { getValidationForKey } from "utils/validation"
import Back from "components/UI/Buttons/Back"
import Helmet from "components/Global/Helmet"

export interface UserOrderProps
extends RouteComponentProps<{ id: string}> {

}

export interface UserOrderState {
	loading: boolean
	error?: string
}

@observer
export default
class UserOrder
extends React.Component<UserOrderProps, UserOrderState> {
	private createReq
		= api.orders.create()

	state
		: UserOrderState
		= {
			loading: false
		}

	get userId(): string {
		return this.props.match.params.id
	}
	
	componentWillUnmount() {
		this.createReq.abort()
	}

	handleSubmit = (
		data: OrderFormData,
	) => {
		if (this.state.loading)
			return

		this.setState({
			loading: true,
			error: undefined,
		})

		this.createReq.request({
			...data,
			accountId: this.userId,
		}).then(response => {
			this.props.history.push(Routing.compile.account.orders.item(response.data.orderId))
		}).catch(error => {
			const { create } = orderErrors
			this.setState({
				loading: false,
				error: create[error.status] || create.default
			})
		})
	}

	render() {
		const { user } = UserItem
		if (!user)
			return <>
				<Helmet
					title="Loading..."
				/>
				<main className="u-fs-view">
					<SimplePreloader />
				</main>
			</>
			
		const { loading } = this.state
		if (!user.orderPrice || user.id == Auth.user?.id || Auth.isCreator)
			return <Redirect to={Routing.compile.user(this.userId).profile()} />

		return <>
			<Helmet
				title={`Order video from ${user.fullname}`}
				description={user.description}
				image={user.avatar}
			/>
			<main className="v-user-order u-grey-view u-fade-in">
				<div className="uo-content-wrapper">
					<div className="subactions">
						<Back to={Routing.compile.user(this.userId).profile()}>
							Return to profile
						</Back>
					</div>

					<UserCardHorizontal
						user={user}
					/>

					{!Auth.isLoggedIn
						? <div className="login-required">
							<h2>
								Login required
							</h2>
							<p className="u-dimmed">
								To order video you have to be logged in
							</p>
							<div className="u-actions">
								<div
									className="u-button outline"
									onClick={Auth.loginModal.show}
								>
									<div className="ub-inner-wrapper">
										Log in
									</div>
								</div>
								<Link
									to={`${Routing.compile.signup()}?redirect=${this.props.location.pathname}`}
									className="u-button default"
								>
									<div className="ub-inner-wrapper">
										Sign up
									</div>
								</Link>
							</div>
						</div>
						: <Form
							className="uo-form"
							onSubmit={this.handleSubmit}
						>
							<div className="two-input">
								<SimpleInput
									name="to"
									label="To"
									required
									renderInvalidMessage={getValidationForKey("name")}
								/>
								<SimpleInput
									name="from"
									label="From"
									required
									renderInvalidMessage={getValidationForKey("name")}
								/>
							</div>
							
							<OrderOccasion name="occasion" />

							<Textarea
								name="description"
								required
								maxLength={450}
								label="Description"
								placeholder="Enter description"
								renderInvalidMessage={getValidationForKey("default")}
							/>

							<div className="two-input">
								<NumberInput
									name="price"
									label="Order price"
									required
									min={user.orderPrice}
									defaultValue={user.orderPrice}
									startDecoration="$"
									renderInvalidMessage={getValidationForKey("orderPrice", { min: user.orderPrice })}
								/>
								<NumberInput
									name="response"
									label="Response time"
									required
									min={user.responseTime}
									defaultValue={user.responseTime}
									startDecoration="days"
									renderInvalidMessage={getValidationForKey("orderResponse", { min: user.responseTime })}
								/>
							</div>

							<Checkbox
								name="isUpdateNotify"
							>
								Receive order updates via email
							</Checkbox>

							<Checkbox
								name="confidential"
							>
								Treat my order as confidential
							</Checkbox>

							<button className={`u-button default ${loading ? "disabled" : ""}`}>
								<div className="ub-inner-wrapper">
									{loading
										? <SimplePreloader color="white" />
										: <span>Continue</span>
									}
								</div>
							</button>
						</Form>
					}

				</div>
			</main>
		</>
	}
}