import React from "react"
import { Link } from "react-router-dom"
import Routing from "stores/Routing"

import { PublicUserData } from "typings/api/User"

export interface UserProfileOwnActionsProps {
	user: PublicUserData
}

export interface UserProfileOwnActionsState {
	
}

export default
class UserProfileOwnActions
extends React.Component<UserProfileOwnActionsProps, UserProfileOwnActionsState> {
	render() {
		// const { user } = this.props
		return <>
			<div className="up-own-actions">
				<div className="u-paper">
					<Link to={Routing.compile.account.settings.home()}>
						Edit profile
					</Link>
					<div onClick={() => { throw new Error("Not implemented") }}>
						Payment info
					</div>
				</div>
			</div>
		</>
	}
}