export type ValidationMessageKeys =
	| "default"
	| "name"
	| "birthday"
	| "contentTypes"
	| "categories"
	| "email"
	| "emailCode"
	| "password"
	| "passwordConfirm"
	| "orderPrice"
	| "orderResponse"

export type ValidationMessages = {
	[key in ValidationMessageKeys]: {
		[key in keyof ValidityState]?: string
	} & {
		[key: string]: string
	}
}

export const validationMessages: ValidationMessages = {
	default: {
		badInput: "Bad input",
		patternMismatch: "Pattern mismatch",
		rangeOverflow: "Value is too big",
		rangeUnderflow: "Value is too small",
		stepMismatch: "Value is not matching the step",
		tooLong: "Value is too long",
		tooShort: "Value is too short",
		typeMismatch: "Invalid email",
		valueMissing: "This field is required",

		// custom validations
		badFormat: "Invalid date format. Use DD.MM.YYYY format for specifying dates",
		dateOverflow: "Date is over the available range",
		dateUnderflow: "Date is under the available range",
	},
	name: {

	},
	birthday: {
		dateOverflow: "You have to be at least 18 years old"
	},
	contentTypes: {
		valueMissing: "At least one content type should be selected"
	},
	categories: {
		valueMissing: "At least one category should be selected"
	},
	email: {

	},
	emailCode: {
		patternMismatch: "Code should be a 6-digit number"
	},
	password: {
		patternMismatch: "Password should consist of A-Z, a-z, 0-9",
		tooShort: "Password should be at least 8 characters long",
	},
	passwordConfirm: {
		patternMismatch: "Passwords do not match",
	},
	orderPrice: {
		rangeUnderflow: "Minimal price is ${min}",
	},
	orderResponse: {
		rangeUnderflow: "Minimal response time is {min} day(s)",
	},
}