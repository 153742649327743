import { EasingFunctions, FRAME_DURATION_60 } from "utils/animation"

export interface AnimatorStartProps {
	from: number
	to: number
	duration?: number
}

export class Animator {
	private frame
		: number

	start = (
		props: AnimatorStartProps,
		assigner: (
			value: number
		) => void,
	) => {
		const {
			from,
			to,
			duration = 350,
		} = props

		let timing = 0
		const diff = to - from

		const animate = () => {
			if (timing >= duration)
				return assigner(to)

			timing += FRAME_DURATION_60
			const rel = EasingFunctions.easeOutCubic(timing / duration)
			assigner(from + diff * rel)
			this.frame = requestAnimationFrame(animate)
		}
		animate()
	}

	stop = () => {
		cancelAnimationFrame(this.frame)
	}
}