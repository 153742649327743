import React from "react"
import { Link } from "react-router-dom"
import Routing from "stores/Routing"
import { PublicUserData } from "typings/api/User"

export interface UserProfileCustomerActionsProps {
	user: PublicUserData
}

export interface UserProfileCustomerActionsState {

}

export default
class UserProfileCustomerActions
extends React.Component<UserProfileCustomerActionsProps, UserProfileCustomerActionsState> {
	render() {
		const { user } = this.props
		if (!user.orderPrice)
			return null

		return <>
			<div className="up-customer-actions">
				<Link
					to={Routing.compile.user(user.id).order()}
					className="u-button default"
				>
					<div className="ub-inner-wrapper">
						<span>
							Order video for ${user.orderPrice}
						</span>
					</div>
				</Link>
				<div>
					<span
						className="u-link"
						onClick={() => alert("Hello, motherfucker")}
					>
						How does it work?
					</span>
				</div>
			</div>
		</>
	}
}