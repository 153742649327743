import React from "react"
import { observer } from "mobx-react"

import "styles/views/search/components/additional-filters"

import Main from "stores/Main"

import { Counters } from "typings"
import { SelectOption } from "typings/Form"

import Searchbox from "components/Forms/Inputs/Searchbox"
import SimplePreloader from "components/UI/Preloaders/Simple"
import AnimatedAccordion from "components/UI/AnimatedAccordion"

export interface SearchAdditionalFiltersProps {
	selected?: DBID
	onSelect?: (
		value?: DBID
	) => void
}

export interface SearchAdditionalFiltersState {
	expanded: boolean
	query: string
}

const SearchAdditionalFiltersChip: React.FC<{
	isSelected?: boolean
	onClick: () => void
} & PropsWithChildren> = ({
	isSelected,
	onClick,
	children,
}) => {
	return <div
		className={`c-additional-filters-chip u-fade-in ${isSelected ? "selected" : ""}`}
		onClick={onClick}
	>
		{children}
	</div>
}

@observer
export default
class SearchAdditionalFilters
extends React.Component<SearchAdditionalFiltersProps, SearchAdditionalFiltersState> {
	state
		: SearchAdditionalFiltersState
		= {
			expanded: false,
			query: "",
		}

	expand = () => {
		this.setState({
			expanded: true,
			query: "",
		})
	}

	collapse = () => {
		this.setState({
			expanded: false,
		})
	}

	toggle = () => {
		this.setState({
			expanded: !this.state.expanded,
			query: this.state.expanded ? this.state.query : "",
		})
	}

	setQuery = (
		query: string
	) => {
		this.setState({
			query,
		})
	}

	select = (
		value?: DBID
	) => {
		this.props.onSelect?.(value)
		this.collapse()
	}

	renderList = (
		list: SelectOption<DBID>[],
		counters: Counters,
	): React.ReactNode => {
		return !list.length
			? <p className="af-not-found u-dimmed">
				No categories found
			</p>
			: list.map(item => {
				const count = counters[item.value]
				const isSelected = item.value == this.props.selected
				return <SearchAdditionalFiltersChip
					key={item.value}
					onClick={() => this.select(isSelected ? undefined : item.value)}
					isSelected={isSelected}
				>
					<span>{item.label}</span> {count && <span className="u-dimmed">
						{count}	
					</span>}
				</SearchAdditionalFiltersChip>
			})
	}

	render() {
		const { categories, categoriesCounters } = Main.dictionaries
		
		if (!categories.list || !categoriesCounters.isReady)
			return <div className="c-additional-filters u-paper">
				<section className="af-main u-center">
					<SimplePreloader />
				</section>
			</div>

		const { expanded } = this.state
		const { selected } = this.props
		const mainList = selected
			? [
				...[categories.list.find(item => item.value == selected)!],
				...categories.list.filter(item => item.value != selected),
			]
			: categories.list

		const totalCount = Object.values(categoriesCounters.data!).reduce((acc, item) => {
			return acc + item
		}, 0)

		const searchableQuery = this.state.query.toLowerCase()

		return <>
			<div className="c-additional-filters u-paper">
				<section className="af-main">
					<div className={`afm-list ${expanded ? "expanded" : ""}`}>
						{this.renderList(mainList, categoriesCounters.data!)}
						{expanded &&
							<div className="afm-search u-fade-in">
								<Searchbox
									placeholder="Search categories"
									onChange={this.setQuery}
								/>
							</div>
						}
					</div>
					<div className="afm-actions">
						<div
							className="afm-action"
							onClick={this.toggle}
						>
							<span>All Categories</span> {totalCount && <span className="u-dimmed">
								{totalCount}
							</span>} <i className={`fas fa-caret-${expanded ? "up" : "down"} u-dimmed`} />
						</div>
					</div>
				</section>
				<AnimatedAccordion
					expanded={expanded}
					className="af-details"
					easing="easeOutCubic"
				>
					<div className="afd-content">
						{this.renderList(categories.list.filter(item => {
							return item.label.toLowerCase().includes(searchableQuery)
						}), categoriesCounters.data!)}
					</div>
				</AnimatedAccordion>
			</div>
		</>
	}
}