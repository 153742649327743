import React from "react"
import { observer } from "mobx-react"
import { Link } from "react-router-dom"

import "styles/components/global/user/cards/search"

import Routing from "stores/Routing"
import Main from "stores/Main"

import { PublicUserData } from "typings/api/User"

import SmoothImage from "components/UI/SmoothImage"
import UserIcon from "components/UI/Decorations/Static/Icons/User"
import ReactiveDependant from "components/UI/ReactiveDependant"
import Rating from "components/UI/Decorations/Static/Rating"

export interface UserCardSearchProps {
	index: number
	user?: PublicUserData
	invisible?: boolean
}

export interface UserCardSearchState {

}

@observer
export default
class UserCardSearch
extends React.Component<UserCardSearchProps, UserCardSearchState> {
	noDefaultUser
		: boolean
		= !this.props.user

	render() {
		const { user } = this.props
		if (!user)
			return <div
				className={`c-user-card-search u-fade-in ${this.props.invisible ? "invisible" : ""}`}
				style={{
					animationDelay: `${30 * this.props.index}ms`
				}}
			>
				<div className="ucs-avatar u-square no-avatar">
					<div className="ucs-avatar-inner-wrapper">
						<UserIcon />
					</div>
				</div>
				<div className="ucs-info">
					<div className="name placeholder" />
					<div className="tags placeholder" />
				</div>
			</div>
			
		const { id, fullname, avatar } = user
		const noAvatar = !avatar
		return <>
			<Link
				to={Routing.compile.user(id).profile()}
				className={`c-user-card-search ${!this.noDefaultUser ? "u-fade-in" : ""}`}
				style={{
					animationDelay: `${30 * this.props.index}ms`
				}}
			>
				<div className={`ucs-avatar u-square ${noAvatar ? "no-avatar" : ""}`}>
					<div className="ucs-avatar-inner-wrapper">
						{noAvatar
							? <UserIcon />
							: <SmoothImage
								src={avatar!}
							/>
						}
					</div>
					<div className="ucs-rating">
						<Rating
							value={user.rating.value}
							tbd={!user.rating.count}
						/>
						<span className="ucs-reviews-count">
							{user.rating.count.pluralize("review", "reviews")}
						</span>
					</div>
				</div>
				<div className="ucs-info">
					<div className="name u-toe">
						{fullname}
					</div>
					<ReactiveDependant
						store={Main.dictionaries.categories}
						watch="list"
					>
						{categories => {
							const items = categories.filter(item => user.categories?.includes(item.value))
							return <div className="tags u-toe">
								{items.map(item => item.label).join(", ")}
							</div>
						}}
					</ReactiveDependant>
				</div>
			</Link>
		</>
	}
}