import React from "react"
import { observer } from "mobx-react"

import "styles/views/account/views/orders/views/list/components/list"

import { OrderData, OrderStatus } from "typings/api/Orders"

import SimplePreloader from "components/UI/Preloaders/Simple"
import OrderCardNew from "../../../components/Card"
import { action } from "mobx"

export interface OrdersListProps {
	list: OrderData[]
	status?: OrderStatus
	isLoading: boolean
	pageSize: number
}

export interface OrdersListState {

}

@observer
export default
class OrdersList
extends React.Component<OrdersListProps, OrdersListState> {
	@action
	updateStatus = (
		index: number,
		newStatus: OrderStatus,
		additionalData: Partial<OrderData> = {},
	) => {
		const { list } = this.props
		if (!list[index])
			return

		console.log(additionalData)
		
		list[index] = {
			...list[index],
			...additionalData,
		}	
		list[index].status = newStatus
	}

	render() {
		const { isLoading, list, status, pageSize } = this.props
		return <>
			<div className={`c-orders-list ${isLoading ? "loading" : ""}`}>
				{list.map((item, i) => {
					return <OrderCardNew
						key={item.id}
						order={item}
						index={i % pageSize}
						onStatusChange={(...args) => this.updateStatus(i, ...args)}
					/>
				})}
				{list.length == 0
					? isLoading
						? <div style={{ textAlign: "center" }}>
							<SimplePreloader />
						</div>
						: <p
							className="u-dimmed"
							style={{
								textAlign: "center",
							}}
						>
							{!status
								? "You have no orders yet"
								: `No ${status} orders yet`
							}
						</p>
					: null
				}
			</div>
		</>
	}
}