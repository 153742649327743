import React from "react"

import "styles/components/forms/inputs/searchbox"

export interface SearchboxProps {
	defaultValue?: string
	placeholder?: string
	onSearch?: (value: string) => void
	onChange?: (value: string) => void
}

export interface SearchboxState {
	value: string
}

export default
class Searchbox
extends React.Component<SearchboxProps, SearchboxState> {
	state
		: SearchboxState
		= {
			value: this.props.defaultValue || "",
		}

	handleInput = (
		event: React.FormEvent<HTMLInputElement>
	) => {
		this.setState({
			value: event.currentTarget.value
		}, () => this.props.onChange?.(this.state.value))
	}

	handleKeyPress = (
		event: React.KeyboardEvent<HTMLInputElement>
	) => {
		if (event.key == "Enter")
			this.search()
	}

	search = () => {
		this.props.onSearch?.(this.state.value)
	}

	render() {
		return <>
			<div className="c-searchbox">
				<i
					className="fas fa-search"
					onClick={this.search}
				/>
				<input
					type="search"
					className="u-input"
					placeholder={this.props.placeholder}
					value={this.state.value}
					onChange={this.handleInput}
					onKeyPress={this.handleKeyPress}
				/>
			</div>
		</>
	}
}