import { action, computed, makeObservable, observable } from "mobx"
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg"

import Uploader from "utils/entities/Uploader"
import Logger from "utils/entities/Logger"

import Main from "stores/Main"

import { Video, VideoMetadata } from "typings/Media"

export type VideoUploaderStage =
	| "processing"
	| "making_cover"
	| "uploading_cover"
	| "uploading"
	| "failed"
	| "finished"

export type VideoUploaderMetadata = {
	name: string
	type: string
	size: number
}

export default
class VideoUploader {
	private logger
		= new Logger("VideoPicker")

	private uploader
		= new Uploader()

	private ffmpegAbort?
		: () => void

	@observable
	private _stage?
		: VideoUploaderStage
		= undefined

	@observable
	private _progress
		: number
		= 0

	@action
	private setStage = (
		stage: VideoUploaderStage
	) => {
		this._stage = stage
		this._progress = 0
	}

	@action
	private setProgress = (
		progress: number
	) => {
		this._progress = progress
	}

	@action
	private reset = () => {
		this._progress = 0
		this._stage = undefined
	}

	private getMimeTypeParams = (
		mimeType: string,
	): {
		outputMimeType: string
		extension: string
		ffmpegParams: string[]
	} => {
		switch (mimeType) {
			case "video/mp4":
				return {
					outputMimeType: "video/mp4",
					extension: "mp4",
					ffmpegParams: [
						"-c:v", "libx264",
						"-crf", "18",
						// "-preset", "slow",
					]
				}
			default:
				return {
					outputMimeType: "video/mp4",
					extension: "mp4",
					ffmpegParams: [
						// "-c:v", "libvpx",
						// "-c:a", "libvorbis",
						"-c:v", "libx264",
						"-crf", "18",
						// "-b:v", "1M",
					]
				}
		}
	}

	private processVideo = async (
		video: File
	): Promise<File> => {
		this.setStage("processing")

		const { name, size, type } = video
		this.getUploadingVideoMetadata = () => {
			return {
				name,
				type,
				size,
			}
		}

		const clearName = name.split(".").slice(0, -1).join(".")
		const decodingName = `decoding_${name}`

		this.logger.info(`Picked *${clearName} (${size} bytes)* with MIME-type *${type}*. Name for decoder is *${decodingName}*.`)

		const ffmpeg = createFFmpeg({
			corePath: `${window.location.origin}/static/utils/ffmpeg-core.js`,
			log: Main.isDev,
		})
		ffmpeg.setProgress(event => this.setProgress(event.ratio * 100))

		if (!ffmpeg.isLoaded())
			await ffmpeg.load()
		
		let abortRun: (() => void) | undefined
		this.ffmpegAbort = () => {
			try {
				ffmpeg.exit()
			} catch (e) {}
			abortRun?.()
		}

		ffmpeg.FS("writeFile", decodingName, await fetchFile(video))
		ffmpeg.FS("writeFile", "watermark.png", await fetchFile(`${window.location.origin}/static/images/logo.png`))

		const { extension, ffmpegParams, outputMimeType } = this.getMimeTypeParams(type)
		const newName = `${clearName}.${extension}`

		await Promise.race([
			ffmpeg.run(...[
				"-i", decodingName,
				"-i", "watermark.png",
				"-filter_complex", "[1:v]format=argb,colorchannelmixer=aa=0.5[tsp];[tsp][0:v]scale2ref=iw*0.1:iw*0.1*main_h/main_w[logo][base];[base][logo]overlay=W*0.01:H-W*0.01-h",
				...ffmpegParams,
				"-movflags",
				"+faststart",
				newName,
			]),
			new Promise((_, reject) => {
				abortRun = () => reject("ffmpeg.run() aborted")
			})
		])

		abortRun = undefined

		const { buffer } = ffmpeg.FS("readFile", newName)
		const processedVideo = new File([buffer], newName, { type: outputMimeType })

		this.ffmpegAbort?.()
		this.ffmpegAbort = undefined

		return processedVideo
	}

	private makeCover = async (
		video: File
	): Promise<File> => {
		this.setStage("making_cover")

		this.logger.info("Making cover...")
		const ffmpeg = createFFmpeg({
			corePath: `${window.location.origin}/static/utils/ffmpeg-core.js`,
			log: Main.isDev,
		})
		ffmpeg.setProgress(event => this.setProgress(event.ratio * 100))

		if (!ffmpeg.isLoaded())
			await ffmpeg.load()

		let abortRun: (() => void) | undefined
		this.ffmpegAbort = () => {
			try {
				ffmpeg.exit()
			} catch (e) {}
			abortRun?.()
		}

		const videoName = "video.mp4"
		const coverName = "cover.jpg"

		ffmpeg.FS("writeFile", videoName, await fetchFile(video))
		await Promise.race([
			ffmpeg.run(...[
				"-i", videoName,
				// "-vf", `"select=eq(n\\,0)"`,
				"-vframes", "1",
				"-q:v", "3",
				coverName,
			]),
			new Promise((_, reject) => {
				abortRun = () => reject("ffmpeg.run() aborted")
			})
		])

		abortRun = undefined

		const { buffer } = ffmpeg.FS("readFile", coverName)
		const cover = new File([buffer], coverName, { type: "image/jpeg" })
		this.ffmpegAbort?.()
		this.ffmpegAbort = undefined

		return cover
	}

	private uploadCover = (
		cover: File
	): Promise<string> => {
		this.setStage("uploading_cover")

		const uploading = new Promise<string>((resolve, reject) => {
			this.uploader.addEventListener("success", ([url]) => resolve(url))
			this.uploader.addEventListener("error", reject)
		})

		this.uploader.upload([cover])

		return uploading
	}

	private uploadVideo = async (
		video: File
	): Promise<string> => {
		this.setStage("uploading")

		const uploading = new Promise<string>((resolve, reject) => {
			this.uploader.addEventListener("success", ([url]) => resolve(url))
			this.uploader.addEventListener("error", reject)
		})

		this.uploader.upload([video])

		return uploading
	}

	constructor() {
		makeObservable(this)

		this.uploader.addEventListener("progress", this.setProgress)
	}

	@computed
	get stage(): VideoUploaderStage | undefined {
		return this._stage
	}

	@computed
	get progress(): number {
		return this._progress
	}

	@computed
	get isInProgress(): boolean {
		const progressStages: VideoUploaderStage[] = [
			"processing",
			"making_cover",
			"uploading_cover",
			"uploading",
		]

		return progressStages.includes(this._stage!)
	}

	getUploadingVideoMetadata?
		: () => VideoUploaderMetadata

	abort = () => {
		this.ffmpegAbort?.()
		this.uploader.cancel()
	}

	start = async (
		video: File
	): Promise<Video> => {
		if (this.isInProgress)
			throw new Error("Uploading still in progress")

		let processedVideo: File
		try {
			this.logger.info(`Starting the video processing`)
			processedVideo = await this.processVideo(video)
			this.logger.ok(`Video has been processed successfully. Processed video size is *${processedVideo.size} bytes*`)
		} catch (e) {
			console.error(e)
			this.logger.error(`Failed to process the video: *${e}*`)
			this.reset()
			this.setStage("failed")
			throw e
		}

		let cover: string
		try {
			this.logger.info("Starting the video cover making")
			const coverFile = await this.makeCover(processedVideo)
			this.logger.ok(`Video cover has been created successfully`)

			this.logger.info(`Starting the video cover uploading`)
			cover = await this.uploadCover(coverFile)
			this.logger.ok(`Video cover has been uploaded successfully`)

		} catch (e) {
			this.logger.error(`Failed to handle video cover: *${e}*`)
			this.reset()
			this.setStage("failed")
			throw e
		}

		let url: string
		try {
			this.logger.info(`Starting the video uploading`)
			url = await this.uploadVideo(processedVideo)
			this.logger.ok(`Video has been uploaded successfully`)
		} catch (e) {
			this.logger.error(`Failed to upload the video: *${e}*`)
			this.reset()
			this.setStage("failed")
			throw e
		}

		this.reset()
		this.setStage("finished")

		const { duration, width, height } = await new Promise<VideoMetadata>((resolve, reject) => {
			try {
				const src = URL.createObjectURL(processedVideo)
				const videoElement = document.createElement("video")

				const handleMetadata = () => {
					try {
						URL.revokeObjectURL(videoElement.src)
						videoElement.removeEventListener("loadedmetadata", handleMetadata)
						resolve({
							width: videoElement.videoWidth,
							height: videoElement.videoHeight,
							duration: videoElement.duration,
						})
					} catch (e) {
						reject(e)
					}
				}

				videoElement.addEventListener("loadedmetadata", handleMetadata)
				videoElement.src = src
			} catch (e) {
				reject(e)
			}
		}).catch(error => {
			this.logger.error(`Failed to retrieve duration of the video: *${error}*`)
			return {
				width: 0,
				height: 0,
				duration: 0,
			}
		})

		return {
			name: processedVideo.name,
			size: processedVideo.size,
			width,
			height,
			duration,
			cover,
			sources: [
				{
					resolution: "native",
					url,
				}
			]
		}
	}
}