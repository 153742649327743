import React from "react"

import { LoginModalFormProps } from ".."

import { getValidationForKey } from "utils/validation"

import Form from "components/Forms/Form"
import SimpleInput from "components/Forms/Inputs/Simple"
import SimpleButton from "components/UI/Buttons/Simple"
import { passwordPattern } from "consts/patterns"
import Info from "components/UI/Decorations/Interactive/Info"
import api from "api"
import { sha256 } from "utils/crypto"

type PasswordResetStageData = {
	email: {
		email: string
	}
	password: {
		code: string
		password: string
	}
	success: {}
}

type PasswordResetStage = keyof PasswordResetStageData

export interface PasswordResetFormProps
extends LoginModalFormProps {
	onBack: () => void
}

export interface PasswordResetFormState {
	stage?: PasswordResetStage
	loading: boolean
	error?: string
	password: string
}

export default
class PasswordResetForm
extends React.Component<PasswordResetFormProps, PasswordResetFormState> {
	private stagesOrder
		: PasswordResetStage[]
		= [
			"email",
			"password",
			"success",
		]

	private email?
		: string

	state
		: PasswordResetFormState
		= {
			stage: "email",
			loading: false,
			error: undefined,
			password: "",
		}

	startLoading = () => {
		this.props.setOutsideClosableStatus(false)
		this.props.onError()
		this.setState({
			loading: true,
			error: undefined,
		})
	}

	endLoading = (
		error?: string
	) => {
		if (this.state.stage != "password")
			this.props.setOutsideClosableStatus(true)

		this.props.onError(error)
		this.setState({
			loading: false,
			error,
		})
	}

	nextStage = () => {
		this.endLoading()
		this.setState({
			stage: this.stagesOrder[this.stagesOrder.indexOf(this.state.stage || "email") + 1],
		}, () => {
			this.props.setOutsideClosableStatus(this.state.stage != "password")
		})
	}

	sendEmail = async (
		data: PasswordResetStageData["email"]
	) => {
		if (this.state.loading)
			return

		this.startLoading()

		try {
			const checkResult = await api.account.checkEmail().request({ email: data.email })
			if (!checkResult.data.isUsing)
				throw new Error("No account using this email")

			await api.account.sendCode().request({ email: data.email })
			this.email = data.email
			this.nextStage()
		} catch (e) {
			console.dir(e)
			this.endLoading(e.response?.body?.info || e.message)
		}
	}

	resetPassword = async (
		data: PasswordResetStageData["password"]
	) => {
		if (this.state.loading)
			return

		this.startLoading()

		try {
			await api.account.resetPasword().request({
				confirmCode: data.code,
				email: this.email  || "",
				password: sha256(data.password),
			})

			this.nextStage()
		} catch (e) {
			console.dir(e)
			this.endLoading(e.response?.body?.info || e.message)
		}
	}

	renderError = (
		error?: string
	): React.ReactNode => {
		return <p className={`u-invalidation-message independent ${error ? "visible" : ""} center`}>
			<span>
				<span>
					{error || <>&nbsp;</>}
				</span>
			</span>
		</p>
	}

	get content(): React.ReactNode {
		const { loading, error } = this.state
		switch (this.state.stage) {
			case "email":
				return <>
					<h1>
						Forgot password?
					</h1>
					<p className="u-dimmed">
						Not a problem! Please enter your email and we'll send you a code, which will help to reset password.
					</p>
					<Form
						className="login-form"
						onSubmit={this.sendEmail}
					>
						<SimpleInput
							label="Email"
							placeholder="johndoe@myemail.com"
							type="email"
							name="email"
							required
							disabled={loading}
							renderInvalidMessage={getValidationForKey("email")}
						/>
						{this.renderError(error)}
						<div className="u-actions column">
							<SimpleButton
								submit
								loading={loading}
							>
								Submit
							</SimpleButton>
							<SimpleButton
								style="outline"
								onClick={this.props.onBack}
							>
								Back to Sign in
							</SimpleButton>
						</div>
					</Form>
				</>
			case "password":
				return <>
					<h1>
						Create new password
					</h1>
					<Form
						className="login-form"
						onSubmit={this.resetPassword}
					>
						<SimpleInput
							name="confirmCode"
							required
							inputmode="numeric"
							disabled={this.state.loading}
							label="Verification code"
							onBeforeChange={value => value.replace(/[^0-9]/g, "")}
							pattern="[0-9]{6}"
							renderInvalidMessage={getValidationForKey("emailCode")}
							endDecoration={
								<Info>
									We've sent you a verification code on your Email. Verify it to complete password recovery.
								</Info>
							}
						/>
						<SimpleInput
							name="password"
							required
							type="password"
							label="Create password"
							pattern={passwordPattern}
							minLength={8}
							onBeforeChange={value => {
								this.setState({ password: value })
								return value
							}}
							renderInvalidMessage={getValidationForKey("password")}
						/>
						<SimpleInput
							name="passwordConfirm"
							required
							type="password"
							pattern={this.state.password}
							label="Confirm password"
							renderInvalidMessage={getValidationForKey("passwordConfirm")}
						/>
						{this.renderError(error)}
						<div className="u-actions column">
							<SimpleButton
								submit
								loading={loading}
							>
								Reset password
							</SimpleButton>
							<SimpleButton
								style="outline"
								onClick={this.props.close}
							>
								Cancel password reset
							</SimpleButton>
						</div>
					</Form>
				</>
			case "success":
				return <>
					<h1>
						Reset successful
					</h1>
					<p className="u-dimmed">
						Your password has been reset succesfully. You can now login with new password
					</p>
					<div className="u-actions column">
						<SimpleButton
							onClick={this.props.onBack}
						>
							Log in
						</SimpleButton>
					</div>
				</>
			default:
				return null
		}
	}

	render() {
		return <>
			<div
				key={this.state.stage}
				className="lm-content u-fade-in"
			>
				{this.content}
			</div>
		</>
	}
}