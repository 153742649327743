import { action, computed, makeObservable, observable } from "mobx"
import { v4 as uuid } from "uuid"

import { UserVideo } from "typings/api/User"

import api from "api"
import VideoUploader from "./VideoUploader"
import { Video } from "typings/Media"

export default
class VideoProfile {
	@observable
	private videos?
		: UserVideo[]
		= undefined

	@observable
	private updating
		: boolean
		= false

	@action
	private setVideos = (
		videos: UserVideo[]
	) => {
		this.videos = videos
	}
	
	@action
	private setUpdating = (
		updating: boolean
	) => {
		this.updating = updating
	}

	constructor(
		private readonly accountId: string,
	) {
		makeObservable(this)
	}

	uploader
		= new VideoUploader()

	@computed
	get isReady(): boolean {
		return !!this.videos
	}

	@computed
	get isUpdating(): boolean {
		return this.updating
	}

	@computed
	get list(): UserVideo[] {
		return this.videos || []
	}

	@computed
	get ids(): string[] {
		return this.videos?.map(video => video.id) || []
	}

	fill = () => {
		return api.account.videos.get()
			.request({ accountId: this.accountId })
			.then(response => {
				this.setVideos(response.data.videoProfile.sort((a, b) => a.order - b.order))
			})
	}

	update = async (
		newList: UserVideo[]
	) => {
		if (this.isUpdating)
			throw("Another update is already in progress")

		this.setUpdating(true)

		await api.account.videos.update().request(newList)
		await this.fill()

		this.setUpdating(false)
	}

	add = async (
		video: Video,
	) => {
		if (!this.isReady)
			throw new Error("Video profile is not ready")

		await this.update([
			{
				...video,
				order: 0,
				id: uuid(),
			},
			...this.videos!.map(item => {
				return {
					...item,
					order: item.order + 1
				}
			})
		])
	}

	upload = (
		video: File
	) => {
		return this.uploader.start(video)
	}
}