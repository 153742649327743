import React from "react"

import "styles/components/forms/misc/checkbox"
import { noop } from "utils"

export interface CheckboxProps
extends PropsWithChildren {
	name?: string
	disabled?: boolean
	defaultChecked?: boolean

	/** Return false if you want to prevent checkbox state changed to nextState */
	shouldChange?: (nextState: boolean) => boolean
	onToggle?: (newState: boolean) => void
}

export interface CheckboxState {
	checked: boolean
}

export default
class Checkbox
extends React.Component<CheckboxProps, CheckboxState> {
	static defaultProps
		: Partial<CheckboxProps>
		= {
			shouldChange: () => true,
		}

	state
		: CheckboxState
		= {
			checked: this.props.defaultChecked || false,
		}

	toggleState = () => {
		if (this.props.disabled)
			return

		const newState = !this.state.checked
		if (!this.props.shouldChange?.(newState))
			return

		this.setState({
			checked: newState
		}, () => this.props.onToggle?.(newState))
	}


	render() {
		const { checked } = this.state
		const { disabled } = this.props
		return <>
			<div
				className={`c-checkbox ${
					checked ? "checked" : "" } ${
					disabled ? "disabled" : ""
				}`}
			>
				<div
					className="checkbox-inner-wrapper"
					onClick={this.toggleState}
				>
					<div className="box">
						<i className="fas fa-check" />
					</div>
					<span className="label">
						{this.props.children}
					</span>
				</div>
			</div>
			{this.props.name &&
				<input
					type="checkbox"
					name={this.props.name}
					checked={checked}
					onChange={noop}
					style={{ display: "none "}}
				/>
			}
		</>
	}
}