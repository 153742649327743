import React from "react"
import { observer } from "mobx-react"
import { TransitionBlocker } from "stores/Routing"
import ConfirmModal from "components/Modals/Confirm"

export interface RouteTransitionConfirmProps {
	type: TransitionBlocker
	confirm: () => void
	cancel: () => void
}

export interface RouteTransitionConfirmState {

}

@observer
export default
class RouteTransitionConfirm
extends React.Component<RouteTransitionConfirmProps, RouteTransitionConfirmState> {
	get text(): React.ReactNode {
		switch (this.props.type) {
			case "dirtySettings":
				return `Are you sure you want to leave settings? There are some unsaved changes that will be lost if you continue.`
			default:
				return this.props.type
		}
	}
	render() {
		return <>
			<ConfirmModal
				title="Leave confirmation"
				onCancel={this.props.cancel}
				onConfirm={this.props.confirm}
			>
				{this.text}
			</ConfirmModal>
		</>
	}
}