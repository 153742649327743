import React from "react"
import { RouteComponentProps } from "react-router-dom"

export interface LegalPressViewProps
extends RouteComponentProps<any> {

}

export interface LegalPressViewState {

}

export default
class LegalPressView
extends React.Component<LegalPressViewProps, LegalPressViewState> {
	render() {
		return <>
			<main className="v-legal-view press">
				<h1>Press</h1>
			</main>
		</>
	}
}