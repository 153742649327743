import React from "react"

import "styles/components/forms/misc/radio-group"

import { FormElementDefaultProps } from "typings/Form"
import { noop } from "utils"

export interface RadioGroupProps<T extends string>
extends FormElementDefaultProps<T> {
	label?: React.ReactNode
	items: T[]
	children: (item: T) => React.ReactNode
}

export interface RadioGroupState<T extends string> {
	selected: T
}

export default
class RadioGroup<T extends string>
extends React.Component<RadioGroupProps<T>, RadioGroupState<T>> {
	state
		: RadioGroupState<T>
		= {
			selected: this.props.defaultValue || this.props.items[0]
		}

	selectItem = (
		item: T
	) => {
		this.setState({
			selected: item
		})
	}

	render() {
		return <>
			<div className="c-radio-group">
				{this.props.label &&
					<div className="u-input-label">
						{this.props.label}
					</div>
				}
				{this.props.name &&
					<input
						type="text"
						name={this.props.name}
						value={this.state.selected}
						onChange={noop}
						style={{ display: "none" }}
					/>
				}

				<div className="rg-items">
					{this.props.items.map(item => {
						const isSelected = item == this.state.selected
						return <div
							key={item}
							className={`rg-item ${isSelected ? "selected" : ""}`}
							onClick={() => this.selectItem(item)}
						>
							<div className="decorator" />
							<div className="content">
								{this.props.children(item)}
							</div>
						</div>
					})}
				</div>
			</div>
		</>
	}
}