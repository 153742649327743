import React from "react"

import "styles/components/ui/tabs/linear"

import { SelectOption } from "typings/Form"
import { Animator } from "utils/entities/Animator"
import { Scroller } from "utils/entities/Scroller"

export interface LinearTabsProps<T> {
	tabs: SelectOption<T>[]
	defaultTab?: T

	controlled?: boolean
	currentTab?: T

	onTabChange?: (
		nextTab: T
	) => void

	children: (tab: T) => React.ReactNode
	renderTab?: (tab: T) => React.ReactNode

	renderTabWithWrapper?: (
		tab: SelectOption<T>,
		isActive: boolean,
		onSelect: () => void,
	) => React.ReactNode
}

export interface LinearTabsState<T> {
	currentTab: T
}

export default
class LinearTabs<T>
extends React.Component<LinearTabsProps<T>, LinearTabsState<T>> {
	private scroller
		: Scroller

	private animator
		= new Animator()

	private wrapper?
		: HTMLDivElement

	get selectedIndex(): number {
		return this.props.tabs.findIndex(tab => {
			return this.props.controlled
				? tab.value == this.props.currentTab
				: tab.value == this.state.currentTab
		})
	}

	get highlighterPosition(): React.CSSProperties {
		if (!this.wrapper || !this.wrapper.children[this.selectedIndex])
			return {
				left: 0,
				width: 0
			}
		const { offsetLeft, offsetWidth } = this.wrapper.children[this.selectedIndex] as HTMLElement
		return {
			left: offsetLeft,
			width: offsetWidth,
		}
	}

	state
		: LinearTabsState<T>
		= {
			currentTab: this.props.defaultTab || this.props.tabs[0].value
		}

	componentDidMount() {
		setTimeout(this.redraw, 100)
		window.addEventListener("resize", this.redraw)

		this.scroller = new Scroller(this.wrapper?.parentElement!)
		this.scrollToActive()
	}

	componentDidUpdate(
		_: LinearTabsProps<T>,
		prevState: LinearTabsState<T>
	) {
		if (this.state.currentTab != prevState.currentTab)
			this.scrollToActive()
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.redraw)
	}

	redraw = () => {
		this.forceUpdate()
	}

	scrollToActive = () => {
		if (!this.wrapper || !this.wrapper.children[this.selectedIndex])
			return

		this.animator.stop()

		const item = this.wrapper.children[this.selectedIndex] as HTMLElement
		const { left, width } = item.getBoundingClientRect()

		this.animator.start(
			{
				from: this.scroller.scrollLeft,
				to: this.scroller.scrollLeft + left + width / 2 - (this.wrapper.parentElement?.offsetWidth || 0) / 2,
				duration: 300,
			},
			value => this.scroller.scrollLeft = value
		)
	}

	selectTab = (
		tab: T
	) => {
		this.props.onTabChange?.(tab)
		this.setState({
			currentTab: tab
		})
	}

	render() {
		const { controlled } = this.props
		const currentTab = controlled
			? this.props.currentTab
			: this.state.currentTab

		return <>
			<div className="c-linear-tabs">
				<div
					ref={r => this.wrapper = r!}
					className="tabs"
				>
					{this.props.tabs.map(item => {
						const isActive = item.value == currentTab
						const onSelect = () => this.selectTab(item.value)

						if (this.props.renderTabWithWrapper)
							return <React.Fragment key={`${item.value}`}>
								{this.props.renderTabWithWrapper(item, isActive, onSelect)}
							</React.Fragment>

						return <div
							key={`${item.value}`}
							className={`tab ${isActive ? "active" : ""}`}
							onClick={onSelect}
						>
							{this.props.renderTab
								? this.props.renderTab(item.value)
								: item.label
							}
						</div>
					})}
				</div>
				<div
					className="highlighter"
					style={this.highlighterPosition}
				/>
			</div>
			{this.props.children(currentTab!)}
		</>
	}
}
