import SimpleInput from "components/Forms/Inputs/Simple"
import React from "react"
import { v4 as uuid } from "uuid"

export interface SignUpSocialsListProps {
	defaultItems?: string[]
}

export interface SignUpSocialsListState {
	items: string[]
}

export default
class SignUpSocialsList
extends React.Component<SignUpSocialsListProps, SignUpSocialsListState> {
	private minItems
		: number
		= 3

	private labels
		: string[]
		= [
			"Share your Instagram link (optional)",
			"Share your Facebook link (optional)",
			"Share other links (optional)",
		]

	state
		: SignUpSocialsListState
		= {
			items: [...Array(Math.max(this.props.defaultItems?.length || 0, this.minItems))].map(() => uuid())
		}

	addItem = () => {
		const { items } = this.state
		items.push(uuid())
		this.setState({ items })
	}

	removeItem = (
		item: string
	) => {
		const { items } = this.state
		const foundIndex = items.findIndex(i => i == item)
		if (foundIndex >= 0) {
			items.splice(foundIndex, 1)
			this.setState({ items })
		}
	}

	render() {
		return <>
			{this.state.items.map((item, i) => {
				const label = this.labels[i]
				if (!!label)
					return <SimpleInput
						key={item}
						label={label}
					/>
				return <div
					key={item}
					className="u-actions auto-size su-default"
				>
					<SimpleInput

					/>
					<span
						className="u-link shrink no-stretch"
						onClick={() => this.removeItem(item)}
					>
						Remove
					</span>
				</div>
			})}
			<span
				className="u-link u-dib closer"
				onClick={this.addItem}
			>
				<i className="fas fa-plus" />
				<span>
					Add another link
				</span>
			</span>
		</>
	}
}