import React from "react"
import { observer } from "mobx-react"

import "styles/components/global/network-status"

import Meta from "stores/Meta"

export interface NetworkStatusProps {

}

export interface NetworkStatusState {
	isHidden: boolean
}

@observer
export default
class NetworkStatus
extends React.Component<NetworkStatusProps, NetworkStatusState> {
	state
		: NetworkStatusState
		= {
			isHidden: !Meta.isOffline
		}

	handleAnimationEnd = (
		event: React.AnimationEvent<HTMLDivElement>
	) => {
		if (event.animationName == "fadeOut" && !Meta.isOffline)
			this.setState({
				isHidden: true,
			})
	}

	componentDidUpdate() {
		if (Meta.isOffline && this.state.isHidden)
			this.setState({
				isHidden: false,
			})
	}

	render() {
		const { isOffline } = Meta
		const { isHidden } = this.state
		
		return isOffline
			? <div className="c-network-status offline">
				You're seem to be offline. Some pages may not work properly.
			</div>
			: isHidden
				? null
				: <div
					className="c-network-status online"
					onAnimationEnd={this.handleAnimationEnd}
				>
					Back online!
				</div>
	}
}