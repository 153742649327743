import React from "react"

import "styles/views/sign-up/components/steps/step-1"

import SignUpDefaultStep, { SignUpDefaultStepProps, SignUpDefaultStepState } from "./DefaultStep"

import Main from "stores/Main"
import Auth from "stores/Auth"

import Form from "components/Forms/Form"

import { noop } from "utils"
import SmoothImage from "components/UI/SmoothImage"

export interface SignUpStep1Props
extends SignUpDefaultStepProps {

}

export interface SignUpStep1State
extends SignUpDefaultStepState {
	type?: string
}

export default
class SignUpStep1
extends SignUpDefaultStep<SignUpStep1Props, SignUpStep1State> {
	private userTypes
		: {
			value: string
			label: string
			text: string
		}[]
		= [
			{
				label: "User",
				text: "I want to get fun video or talk to celebrity",
				value: "user",
			},
			{
				label: "Video creator",
				text: "I want to show my talent or talk to my fans",
				value: "creator",
			},
		]

	state
		: SignUpStep1State
		= {
			loading: false,
			fadeOut: false,
			type: this.props.defaultData?.type,
		}

	get hasType(): boolean {
		return !!this.state.type
	}

	selectUserType = (
		type: string
	) => {
		if (!this.state.loading)
			this.setState({ type })
	}

	render() {
		const { type, fadeOut } = this.state
		return <>
			<div 
				className={`section-content fade-${fadeOut ? "out" : "in"} ${this.props.direction}`}
				onAnimationEnd={this.handleAnimationEnd}
			>
				<h1>
					Welcome to {Main.projectName}
				</h1>
				<Form
					className="su-step step-1"
					onSubmit={this.handleSubmit}
				>
					<input
						type="text"
						name="type"
						required
						style={{ display: "none" }}
						value={type || ""}
						onChange={noop}
						onInvalid={event => event.preventDefault()}
					/>

					<div className="radio">
						<div className="u-input-label">
							Register as a:
						</div>
						{this.userTypes.map(userType => {
							const isSelected = userType.value == type
							return <div
								key={userType.value}
								className={`radio-item ${isSelected ? "selected" : ""}`}
								onClick={() => this.selectUserType(userType.value)}
							>
								<div className="image-wrapper">
									<div className="u-square">
										<SmoothImage
											src={`https://picsum.photos/900/900/?random&key=${userType.value}`}
										/>
									</div>
								</div>
								<div className="text-content">
									<p className="type-name">
										{userType.label}
									</p>
									<p className="type-description u-dimmed">
										{userType.text}
									</p>
								</div>
								<div className={`u-checkbox ${isSelected ? "checked" : ""}`}>
									<i className="fas fa-check" />
								</div>
							</div>
						})}
					</div>

					{this.renderActions(!this.hasType, false)}

					<p className="u-dimmed-text">
						Already have an account? <span
							className="u-link"
							onClick={Auth.loginModal.show}
						>
							Sign in
						</span>
					</p>
				</Form>
			</div>
		</>
	}
}