import React from "react"

import "styles/components/media/video"

import { Video as TVideo } from "typings/Media"

import VideoPlayback from "managers/VideoPlayback"

import SmoothImage from "components/UI/SmoothImage"

export interface VideoProps {
	video: TVideo
	noPlay?: boolean
	showTime?: boolean
}

export interface VideoState {
	played: boolean
}

export default
class Video
extends React.Component<VideoProps, VideoState> {
	private reset = (
		videoId: string
	) => {
		if (videoId == this.props.video.cover)
			return

		this.setState({
			played: false,
		})
	}

	state
		: VideoState
		= {
			played: false,
		}

	componentDidMount() {
		VideoPlayback.addEventListener("playbackchange", this.reset)
	}

	componentWillUnmount() {
		VideoPlayback.removeEventListener("playbackchange", this.reset)
		if (this.state.played)
			VideoPlayback.stopPlaying(this.props.video.cover)
	}

	play = () => {
		VideoPlayback.startPlaying(this.props.video.cover)
		this.setState({
			played: true,
		})
	}

	render() {
		const { played } = this.state
		const { video, noPlay, showTime } = this.props
		return <>
			<div className="c-video">
				{played
					? <video
						controls
						autoPlay
					>
						{video.sources.map((source, i) => {
							return <source
								key={i}
								src={source.url}
								type="video/mp4"
							/>
						})}
					</video>
					: <>
						<SmoothImage
							src={video.cover!}
							alt={`${video.name} cover`}
							contain
						/>
						{!noPlay &&
							<div
								className="play-button"
								onClick={this.play}
							>
								<i className="fas fa-play" />
							</div>
						}
						{showTime &&
							<div className="cv-duration">
								{video.duration.toTime()}
							</div>
						}
					</>
				}
			</div>
		</>
	}
}