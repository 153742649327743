import React from "react"
import { Link } from "react-router-dom"
import { observer } from "mobx-react"

import { PublicUserData } from "typings/api/User"
import { SearchFilters } from "typings/Search"

import Main from "stores/Main"
import Routing from "stores/Routing"

import { objectToQueryString } from "utils/search"

import ReactiveDependant from "components/UI/ReactiveDependant"

export interface UserCardCategoriesProps {
	categories: PublicUserData["categories"]
}

export interface UserCardCategoriesState {

}

@observer
export default
class UserCardCategories
extends React.Component<UserCardCategoriesProps, UserCardCategoriesState> {
	render() {
		return <>
			<ReactiveDependant
				store={Main.dictionaries.categories}
				watch="list"
			>
				{categories => {
					const items = categories.filter(item => this.props.categories?.includes(item.value))
					return <div className="uc-categories">
						{items.map((item, i) => {
							return <React.Fragment key={item.value}>
								<Link
									to={Routing.compile.search() + objectToQueryString({ category: item.value } as Partial<SearchFilters>)}
									className="u-link"
								>
									{item.label}
								</Link>
								{i < items.length - 1 && <>, </>}
							</React.Fragment>
						})}
					</div>
				}}
			</ReactiveDependant>
		</>
	}
}