import React from "react"

import "styles/components/ui/decorations/static/icons/user"

export interface UserIconProps {
	round?: boolean
}

export interface UserIconState {

}

export default
class UserIcon
extends React.Component<UserIconProps, UserIconState> {
	render() {
		return <>
			<div className="c-user-icon u-square">
				<div className={`icon-inner-wrapper ${this.props.round ? "round" : ""}`}>
					<div className="head">
						<div className="u-square">
							<div className="round" />
						</div>
					</div>
					<div className="body u-square">
						<div className="round" />
					</div>
				</div>
			</div>
		</>
	}
}