import React from "react"
import { observer } from "mobx-react"
import { Link, matchPath, RouteComponentProps, withRouter } from "react-router-dom"

import "styles/components/global/footer"

import Routing from "stores/Routing"
import Main from "stores/Main"

import Logo from "../Header/components/Logo"

export interface FooterProps
extends RouteComponentProps<any> {

}

export interface FooterState {

}

@observer
class Footer
extends React.Component<FooterProps, FooterState> {
	private routing
		: {
			link: string
			label: string
			isExternal?: boolean
		}[]
		= [
			{
				link: Routing.patterns.faq.home,
				label: "FAQ",
			},
			{
				link: "https://google.com",
				label: "Blog",
				isExternal: true,
			},
			{
				link: Routing.patterns.legal.terms,
				label: "Terms of use",
			},
			{
				link: "https://google.com",
				label: "Help",
				isExternal: true,
			},
			{
				link: Routing.patterns.legal.press,
				label: "Press",
			},
			{
				link: Routing.patterns.legal.privacy,
				label: "Privacy policy",
			},
		]

	private socials
		: {
			link: string
			label: string
		}[]
		= [
			{
				link: "https://facebook.com",
				label: "Facebook",
			},
			{
				link: "https://instagram.com",
				label: "Instagram",
			},
			{
				link: "https://twitter.com",
				label: "Twitter",
			},
		]
		
	private hideForRoutes
		: {
			path: string
			exact?: boolean
		}[]
		= [
			{
				path: Routing.patterns.signup,
				exact: true
			}
		]

	get isHomepage() {
		return !!matchPath(this.props.location.pathname, {
			path: Routing.patterns.homepage,
			exact: true
		})
	}

	get isHidden(): boolean {
		return this.hideForRoutes.some(route => {
			return !!matchPath(this.props.location.pathname, { ...route })
		})
	}

	render() {
		if (this.isHidden)
			return null

		return <>
			<footer className="c-app-footer">
				<div className="caf-inner-wrapper">
					<div className="routing">
						<Logo noLink={this.isHomepage} />
						
						<div className="links">
							{this.routing.map((route, i) => {
								return <div
									key={i}
									className="link"
								>
									{route.isExternal
										? <a
											rel="noopener"
											target="_BLANK"
											href={route.link}
										>
											{route.label}
										</a>
										: <Link to={route.link}>
											{route.label}
										</Link>
									}
								</div>
							})}
						</div>
					</div>
					<div className="socials">
						<div className="links">
							{this.socials.map((social, i) => {
								return <div
									key={i}
									className="link"
								>
									<a
										rel="noopener"
										target="_BLANK"
										href={social.link}
									>
										{social.label}
									</a>
								</div>
							})}
						</div>
						<div className="copyright">
							{Main.projectName} &copy; {new Date().getFullYear()} &bull; UI v{process.env.VERSION}
						</div>
					</div>
				</div>
			</footer>
		</>
	}
}

export default withRouter(Footer)