import React from "react"
import { Link } from "react-router-dom"
import { observer } from "mobx-react"

import "styles/views/account/views/orders/components/card"

import api from "api"

import { noop } from "utils"

import Routing from "stores/Routing"
import Auth from "stores/Auth"

import { OrderData, OrderStatus } from "typings/api/Orders"
import { PublicUserData } from "typings/api/User"

import OrderCardHeader from "./components/Header"
import OrderCreatorContent from "./components/content/Creator"
import OrderUserContent from "./components/content/User"
import Helmet from "components/Global/Helmet"

export interface OrderCardProps {
	order: OrderData
	isExpanded?: boolean
	index?: number
	onStatusChange: (
		newStatus: OrderStatus,
		additionalData?: Partial<OrderData>
	) => void
}

export interface OrderCardState {
	user?: PublicUserData
	email?: string
}

@observer
export default
class OrderCard
extends React.Component<OrderCardProps, OrderCardState> {
	private Wrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
		const { order, isExpanded, index = 0 } = this.props

		const wrapperClassName = `c-order-card u-fade-in ${
			order.status } ${
			isExpanded ? "expanded" : ""
		}`

		const wrapperStyle: React.CSSProperties = {
			animationDelay: `${60 * index}ms`
		}

		return !isExpanded
			? <Link
				to={Routing.compile.account.orders.item(order.id)}
				className={wrapperClassName}
				style={wrapperStyle}
			>
				{children}
			</Link>
			: <section
				className={wrapperClassName}
				style={wrapperStyle}
			>
				{children}
			</section>
	}

	private userRequest
		= api.account.item()

	state
		: OrderCardState
		= {
			user: undefined,
			email: undefined,
		}

	componentDidMount() {
		this.userRequest.request({
			accountId: Auth.isCreator
				? this.props.order.userId
				: this.props.order.creatorId
		}).then(response => {
			this.setState({
				user: response.data.account,
			})
		}).catch(noop)
	}

	componentWillUnmount() {
		this.userRequest.abort()
	}

	render() {
		const { order, isExpanded } = this.props
		const { user } = this.state

		return <>
			{isExpanded && user &&
				<Helmet
					title={`Order from ${user.fullname}`}
				/>
			}
			<this.Wrapper>
				<OrderCardHeader
					order={order}
					user={user}
					isExpanded={isExpanded}
					onUpdateStatus={this.props.onStatusChange}
				/>
				{!isExpanded
					? null
					: Auth.isCreator
						? <OrderCreatorContent
							order={order}
							onStatusChange={this.props.onStatusChange}
						/>
						: <OrderUserContent
							order={order}
						/>
				}
			</this.Wrapper>
		</>
	}
}