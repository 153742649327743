import React from "react"

import "styles/views/account/views/settings/views/videos/components/edit"

import { UserVideo } from "typings/api/User"

import { isEqual } from "utils/object"

import SimpleModal from "components/Modals/Simple"
import SimplePreloader from "components/UI/Preloaders/Simple"
import SimpleInput from "components/Forms/Inputs/Simple"
import Info from "components/UI/Decorations/Interactive/Info"
import Video from "components/Media/Video"
import Filepicker from "components/Forms/Misc/Filepicker"
import SmoothImage from "components/UI/SmoothImage"

export interface VideoEditProps {
	video: UserVideo
	onChange: (
		newVideo: UserVideo
	) => Promise<void>
	onClose: () => void
}

export interface VideoEditState {
	video: UserVideo
	isNewCover: boolean
	isProcessing: boolean
	processingError?: string
}

export default
class VideoEdit
extends React.Component<VideoEditProps, VideoEditState> {
	private manualClose?
		: () => void

	state
		: VideoEditState
		= {
			video: { ...this.props.video },
			isNewCover: false,
			isProcessing: false,
			processingError: undefined,
		}

	get isChanged(): boolean {
		return !isEqual(this.props.video, this.state.video)
	}

	handleSubmit = () => {
		if (!this.isChanged || this.state.isProcessing)
			return

		this.setState({
			isProcessing: true,
			processingError: undefined,
		})

		this.props.onChange(this.state.video)
			.then(() => {
				this.manualClose?.()
			})
			.catch(error => {
				this.setState({
					isProcessing: false,
					processingError: `${error}`
				})
			})
	}

	changeCover = (
		newCover: string
	) => {
		const { video } = this.state
		video.cover = newCover
		this.setState({
			video,
			isNewCover: true
		})
	}

	changeName = (
		newName: string
	) => {
		const { video } = this.state
		video.name = `${newName}.mp4`
		this.setState({
			video,
		})
	}

	resetCover = () => {
		const { video } = this.state
		video.cover = this.props.video.cover
		this.setState({
			video,
			isNewCover: false,
		})
	}

	render() {
		const { video: initialVideo } = this.props
		const { video: newVideo, isNewCover, isProcessing } = this.state
		return <>
			<SimpleModal
				useManualClose={close => this.manualClose = close}
				onClose={this.props.onClose}
				classNames={{
					innerWrapper: "c-video-edit"
				}}
			>
				{close => {
					return <>
						<header>
							<h2>
								Edit video
							</h2>
						</header>
						<div className="ve-video-metadata">
							<div
								style={{
									width: initialVideo.width / initialVideo.height * 100
								}}
							>
								<Video
									video={initialVideo}
									noPlay
								/>
							</div>
							<div className="name-size">
								<p className="name">
									{initialVideo.name}
								</p>
								<span className="size">
								{initialVideo.duration.toTime()}, {initialVideo.size.toVerboseSize()}
								</span>
							</div>
						</div>
						<div className="ve-edit-cover">
							<h3>Video cover</h3>
							{isNewCover
								? <div className="new-cover">
									<SmoothImage
										src={newVideo.cover}
										contain
									/>
									<div className="reset">
										<i
											className="fas fa-trash-alt"
											onClick={this.resetCover}
										/>
									</div>
								</div>
								: <Filepicker
									maxCount={1}
									allowedMimeTypes={[
										"image/jpeg",
										"image/png",
										"image/webp"
									]}
									onUploadEnd={([url]) => this.changeCover(url)}
								>
									Choose image
								</Filepicker>
							}
							<p className="recommendations">
								Recommended cover size for this video:<br />
								<strong>
									{newVideo.width}x{newVideo.height}
								</strong>
							</p>
						</div>
						<div className="ve-edit-name">
							<h3>Video name</h3>
							<div className="input-wrapper">
								<SimpleInput
									defaultValue={initialVideo.name.replace(/\.mp4$/, "")}
									endDecoration={
										<Info
											icon="question-circle"
										>
											Nobody except you will see this name. It's used only in video settings.
										</Info>
									}
									onBeforeChange={value => {
										this.changeName(value)
										return value
									}}
								/>
								<span>
									.mp4
								</span>
								
							</div>
						</div>
						<div className={`ve-actions u-actions ${isProcessing ? "loading" : ""}`}>
							{isProcessing
								? <SimplePreloader color="" />
								: <>
									<div
										className="u-button smaller outline"
										onClick={close}
									>
										<div className="ub-inner-wrapper">
											Cancel
										</div>
									</div>
									<div
										className={`u-button smaller default ${!this.isChanged ? "disabled" : ""}`}
										onClick={this.handleSubmit}
									>
										<div className="ub-inner-wrapper">
											Save
										</div>
									</div>
								</>
							}
						</div>
					</>
				}}
			</SimpleModal>
		</>
	}
}