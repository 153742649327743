import React from "react"
import ReactDOM from "react-dom"

import { isEventFiredInsideElement } from "utils/dom"

export interface PopupWithTriggerProps
extends PropsWithChildren {
	elementProps: React.HTMLAttributes<HTMLDivElement>
	setCloseHook?: (
		closeHook: () => void
	) => void
	trigger: {
		props?: Omit<React.HTMLAttributes<HTMLDivElement>, "onClick" | "onClickCapture">
		chidren?: React.ReactNode
	}
}

export interface PopupWithTriggerState {
	popupShown: boolean
}

export default
class PopupWithTrigger
extends React.Component<PopupWithTriggerProps, PopupWithTriggerState> {
	private trigger
		: HTMLDivElement | null

	private popup
		: HTMLDivElement | null

	private __update = () => {
		this.forceUpdate()
	}

	state
		: PopupWithTriggerState
		= {
			popupShown: false,
		}

	get popupPosition(): {
		top: number
		right: number
	} {
		const { height, right } = this.trigger?.getBoundingClientRect() || {}

		return {
			top: this.trigger?.offsetTop! + height! + 15,
			right: document.body.offsetWidth - right!,
		}
	}

	componentDidMount() {
		this.props.setCloseHook?.(this.hide)
		document.addEventListener("click", this.handleDocumentClick)
		window.addEventListener("resize", this.__update)
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.handleDocumentClick)
		window.removeEventListener("resize", this.__update)
	}

	handleDocumentClick = (
		event: MouseEvent
	) => {
		const isClickedInTrigger = this.trigger && isEventFiredInsideElement(event.target, this.trigger)
		const isClickedInPanel = this.popup && isEventFiredInsideElement(event.target, this.popup) || false

		if (isClickedInTrigger || isClickedInPanel)
			return

		this.hide()
	}

	toggle = () => {
		this.setState({
			popupShown: !this.state.popupShown,
		})
	}

	hide = () => {
		this.setState({
			popupShown: false
		})
	}

	render() {
		const { popupShown } = this.state
		return <>
			<div
				{...(this.props.trigger.props || {})}
				ref={r => this.trigger = r}
				onClick={this.toggle}
			>
				{this.props.trigger.chidren}
			</div>
			{typeof document != "undefined" && popupShown &&
				ReactDOM.createPortal(
					<div
						{...this.props.elementProps}
						ref={r => this.popup = r}
						style={this.popupPosition}
					>
						{this.props.children}
					</div>,
					document.body,
				)
			}
		</>
	}
}