import React from "react"

import "styles/components/ui/decorations/static/text-placeholder"

export interface TextPlaceholderProps {
	width?: string | number
	height?: string | number
}

export interface TextPlaceholderState {

}

export default
class TextPlaceholder
extends React.Component<TextPlaceholderProps, TextPlaceholderState> {
	render() {
		const {
			width = "100%",
			height = 20,
		} = this.props

		return <>
			<div
				style={{
					width, 
					height,
				}}
				className="c-text-placeholder"
			/>
		</>
	}
}