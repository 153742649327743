import { observable, makeObservable, action, IObservableArray } from "mobx"
import { noop } from "utils"
import { v4 as uuid } from "uuid"

export type ConfirmItemProps = {
	title: React.ReactNode
	description: React.ReactNode
	prompt?: {
		label?: string
		placeholder?: string
		maxLength?: number
	}
	actionLabels?: {
		yes?: string
		no?: string
	}
	classNames?: {
		mainWindow?: string
		outerWrapper?: string
		innerWrapper?: string
		actions?: {
			primary?: string
			secondary?: string
		}
	}
}

type ConfirmTypes = {
	confirm: () => Promise<void>
	prompt: (prompt: string) => Promise<void>
}

type ConfirmType = keyof ConfirmTypes

export type ConfirmItemPrivateProps = {
	type: ConfirmType
}

export type ConfirmCallback<K extends ConfirmType = ConfirmType> = {
	[P in K]: ConfirmTypes[P]
}[K]

export
class ConfirmItem<T extends ConfirmType = ConfirmType> {
	@observable
	private prompt
		: string
		= ""

	@action
	onBeforeConfirm = () => {
		this.loading = true
		this.error = undefined
	}

	@action
	onConfirmError = (
		error: string
	) => {
		this.loading = false
		this.error = error
	}

	@observable
	loading
		: boolean
		= false

	@observable
	error?
		: string
		= undefined

	id
		: string
		= uuid()

	constructor(
		readonly props: ConfirmItemProps & ConfirmItemPrivateProps,
		private readonly onClose: (instance: ConfirmItem<T>) => void,
		private readonly onConfirm: ConfirmCallback<T>,
		private readonly onCancel: () => void,
	) {
		makeObservable(this)
	}

	@action
	updatePrompt = (
		newPrompt: string
	) => {
		this.prompt = newPrompt
	}

	confirm = () => {
		if (this.loading)
			return

		this.onBeforeConfirm()

		this.onConfirm(this.prompt).then(() => {
			this.onClose(this)
		}).catch(error => {
			this.onConfirmError(`${error}`)
		})
	}

	cancel = () => {
		this.onCancel()
		this.onClose(this)
	}
}

class ConfirmsStore {
	@action
	private addConfirm = (
		confirm: ConfirmItem
	) => {
		this.list.push(confirm)
	}

	@action
	private removeConfirm = (
		item: ConfirmItem
	) => {
		(this.list as IObservableArray<ConfirmItem>).remove(item)
	}

	@observable
	list
		: ConfirmItem[]
		= []

	constructor() {
		makeObservable(this)
	}

	prompt = (
		confirmAction: ConfirmTypes["prompt"],
		props: ConfirmItemProps,
	) => {
		return new Promise<void>((resolve, reject) => {
			const confirm = new ConfirmItem(
				{
					...props,
					type: "prompt",
				},
				this.removeConfirm,
				(prompt) => confirmAction(prompt).then(resolve),
				reject,
			)

			this.addConfirm(confirm)
		}).catch(noop)
	}

	create = (
		confirmAction: ConfirmTypes["confirm"],
		props: ConfirmItemProps,
	) => {
		return new Promise<void>((resolve, reject) => {
			const confirm = new ConfirmItem(
				{
					...props,
					type: "confirm",
				},
				this.removeConfirm,
				() => confirmAction().then(resolve),
				reject,
			)

			this.addConfirm(confirm)
		}).catch(noop)
	}
}

export default new ConfirmsStore()