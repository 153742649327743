import React from "react"
import { Route, RouteComponentProps, Switch } from "react-router-dom"

import Routing from "stores/Routing"

import LegalPressView from "./views/Press"
import LegalPrivacyView from "./views/Privacy"
import LegalTermsView from "./views/Terms"
import NotFound from "views/NotFound"

export interface LegalRouteProps
extends RouteComponentProps<any> {

}

export interface LegalRouteState {

}

export default
class LegalRoute
extends React.Component<LegalRouteProps, LegalRouteState> {
	render() {
		return <>
			<Switch>
				<Route
					exact
					path={Routing.patterns.legal.terms}
					render={props => <LegalTermsView {...props} />}
				/>
				<Route
					exact
					path={Routing.patterns.legal.privacy}
					render={props => <LegalPrivacyView {...props} />}
				/>
				<Route
					exact
					path={Routing.patterns.legal.press}
					render={props => <LegalPressView {...props} />}
				/>
				<Route
					render={props => <NotFound {...props} />}
				/>
			</Switch>
		</>
	}
}