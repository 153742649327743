import React from "react"

import "styles/components/ui/progress"

export interface ProgressProps {
	maxProgress: number
	currentProgress: number
	children: (currentProgress: number) => React.ReactNode
}

export interface ProgressState {

}

export default
class Progress
extends React.Component<ProgressProps, ProgressState> {
	private size
		: number
		= 80

	private halfSize
		: number
		= this.size / 2
	
	circlePosition
		: React.SVGProps<SVGCircleElement> 
		= {	
			cx: this.halfSize,
			cy: this.halfSize,
			r: this.halfSize - 2,
		}

	render() {
		const { size } = this
		const { currentProgress, maxProgress } = this.props
		const relativeProgress = currentProgress / maxProgress
		return <>
			<div
				style={{
					width: size,
					height: size,
				}}
				className="c-progress"
			>
				<span
					style={{ opacity: (relativeProgress + .25) / 1.25 }}
					className="counter"
				>
					{this.props.children(currentProgress)}
				</span>

				<svg viewBox={`0 0 ${size} ${size}`}>
					<circle
						className="background"
						{...this.circlePosition}
					/>
					<circle
						className="progress"
						pathLength={1}
						strokeDasharray={`${relativeProgress} ${1 - relativeProgress}`}
						{...this.circlePosition}
					/>
				</svg>
			</div>
		</>
	}
}