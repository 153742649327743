import React from "react"
import ReactDOM from "react-dom"
import { RouteComponentProps, withRouter } from "react-router-dom"

export interface HeaderBurgerProps
extends RouteComponentProps<any> {
	children: (
		isShown: boolean
	) => React.ReactNode
}

export interface HeaderBurgerState {
	isShown: boolean
}

class HeaderBurger
extends React.Component<HeaderBurgerProps, HeaderBurgerState> {
	state
		: HeaderBurgerState
		= {
			isShown: false
		}
	
	componentDidUpdate(prevProps: HeaderBurgerProps) {
		if (this.props.location.pathname != prevProps.location.pathname)
			this.hideMenu()
	} 

	toggleMenu = () => {
		this.setState({
			isShown: !this.state.isShown,
		})
	}

	hideMenu = () => {
		this.setState({
			isShown: false,
		})
	}

	render() {
		const { isShown } = this.state
		return <>
			<div
				className={`toggle-menu ${isShown ? "shown" : ""}`}
				onClick={this.toggleMenu}
			>
				<span className="element" />
				<span className="element" />
				<span className="element" />
			</div>
			{typeof document != "undefined" && ReactDOM.createPortal(
				this.props.children(isShown),
				document.body
			)}
		</>
	}
}

export default withRouter(HeaderBurger)