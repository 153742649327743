import { SelectOption } from "typings/Form"

export const createOptions = <T extends string>(
	keys: T[],
	labels: {
		[key in T]: string
	},
): SelectOption<T>[] => {
	return keys.map(key => {
		return {
			label: labels[key],
			value: key,
		}
	})
}