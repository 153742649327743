import React from "react"

import "styles/components/ui/decorations/static/content-placeholder"

export type ContentPlaceholderType =
	| "image"
	| "video"
	| "review"

export interface ContentPlaceholderProps
extends PropsWithChildren {
	type: ContentPlaceholderType
}

export interface ContentPlaceholderState {

}

export default
class ContentPlaceholder
extends React.Component<ContentPlaceholderProps, ContentPlaceholderState> {
	get icon(): string {
		const staticPath = "/static/images/icons/content"
		switch (this.props.type) {
			default:
				return `${staticPath}/${this.props.type}.svg`
		}
	}

	render() {
		return <>
			<div className="c-content-placeholder">
				<img
					src={this.icon}
					alt={this.props.type}
				/>
				<span>
					{this.props.children}
				</span>
			</div>
		</>
	}
}