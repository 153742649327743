import { ValidationMessageKeys, validationMessages } from "consts/text/validations"
import { checkValidation } from "./forms"

export const isEmailBasicallyValid = (
	email: string
): boolean => {
	const input = document.createElement("input")
	input.type = "email"
	input.required
	input.value = email

	const invalidationKey = checkValidation(input)
	return !invalidationKey
}

export const getValidationForKey = (
	key: ValidationMessageKeys,
	replacers?: {
		[key: string]: string | number | undefined
	}
) => {
	return (
		mainValidation: keyof ValidityState,
		customValidation?: string,
	): string => {
		const validationKey = customValidation || mainValidation
		return (
			validationMessages[key][validationKey]
			|| validationMessages.default[validationKey]
			|| validationKey
		).replace(/\{([a-z]+)\}/gi, (match, group) => {
			return typeof replacers?.[group] != "undefined"
				? `${replacers?.[group]}`
				: match
		})
	}
}