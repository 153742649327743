import { RequestWrapper } from "./Request"
import { SignUpRequestData } from "typings/api/SignUp"
import { EmailCheckResponse, IdentityResponse, ResetPasswordRequestData, SignInRequestData } from "typings/api/SignIn"
import { CategoriesListResponse, OccasionsListResponse } from "typings/api/Dictionaries"
import { UserData, UserProfileRequest, UserProfileResponse, UserSearchRequest, UserSearchResponse, UserVideo, VideoProfileResponse } from "typings/api/User"
import { CreateOrderRequest, CreateOrderResponse, OrderItemRequest, OrderItemResponse, OrderListRequest, OrdersListResponse, OrderUpdateAsCreatorData, OrderUpdateAsFanData } from "typings/api/Orders"
import { ReviewsListRequestData, ReviewsListResponse } from "typings/api/Reviews"
import { ApiResponseData } from "typings/api"
import { VerifyRecaptchaRequestData, VerifyRecaptchaResponse } from "typings/api/Commons"

class API {
	private routes = {
		account: "account",
		content: "content",
		category: "category",
		profile: "profile",
		occasions: "occasion",
		order: "order",
		review: "review",
	}

	account = {
		identity: () => new RequestWrapper<void, IdentityResponse>(`/api/${this.routes.account}/identity`, { method: "get" }),
		update: () => new RequestWrapper<Partial<UserData>, any>(`/api/${this.routes.account}/update`, { method: "post" }),
		signUp: () => new RequestWrapper<SignUpRequestData, any>(`/api/${this.routes.account}/signUp`, { method: "post" }),
		login: () => new RequestWrapper<SignInRequestData, any>(`/api/${this.routes.account}/signIn`, { method: "post" }),
		logout: () => new RequestWrapper<void, void>(`/api/${this.routes.account}/logout`, { method: "post" }),

		// TODO sendCode have to be private
		sendCode: () => new RequestWrapper<{ email: string }, void>(`/api/${this.routes.account}/sendSignUpCode`, { method: "post" }),
		verifyEmail: () => new RequestWrapper<{ confirmCode: string}, void>(`/api/${this.routes.account}/verifyEmail`, { method: "post" }),

		checkEmail: () => new RequestWrapper<{ email: string }, EmailCheckResponse>(`/api/${this.routes.account}/isUsingEmail`, { method: "get" }),
		resetPasword: () => new RequestWrapper<ResetPasswordRequestData, void>(`/api/${this.routes.account}/reset`, { method: "post" }),

		list: () => new RequestWrapper<UserSearchRequest, UserSearchResponse>(`/api/${this.routes.account}/public/list`, { method: "get" }), 
		item: () => new RequestWrapper<UserProfileRequest, UserProfileResponse>(`/api/${this.routes.account}/public`, { method: "get" }),

		videos: {
			get: () => new RequestWrapper<{ accountId: DBID }, VideoProfileResponse>(`/api/${this.routes.account}/videoProfile/get`, { method: "get" }),
			update: () => new RequestWrapper<UserVideo[], void>(`/api/${this.routes.account}/videoProfile/update`, { method: "post" }),
		} 
	}

	dictionaries = {
		categories: () => new RequestWrapper<void, CategoriesListResponse>(`/api/${this.routes.category}`, { method: "get" }),
		occasions: () => new RequestWrapper<void, OccasionsListResponse>(`/api/${this.routes.occasions}`, { method: "get" })
	}

	profile = {
		current: () => new RequestWrapper<void, void>(`/api/${this.routes.profile}`, { method: "get" }),
	}

	orders = {
		create: () => new RequestWrapper<CreateOrderRequest, CreateOrderResponse>(`/api/${this.routes.order}/create`, { method: "post" }),
		list: () => new RequestWrapper<OrderListRequest, OrdersListResponse>(`/api/${this.routes.order}/list`, { method: "get" }),
		item: () => new RequestWrapper<OrderItemRequest, OrderItemResponse>(`/api/${this.routes.order}`, { method: "get" }),
		updateAsCreator: () => new RequestWrapper<OrderUpdateAsCreatorData, any>(`/api/${this.routes.order}/update`, { method: "post" }),
		updateAsFan: () => new RequestWrapper<OrderUpdateAsFanData, any>(`/api/${this.routes.order}/update`, { method: "post" }),
		customerEmail: () => new RequestWrapper<{ orderId: DBID }, ApiResponseData<{ email: string }>>(`/api/${this.routes.order}/email`, { method: "get" })
	}

	reviews = {
		list: () => new RequestWrapper<ReviewsListRequestData, ReviewsListResponse>(`/api/${this.routes.review}`, { method: "get" })
	}

	commons = {
		verifyRecaptcha: () => new RequestWrapper<VerifyRecaptchaRequestData, VerifyRecaptchaResponse>(`/api/captcha`, { method: "get" }),
	}
}

export default new API()