import React from "react"
import Main from "stores/Main"
import { PublicUserData, UserSearchResponse } from "typings/api/User"

import SearchAdditionalFilters from "views/Search/components/AdditionalFilters"
import HomepageUsersList from "../UsersList"

export interface FeaturedWithCategoriesProps {

}

export interface FeaturedWithCategoriesState {
	category: string
	users?: PublicUserData[]
}

export default
class FeaturedWithCategories
extends React.Component<FeaturedWithCategoriesProps, FeaturedWithCategoriesState> {
	index
		: number
		= 0

	maxIndex
		: number
		= Math.min(Main.dictionaries.categories.list?.length! - 1, 6)

	state
		: FeaturedWithCategoriesState
		= {
			category: Main.dictionaries.categories.list![this.index].value,
		}

	__fetchUsersMockup = (
		category: DBID
	): Promise<UserSearchResponse> => {
		return new Promise(resolve => {
			setTimeout(() => {
				const catname = Main.dictionaries.categories.list?.find(item => item.value == category)?.label
				resolve({
					data: {
						// accounts: [...Array((Math.random() * 5 + 5) | 0)].map((_, i) => {
						accounts: [...Array(10)].map((_, i) => {
							return {
								fullname: `Temp ${catname} User #${i + 1}`,
								id: `61fe932cde1606947aee954${i}`,
								isCelebrity: true,
								rating: {
									value: Math.random() * 4 + 1,
									count: Math.round(Math.random() * 100)
								},
								type: "creator",
								avatar: `https://picsum.photos/1024/1365/?random&key=${i}`,
								categories: [category],
							}
						}),
						pageNumber: 1,
						pageSize: 10,
						totalEntries: 10,
						totalPages: 10
					}
				})
			}, 1000)
		})
	}

	componentDidMount() {
		this.fetchAndSet(this.state.category)
	}

	setCategory = (
		category: DBID,
	) => {
		if (!this.state.users)
			return

		this.setState({
			category,
			users: undefined,
		})

		this.fetchAndSet(category)
	}

	fetchAndSet = (
		category: DBID,
	) => {
		this.__fetchUsersMockup(category).then(response => {
			this.setState({
				users: response.data.accounts,
			})
		})
	}

	nextCategory = () => {
		this.index++
		if (this.index > this.maxIndex)
			this.index = 0

		this.setCategory(Main.dictionaries.categories.list![this.index].value)
	}

	render() {
		const { users, category } = this.state
		return <>
			<section className={`ho-featured-users other ho-wrapper ${users ? "fadeout-list" : ""}`}>
				<SearchAdditionalFilters
					selected={category}
				/>
				{users &&
					<div
						key={category + "category"}
						className="hofu-loader"
						onAnimationEnd={this.nextCategory}
					/>
				}
				<HomepageUsersList
					key={category + "users"}
					users={users}
				/>
			</section>
		</>
	}
}