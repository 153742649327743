import React from "react"
import { RouteComponentProps } from "react-router-dom"

import "styles/views/account/views/settings/views/profile"

import { SettingsItemDefaultProps } from "typings/Settings"

import Auth from "stores/Auth"
import Main from "stores/Main"

import SimpleInput from "components/Forms/Inputs/Simple"
import Textarea from "components/Forms/Inputs/Textarea"
import BlockingForm from "../../components/BlockingForm"
import ReactiveDependant from "components/UI/ReactiveDependant"
import ChipsAutocomplete from "components/Forms/Selects/ChipsAutocomplete"
import NumberInput from "components/Forms/Inputs/Number"
import { toJS } from "mobx"
import { getValidationForKey } from "utils/validation"

export interface ProfileSettingsProps
extends RouteComponentProps<any>, SettingsItemDefaultProps {

}

export interface ProfileSettingsState {

}

export default
class ProfileSettings
extends React.PureComponent<ProfileSettingsProps, ProfileSettingsState> {
	private get defaultData() {
		return {
			title: Auth.user?.title || "",
			bio: Auth.user?.bio || "",
			description: Auth.user?.description || "",
			categories: toJS(Auth.user?.categories) || [],
			orderPrice: Auth.user?.orderPrice || 0,
			responseTime: Auth.user?.responseTime || 0,
		}
	}

	render() {
		const { categories } = Main.dictionaries

		return <>
			<BlockingForm
				data={this.defaultData}
				className="c-profile-settings"
				onSubmit={this.props.onSubmit}
			>
				<div className="csf-inner-wrapper form-wrapper">
					<SimpleInput
						name="title"
						defaultValue={this.defaultData.title}
						label="Title"
						maxLength={120}
					/>

					<SimpleInput
						name="bio"
						defaultValue={this.defaultData.bio}
						label="Bio"
						maxLength={50}
					/>

					<Textarea
						name="description"
						defaultValue={this.defaultData.description}
						label="Description"
						maxLength={400}
					/>
					<ReactiveDependant
						store={categories}
						watch="list"
					>
						{cats => {
							return <ChipsAutocomplete
								name="categories"
								label="Categories"
								required
								popular={cats}
								popularLabel="Popular categories"
								defaultItems={this.defaultData?.categories}
								fetch={async query => categories.filter(query)}
								fetchDefault={async ids => categories.getListByIds(ids)}
								renderInvalidMessage={getValidationForKey("categories")}
							/>
						}}
					</ReactiveDependant>

					<div className="csf-rows">
						<NumberInput
							label="Minimal order price"
							name="orderPrice"
							min={1}
							max={1000}
							required
							defaultValue={Auth.user?.orderPrice}
							startDecoration="$"
							renderInvalidMessage={getValidationForKey("orderPrice", { min: 1 })}
						/>
						<NumberInput
							label="Response time"
							name="responseTime"
							min={1}
							required
							defaultValue={Auth.user?.responseTime}
							startDecoration="days"
							renderInvalidMessage={getValidationForKey("orderResponse", { min: 1 })}
						/>
					</div>
				</div>
			</BlockingForm>
		</>
	}
}