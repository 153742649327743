import React from "react"
import { observer } from "mobx-react"

import VideoUploader from "stores/instantiable/VideoUploader"

import Filepicker from "components/Forms/Misc/Filepicker"
import Progress from "components/UI/Progress"

export type VideoPickerStage =
	| "converting"
	| "uploading"

export interface VideoPickerProps {
	uploader: VideoUploader
	onPick: (
		video: File
	) => void
}

export interface VideoPickerState {

}

@observer
export default
class VideoPicker
extends React.Component<VideoPickerProps, VideoPickerState> {
	render() {
		const { uploader } = this.props
		return !uploader.isInProgress
			? <Filepicker
				maxCount={1}
				noUpload
				allowedMimeTypes={["video/webm", "video/avi", "video/x-msvideo", "video/mp4", "video/quicktime"]}
				onPick={([file]) => this.props.onPick(file)}
			>
				Choose video
			</Filepicker>
			: <div className="c-filepicker">
				<div className="fp-drop-area uploading">
					<Progress
						currentProgress={uploader.progress}
						maxProgress={100}
					>
						{progress => `${Math.floor(progress)}%`}
					</Progress>
					<div className="fp-label">
						{uploader.stage?.capitalize().split("_").join(" ")}...
					</div>
				</div>
			</div>
	}
}