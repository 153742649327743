import React from "react"

import "styles/views/sign-up/components/stepper"

export interface StepperProps {
	steps: number
	current: number
}

export interface StepperState {

}

export default
class Stepper
extends React.Component<StepperProps, StepperState> {
	render() {
		const { steps, current } = this.props
		return <>
			<div className="c-stepper">
				{[...Array(steps)].map((_, i) => {
					return <span
						key={i}
						className={i == current ? "current" : ""}
					/>
				})}
			</div>
		</>
	}
}