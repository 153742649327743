import React from "react"
import { Link } from "react-router-dom"

import "styles/components/ui/buttons/back"

export interface BackProps
extends PropsWithChildren {
	to: string
}

export interface BackState {

}

export default
class Back
extends React.Component<BackProps, BackState> {
	render() {
		return <Link
			to={this.props.to}
			className="c-back"
		>
			<i className="fas fa-arrow-left" />
			<span>
				{this.props.children}
			</span>
		</Link>
	}
}