import Logger from "utils/entities/Logger"

export type VideoPlaybackEvent =
	| "playbackchange"

export type VideoPlaybackListener<K extends VideoPlaybackEvent = VideoPlaybackEvent> = {
	[key in VideoPlaybackEvent]: {
		playbackchange: (
			nowPlaying?: string
		) => void
	}[K]
}

export type VideoPlaybackListeners<K extends VideoPlaybackEvent = VideoPlaybackEvent> = {
	[key in VideoPlaybackEvent]: VideoPlaybackListener[K][]
}

class VideoPlaybackManager {
	private logger
		= new Logger("VideoPlayback manager")

	private listeners
		: VideoPlaybackListeners
		= {
			playbackchange: [],
		}

	private nowPlaying?
		: string

	addEventListener = <K extends VideoPlaybackEvent>(
		type: K,
		listener: VideoPlaybackListener[K]
	) => {
		this.listeners[type].push(listener)
	}

	removeEventListener = <K extends VideoPlaybackEvent>(
		type: K,
		listener: VideoPlaybackListener[K]
	) => {
		const index = this.listeners[type].indexOf(listener)
		if (index >= 0)
			this.listeners[type].splice(index, 1)
	}

	startPlaying = (
		videoIdToPlay: string
	) => {
		this.nowPlaying = videoIdToPlay
		this.listeners.playbackchange.forEach(listener => {
			listener(videoIdToPlay)
		})

		this.logger.info(`Starting to play video *${videoIdToPlay}*`)
	}

	stopPlaying = (
		videoToStop?: string
	) => {
		if (videoToStop != this.nowPlaying)
			return

		this.nowPlaying = undefined
		this.listeners.playbackchange.forEach(listener => {
			listener(undefined)
		})

		if (videoToStop)
			this.logger.warn(`Video *${videoToStop}* has been stopped`)
	}
}

export default new VideoPlaybackManager()