import api from "api"
import { action, computed, makeObservable, observable } from "mobx"
import { UserData } from "typings/api/User"
import { sha256 } from "utils/crypto"
import { postMessageToWorker } from "utils/sw"
import Captcha from "./Captcha"
import { ModalMarker } from "./instantiable/ModalMarker"
import VideoProfile from "./instantiable/VideoProfile"
import Meta from "./Meta"

class AuthStore {
	private loginReq
		= api.account.login()

	private logoutReq
		= api.account.logout()

	private identityReq
		= api.account.identity()

	@observable
	private _isAuthChecked
		: boolean
		= false

	@observable
	private _user?
		: UserData

	@action
	private setUserData = (
		data?: UserData
	) => {
		this._user = data
		this._isAuthChecked = true
	}

	constructor() {
		makeObservable(this)
	}

	loginModal
		= new ModalMarker()

	emailVerification
		= new ModalMarker()

	videoProfile?
		: VideoProfile
		= undefined

	@computed
	get isLoggedIn(): boolean {
		return !!this._user
	}

	@computed
	get isAuthChecked(): boolean {
		return this._isAuthChecked
	}

	@computed
	get user(): UserData | undefined {
		return this._user
	}

	@computed
	get isCreator(): boolean {
		return this._user?.type == "creator"
	}

	@computed
	get isNotVerified(): boolean {
		return this.isLoggedIn && !this.user?.isEmailVerified
	}

	login = async (
		email: string,
		password: string,
	): Promise<void> => {
		await Captcha.verify("login")
		
		return this.loginReq.request({
			email,
			password: sha256(password),
		}).then(() => {
			return this.identity()
		})
	}

	logout = () => {
		return this.logoutReq.request()
			.then(() => {
				if (this.videoProfile) {
					this.videoProfile?.uploader.abort()
					this.videoProfile = undefined
				}

				this.setUserData(undefined)
				postMessageToWorker("logout", {
					tabId: Meta.tabId,
				})
			})
	}

	identity = () => {
		return this.identityReq.request()
			.then(response => {
				if (response.data.account.type == "creator") {
					this.videoProfile = new VideoProfile(response.data.account.id)
					this.videoProfile.fill()
				}

				this.setUserData(response.data.account)
				postMessageToWorker("identity_changed", {
					tabId: Meta.tabId,
					account: response.data.account,
				})
			})
			.catch(() => {
				this.setUserData()
			})
	}

	externalUpdate = (
		data?: UserData
	) => {
		this.setUserData(data)
	}

	@action
	update = (
		data: Partial<UserData>
	) => {
		if (!this.user)
			return

		this.setUserData({
			...this.user,
			...data,
		})
	}
}

export default new AuthStore()