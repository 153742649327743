import api from "api"
import { action, computed, IReactionDisposer, makeObservable, observable, reaction } from "mobx"
import { PublicUserData } from "typings/api/User"
import { SearchSorting, SearchSortingRequest } from "typings/Search"
import { noop } from "utils"
import Search from "./Search"

const searchSortingToRequest = (
	sorting?: SearchSorting,
): SearchSortingRequest | undefined => {
	switch (sorting) {
		case "popular": return "recommended"
		case "priceHighLow": return "priceDesc"
		case "priceLowHigh": return "price"
		case "mostReviewed": return "reviewed"
		case "topRated": return "newest"
		default: return undefined
	}
}

export class UsersListStore {
	static PAGE_SIZE
		: number
		= 12

	private listReq
		= api.account.list()

	@observable
	private page
		: number
		= 0

	@observable
	private totalPages
		: number
		= 1

	@observable
	private loading
		: boolean
		= false

	@observable
	private _list?
		: PublicUserData[]
		= undefined

	@action
	private setList = (
		list: PublicUserData[],
		totalPages: number = 0
	) => {
		if (this.page == 1 || !this._list)
			this._list = list
		else
			this._list.push(...list)

		this.setList

		this.totalPages = totalPages
	}

	private setUpFiltersReaction = () => {
		this.filtersReaction = reaction(
			() => ({
				currentPage: this.page,
				...Search.filters,
			}),
			(filters) => {
				if (this.loading || !filters.currentPage)
					return
	
				this.listReq.abort()
				this.listReq.request({
					pageNumber: filters.currentPage,
					pageSize: UsersListStore.PAGE_SIZE,
					// isCelebrity: filters.type == "celebrities",
					// minOrderPrice: filters.priceFrom,
					// maxOrderPrice: filters.priceTo,
					orderBy: searchSortingToRequest(filters.sort),
					// responseTime: filters.response,
				}).then((response => {
					const { totalPages, accounts } = response.data
					this.setList(accounts, totalPages)
				})).catch(noop)
			},
			{
				fireImmediately: true,
				equals: (a, b) => {
					const aKeys = Object.keys(a)
					const bKeys = Object.keys(b)
					return aKeys.length == bKeys.length
						&& aKeys.every(key => {
							return bKeys.includes(key)
								&& a[key] === b[key]
						})
				}
			}
		)
	}

	protected filtersReaction
		: IReactionDisposer

	constructor() {
		makeObservable(this)
		this.setUpFiltersReaction()
	}

	@computed
	get list() {
		return this._list
	}

	@computed
	get isLoading(): boolean {
		return !this._list || this.loading
	}

	@computed
	get hasNextPage(): boolean {
		return this.page < this.totalPages
	}

	@action
	nextPage = () => {
		if (this.loading || !this.hasNextPage)
			return

		this.page += 1
		console.log(this.page)
	}

	@action
	clearStore = () => {
		this.listReq.abort()

		this.page = 0
		this.totalPages = 1
		this.loading = false
		this._list = undefined
	}
}

const store = new UsersListStore()

export default store