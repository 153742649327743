import React from "react"
import { Link, RouteComponentProps, withRouter } from "react-router-dom"

import "styles/components/global/header/components/user-menu"

import Routing from "stores/Routing"

import PopupWithTrigger from "./PopupWithTrigger"
import AvatarRound from "components/Global/User/avatars/Round"
import Auth from "stores/Auth"
import { observer } from "mobx-react"
import Confirms from "stores/Confirms"

export interface UserMenuProps
extends RouteComponentProps<any> {

}

export interface UserMenuState {

}

export type UserMenuItem = {
	action: string | (() => void)
	caption: React.ReactNode
	icon: string
}

@observer
class UserMenu
extends React.Component<UserMenuProps, UserMenuState> {
	private menu: (UserMenuItem | string)[] = [
		...(Auth.user?.type == "user"
			? []
			: [
				"---",
				{
					action: Routing.compile.user(Auth.user!.id).profile(),
					caption: "Profile",
					icon: "fas fa-address-card",
				},
			]
		),
		"---",
		{
			action: Routing.compile.account.home(),
			caption: "Account",
			icon: "fas fa-user",
		},
		{
			action: Routing.compile.account.settings.home(),
			caption: "Settings",
			icon: "fas fa-cog",
		},
		"---",
		{
			action: () => Confirms.create(
				Auth.logout,
				{
					title: "Logout confirmation",
					description: "Are you sure you want to logout?",
					actionLabels: {
						yes: "Logout",
						no: "Cancel"
					}
				}
			),
			caption: "Log out",
			icon: "fas fa-sign-out-alt",
		}
	]

	private closeHook?
		: () => void

	componentDidUpdate(prevProps: UserMenuProps) {
		if (this.props.location.pathname != prevProps.location.pathname)
			this.closeHook?.()
	}

	renderMenuItemContent = (
		item: UserMenuItem
	) => {
		return <>
			<i className={item.icon} />
			<span className="caption">
				{item.caption}
			</span>
		</>
	}

	render() {
		const { user } = Auth
		return <>
			<PopupWithTrigger
				setCloseHook={hook => this.closeHook = hook}
				elementProps={{
					className: "c-header-popup c-user-menu",
				}}
				trigger={{
					props: {
						className: `c-header-popup-trigger c-user-menu-button`
					},
					chidren: <>
						<AvatarRound
							src={user?.avatar}
						/>
					</>
				}}
			>
				<div className="um-user-info">
					<div className="name">
						{user?.fullname}
					</div>
					<p className="type u-dimmed">
						{user?.type}
					</p>
				</div>
				{this.menu.map((item, i) => {
					if (typeof item == "string")
						return <div
							key={i}
							className="um-delimiter"
						/>

					return typeof item.action == "string"
						? <Link
							key={i}
							to={item.action}
							className="um-item"
						>
							{this.renderMenuItemContent(item)}
						</Link>
						: <div
							key={i}
							onClick={item.action}
							className="um-item"
						>
							{this.renderMenuItemContent(item)}
						</div>
				})}
			</PopupWithTrigger>
		</>
	}
}

export default withRouter(UserMenu)