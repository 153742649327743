import React from "react"
import Auth from "stores/Auth"

import "styles/components/global/not-verified"

export interface NotVerifiedProps {

}

export interface NotVerifiedState {
	isHidden: boolean
	isShifted: boolean
}

export default
class NotVerified
extends React.Component<NotVerifiedProps, NotVerifiedState> {
	state
		: NotVerifiedState
		= {
			isHidden: false,
			isShifted: true,
		}

	componentDidMount() {
		setTimeout(() => {
			this.setState({
				isShifted: false,
			})
		}, 100)
	}

	hide = () => {
		if (!this.state.isHidden)
			this.setState({
				isHidden: true,
			})
	}

	show = () => {
		if (this.state.isHidden)
			this.setState({
				isHidden: false,
			})
	}

	render() {
		return <>
			<div className="c-not-verified">
				<div
					className={`nv-card ${
						this.state.isHidden ? "hidden" : "" } ${
						this.state.isShifted ? "shifted" : ""
					}`}
					onClick={this.show}
				>
					<i className="fas fa-exclamation-triangle" />
					<div className="nv-text">
						<div>
							<strong>
								Your account is not verified
							</strong>
						</div>
						<div>
							<span>
								Please verify your email to continue using the platform
							</span>
						</div>
					</div>
					<div className="nv-actions">
						<div
							className="u-button x-small default nv-verify"
							onClick={Auth.emailVerification.show}
						>
							<div className="ub-inner-wrapper">
								Verify
							</div>
						</div>
						<div
							className="u-button x-small nv-close"
							onClick={this.hide}
						>
							<div className="ub-inner-wrapper">
								<i className="fas fa-times" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	}
}