import React from "react"
import { observer } from "mobx-react"

import "styles/components/global/user/cards"
import "styles/components/global/user/cards/horizontal"

import { PublicUserData } from "typings/api/User"

import SmoothImage from "components/UI/SmoothImage"
import UserIcon from "components/UI/Decorations/Static/Icons/User"
import Rating from "components/UI/Decorations/Static/Rating"
import UserCardCategories from "./components/Categories"

export interface UserCardHorizontalProps {
	user: PublicUserData
}

export interface UserCardHorizontalState {

}

@observer
export default
class UserCardHorizontal
extends React.Component<UserCardHorizontalProps, UserCardHorizontalState> {
	private get hasAvatar(): boolean {
		return !!this.props.user.avatar
	}

	render() {
		const { user } = this.props
		return <>
			<div className="c-user-card horizontal u-paper no-padding">
				<div className="uc-avatar-wrapper">
					{this.hasAvatar
						? <div className="u-square">
							<SmoothImage src={user.avatar!} />
						</div>
						: <div className="uc-user-icon">
							<UserIcon />
						</div>
					}
				</div>
				<div className="uc-content">
					<h1>
						{user.fullname} <img src="/static/images/icons/verified_celebrity.svg" alt="verified" />
					</h1>
					<UserCardCategories
						categories={user.categories}
					/>
					<p className="uc-subtext">
						{user.bio}
					</p>
					<div className="uc-stats">
						<Rating
							value={user.rating.value}
							tbd={!user.rating.count}
							user={user}
						/>
					</div>
				</div>
			</div>
		</>
	}
}