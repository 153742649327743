import React from "react"
import { observer } from "mobx-react"

import "styles/views/sign-up/components/steps/step-4"

import { getValidationForKey } from "utils/validation"

import { passwordPattern } from "consts/patterns"

import SignUpDefaultStep, { SignUpDefaultStepProps, SignUpDefaultStepState } from "./DefaultStep"
import Form from "components/Forms/Form"
import SimpleInput from "components/Forms/Inputs/Simple"
import Captcha from "stores/Captcha"

export interface SignUpStep4Props
extends SignUpDefaultStepProps {

}

export interface SignUpStep4State
extends SignUpDefaultStepState {
	password: string
}

@observer
export default
class SignUpStep4
extends SignUpDefaultStep<SignUpStep4Props, SignUpStep4State> {
	state
		: SignUpStep4State
		= {
			fadeOut: false,
			loading: false,
			password: "",
		}

	checkCaptcha = (
		data: any
	) => {
		if (this.state.loading)
			return

		this.setState({
			loading: true
		})

		Captcha.verify("signup").then(() => {
			this.setState({
				loading: false,
			}, () => {
				this.handleSubmit(data)
			})
		}).catch(() => {
			this.setState({
				loading: false
			})
		})
	}

	render() {
		const { fadeOut } = this.state
		return <>
			<div 
				className={`section-content fade-${fadeOut ? "out" : "in"} ${this.props.direction}`}
				onAnimationEnd={this.handleAnimationEnd}
			>
				<h1>
					Complete Registration
				</h1>

				<Form
					className="su-step step-4"
					onSubmit={this.checkCaptcha}
				>
					<SimpleInput
						type="email"
						name="email"
						label="Email"
						placeholder="johndoe@gmail.com"
						disabled={this.state.loading}
						required
						onBeforeChange={value => {
							this.setState({ error: undefined })
							return value
						}}
						renderInvalidMessage={getValidationForKey("email")}
					/>

					<SimpleInput
						name="password"
						required
						type="password"
						label="Create password"
						pattern={passwordPattern}
						minLength={8}
						disabled={this.state.loading}
						onBeforeChange={value => {
							this.setState({ password: value })
							return value
						}}
						renderInvalidMessage={getValidationForKey("password")}
					/>
					
					<SimpleInput
						name="passwordConfirm"
						required
						type="password"
						pattern={this.state.password}
						label="Confirm password"
						disabled={this.state.loading}
						renderInvalidMessage={getValidationForKey("passwordConfirm")}
					/>

					<div className="u-recaptcha-disclaimer">
						This site is protected by reCAPTCHA and the Google <a
							href="https://policies.google.com/privacy"
							target="_BLANK"
							rel="noopener"
							className="u-link"
						>
							Privacy Policy
						</a> and <a
							href="https://policies.google.com/terms"
							target="_BLANK"
							rel="noopener"
							className="u-link"
						>
							Terms of Service
						</a> apply.
					</div>

					{this.renderActions(
						!Captcha.isReady,
						undefined,
						"Sign up"
					)}

					{this.state.error &&
						<div className="su-server-error">
							{this.state.error == "400"
								? "Email is already in use"
								: this.state.error
							}
						</div>
					}
				</Form>
			</div>
		</>
	}
}