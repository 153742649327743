import React from "react"
import Helmet from "react-helmet"
import { Element as Tooltip } from "@sounds.of.limbo/tooltip"

import "styles/components/ui/decorations/static/rating"

import { PublicUserData } from "typings/api/User"

export interface RatingProps {
	value: number
	tbd?: boolean
	user?: PublicUserData
	tooltip?: React.ReactNode
	formatting?: (
		value: number | "tbd"
	) => React.ReactNode
}

export interface RatingState {

}

export default
class Rating
extends React.Component<RatingProps, RatingState> {
	private max
		: number
		= 5

	get relative(): number {
		const { value } = this.props
		return !value
			? 0
			: value == this.max
				? 1
				: (value - 1) / this.max * 0.8
	}

	get formatted(): React.ReactNode {
		const {
			value,
			formatting = value => value == "tbd" ? "N/A" : Number(value.toFixed(2)),
		} = this.props
		const formattable = Math.floor(value * 10) / 10
		return formatting(this.props.tbd ? "tbd" : formattable)
	}

	render() {
		const { user } = this.props
		return <>
			{user && user.rating.count > 0 &&
				<Helmet>
					<script type="application/ld+json">
						{`
							{
								"@context": "https://schema.org",
								"@type": "Product",
								"aggregateRating": {
									"@type": "AggregateRating",
									"ratingValue": "${user.rating.value}",
									"ratingCount": "${user.rating.count}",
									"bestRating": "5",
									"worstRating": "1"
								},
								"description": "${user.bio}",
								"name": "${user.fullname}",
								${user.avatar ? `"image": "${user.avatar}"` : ""}
								"offers": {
									"@type": "Offer",
									"availability": "https://schema.org/InStock",
									"price": "${user.orderPrice}",
									"priceCurrency": "USD"
								}
							}
						`}
					</script>
				</Helmet>
			}
			<Tooltip
				element="div"
				elementProps={{
					className: "c-rating",
				} as React.HTMLAttributes<HTMLDivElement>}
				content={this.props.tooltip}
			>
				<div className="icon">
					<i className="background fas fa-star" />
					<i className="far fa-star" />
					<i
						className="fas fa-star"
						style={{ height: `${this.relative * 100}%`}}
					/>
				</div>
				<div className="value">
					{this.formatted}
				</div>
			</Tooltip>
		</>
	}
}