import React from "react"

import "styles/components/ui/tabs/switch"

import { SelectOption } from "typings/Form"

import { noop } from "utils"

export interface SwitchTabsProps<T> {
	tabs: SelectOption<T>[]
	defaultTab?: T
	name?: string
	onSelect?: (
		value: T
	) => void
}

export interface SwitchTabsState<T> {
	currentTab: T
}

export default
class SwitchTabs<T>
extends React.Component<SwitchTabsProps<T>, SwitchTabsState<T>> {
	state
		: SwitchTabsState<T>
		= {
			currentTab: this.props.defaultTab || this.props.tabs[0].value
		}

	selectTab = (
		tab: T
	) => {
		this.setState({
			currentTab: tab
		}, () => {
			this.props.onSelect?.(this.state.currentTab)
		})
	}

	render() {
		const { tabs } = this.props
		const { currentTab } = this.state
		const currentIndex = tabs.findIndex(item => item.value == currentTab)

		return <>
			{this.props.name &&
				<input
					type="text"
					name={this.props.name}	
					value={`${currentTab}`}
					onChange={noop}
					style={{ display: "none" }}
				/>
			}
			<div className="c-switch-tabs">
				<div className="items">
					{tabs.map(item => {
						const isSelected = item.value == currentTab
						return <div
							key={item.value as any}
							className={`item ${isSelected ? "selected" : ""}`}
							onClick={() => this.selectTab(item.value)}
						>
							{item.label}
						</div>
					})}

					<div
						className="thumb"
						style={{
							width: `calc(100% / ${tabs.length})`,
							left: `${100 / tabs.length * currentIndex}%`
						}}
					/>
				</div>
			</div>
		</>
	}
}