import React from "react"
import { RouteComponentProps } from "react-router"
import { computed, makeObservable, toJS } from "mobx"
import { observer } from "mobx-react"

import "styles/views/account/views/settings/views/videos"

import { noop } from "utils"

import Auth from "stores/Auth"
import Confirms from "stores/Confirms"

import BlockingForm from "../../components/BlockingForm"
import OrderingVertical from "components/UI/Ordering/Vertical"
import Video from "components/Media/Video"
import VideoPicker from "components/Forms/Misc/VideoPicker"
import VideoEdit from "./components/Edit"
import { UserVideo } from "typings/api/User"

export interface VideosSettingsProps
extends RouteComponentProps<any> {
	
}

export interface VideosSettingsState {
	videos: string[]
	videoToEdit?: string
}

type VideoActionType = 
	| "edit"
	| "remove"

@observer
export default
class VideosSettings
extends React.Component<VideosSettingsProps, VideosSettingsState> {
	private videoActions
		: {
			type: VideoActionType,
			icon: string
		}[]
		= [
			{
				type: "edit",
				icon: "pen",
			},
			{
				type: "remove",
				icon: "trash-alt",
			},
		]

	@computed
	private get defaultData(): string[] {
		return (Auth.videoProfile?.ids || [])
	}

	private updateVideos = () => {
		this.setState({
			videos: toJS(Auth.videoProfile?.ids || [])
		})
	}

	private remove = async (
		id: string
	) => {
		const newList = toJS(Auth.videoProfile!.list).filter(item => {
			return item.id != id
		}).map((video, i) => {
			return {
				...video,
				order: i
			}
		})

		await Confirms.create(
			() => Auth.videoProfile!.update(newList).then(this.updateVideos),
			{
				title: "Remove video?",
				description: "Are you sure you want to remove this video? This action cannot be undone.",
			},
		)
	}

	private getVideoById = (
		videoId: string
	) => {
		return Auth.videoProfile?.list.find(video => video.id == videoId)
	}

	constructor(props: VideosSettingsProps) {
		super(props)

		makeObservable(this)
	}

	state
		: VideosSettingsState
		= {
			videos: toJS(Auth.videoProfile?.ids || []),
		}

	handleOrderChange = (
		videos: string[]
	) => {
		this.setState({
			videos,
		})
	}

	handlePick = (
		video: File
	) => {
		Auth.videoProfile?.upload(video).then(video => {
			return Auth.videoProfile?.add(video).then(this.updateVideos)
		}).catch(noop)
	}

	handleAction = (
		type: VideoActionType,
		id: string,
	) => {
		switch (type) {
			case "remove":
				this.remove(id)
				break
			case "edit":
				this.startEdit(id)
				break
		}
	}

	handleSubmit = async (
		data: {
			videos: string[]
		}
	): Promise<void> => {
		const { videos } = data
		const sortedVideos = toJS(Auth.videoProfile?.list || []).sort((a, b) => {
			return videos.indexOf(a.id) - videos.indexOf(b.id)
		}).map((video, i) => {
			return {
				...video,
				order: i,
			}
		})

		await Auth.videoProfile?.update(sortedVideos)
	}

	handleVideoUpdate = async (
		newVideo: UserVideo
	) => {
		return Auth.videoProfile?.update(toJS(Auth.videoProfile?.list || []).map(item => {
			return item.id == newVideo.id
				? {
					...newVideo,
					order: item.order,
				}
				: item
		}))
	}

	startEdit = (
		videoId: string,
	) => {
		this.setState({
			videoToEdit: videoId,
		})
	}

	endEdit = () => {
		this.setState({
			videoToEdit: undefined,
		})
	}

	render() {
		const { videos } = this.state
		const { videoProfile } = Auth
		const videoToEdit = this.state.videoToEdit
			? this.getVideoById(this.state.videoToEdit)
			: undefined

		return <>
			{videoToEdit &&
				<VideoEdit
					video={videoToEdit}
					onChange={this.handleVideoUpdate}
					onClose={this.endEdit}
				/>
			}

			<BlockingForm
				data={{
					videos: this.defaultData
				}}
				className="c-videos-settings"
				onSubmit={this.handleSubmit}
				__debugName="Videos list"
			>
				<LocalInput
					name="videos"
					value={videos.join("|")}
				/>
				<div className="csf-inner-wrapper">
					<div className="vs-video-picker">
						<VideoPicker
							onPick={this.handlePick}
							uploader={videoProfile?.uploader!}
						/>
					</div>

					<OrderingVertical
						key={videos.length}
						defaultOrder={videos}
						onOrderChange={this.handleOrderChange}
						draggedLeftOffset={20}
						classNames={{
							item: ""
						}}	
					>
						{({ trigger, id }) => {
							const video = videoProfile?.list.find(item => item.id == id)
							if (!video)
								return null

							return <div key={id} className="vs-video-row">
								<div className="left">
									{trigger}
									<div className="cover">
										<div>
											<div>
												<Video
													noPlay
													showTime
													video={video}
												/>
											</div>
										</div>
									</div>
									<div className="meta-data">
										<p className="name">
											{video.name}
										</p>
										<span className="size">
											{video.size.toVerboseSize()}
										</span>
									</div>
								</div>

								<div className="right">
									{this.videoActions.map(action => {
										return <div
											key={action.type}
											onClick={() => this.handleAction(action.type, video.id)}
										>
											<i className={`fas fa-${action.icon}`} />
										</div>
									})}
								</div>
							</div>
						}}
					</OrderingVertical>
					{!videos.length &&
						<p className="u-dimmed u-center">
							<span className="u-not-found-message">
								There are no videos in your profile yet
							</span>
						</p>
					}
				</div>
			</BlockingForm>
		</>
	}
}

interface LocalInputProps {
	name: string
	value: string
}

class LocalInput
extends React.Component<LocalInputProps, {}> {
	input?
		: HTMLInputElement

	componentDidUpdate(prevProps: LocalInputProps) {
		if (this.props.value !== prevProps.value) {
			if (!this.input)
				return

			const valueSetter = Object.getOwnPropertyDescriptor(
				window.HTMLInputElement.prototype,
				"value",
			)

			valueSetter?.set?.call(this.input, this.props.value)
			const manualEvent = new Event("change", { bubbles: true })
			this.input.dispatchEvent(manualEvent)
		}
	}

	render() {
		const { name, value } = this.props
		return <>
			<input
				ref={r => this.input = r!}
				type="text"
				name={name}
				defaultValue={value}
				required
				data-subtype="array"
				onChange={noop}
				style={{
					display: "none",
				}}
			/>
		</>
	}
}