import React from "react"
import { v4 as uuid } from "uuid"

import "styles/components/forms/inputs/simple"

import { FormElementInputLikeProps, InvalidationKey } from "typings/Form"
import { checkValidation } from "utils/forms"

export interface SimpleInputProps
extends FormElementInputLikeProps<string> {
	type?: "text" | "number" | "email" | "password"
	inputmode?: "text" | "decimal" | "numeric"
	minLength?: number
	maxLength?: number
	pattern?: string
	trimValue?: "start" | "end" | "both"
	endDecoration?: React.ReactNode
	labelEndContent?: React.ReactNode
	onBeforeChange?: (nextValue: string) => string
}

export interface SimpleInputState {
	value: string,
	forceType?: SimpleInputProps["type"]
	invalid?: InvalidationKey
}

export default
class SimpleInput
extends React.Component<SimpleInputProps, SimpleInputState> {
	static defaultProps
		: Partial<SimpleInputProps>
		= {
			onBeforeChange: value => value,
			renderInvalidMessage: key => key
		}

	private id
		: string
		= this.props.id || uuid()

	private lastInvalidationKey
		: InvalidationKey
		= "valueMissing"

	state
		: SimpleInputState
		= {
			value: this.props.defaultValue || "",
			invalid: undefined
		}

	componentDidUpdate(
		prevProps: SimpleInputProps,
		prevState: SimpleInputState,
	) {
		// TODO check if validation influence props have changed
		// and, if yes, remove this.state.invalid
		prevProps
		prevState
	}

	handleChange = (
		event: React.FormEvent<HTMLInputElement>
	) => {
		if (this.props.disabled)
			return
			
		var { value } = event.currentTarget
		
		value = this.props.onBeforeChange!(value)
		switch (this.props.trimValue) {
			case "both":
				value = value.trim()
				break
			case "start":
				value = value.trimStart()
				break
			case "end":
				value = value.trimEnd()
				break
		}

		this.setState({
			value,
			invalid: undefined
		})
	}

	handleInvalid = (
		event: React.FormEvent<HTMLInputElement>
	) => {
		event.preventDefault()

		const invalidationKey = checkValidation(event.currentTarget)
		this.lastInvalidationKey = invalidationKey || this.lastInvalidationKey
		this.setState({
			invalid: invalidationKey
		})
	}

	toggleForcePasswordText = () => {
		this.setState({
			forceType: this.state.forceType ? undefined : "text"
		})
	}

	render() {
		const { value, invalid, forceType } = this.state
		const {
			required,
			type = "text",
			maxLength,
			endDecoration,
		} = this.props

		const isInvalid = invalid
		const isFilled = !!value

		return <>
			<div 
				className={`c-simple-input ${
					isInvalid ? "invalid" : "" } ${
					isFilled ? "filled" : "" 
				}`}
			>
				{this.props.label &&
					<label
						htmlFor={this.id}
						className="u-input-label"
					>
						<span className="label-content">
							{this.props.label}
						</span>
						{this.props.maxLength &&
							<span>
								{value.length}/{maxLength}
							</span>
						}
						{!this.props.maxLength && this.props.labelEndContent &&
							<span>
								{this.props.labelEndContent}
							</span>
						}
					</label>
				}
				
				<div className="si-input-wrapper">
					<input 
						id={this.id}
						className={`u-input ${
							isInvalid ? "invalid" : "" } ${
							isFilled ? "filled" : ""
						}`}
						value={value}
						placeholder={this.props.placeholder}
						name={this.props.name}
						type={forceType || type}
						required={required} 
						pattern={this.props.pattern}
						minLength={this.props.minLength}
						maxLength={this.props.maxLength}
						onChange={this.handleChange}
						onInvalid={this.handleInvalid}
						disabled={this.props.disabled}
						readOnly={this.props.readonly}
						inputMode={this.props.inputmode}
					/>
					{type == "password" && !endDecoration &&
						<i 
							className={`fas fa-eye${forceType ? "" : "-slash"} password-visibility-toggler`}
							onClick={this.toggleForcePasswordText}
						/>
					}
					{endDecoration &&
						<div className="end-decoration">
							{endDecoration}
						</div>
					}
				</div>

				<div 
					className={`u-invalidation-message ${
						isInvalid ? "" : "hidden"
					}`}
				>
					{this.props.renderInvalidMessage?.(this.lastInvalidationKey)}
				</div>
			</div>
		</>
	}
}