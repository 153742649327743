import _isEqual from "lodash.isequal"
import _set from "lodash.set"

/**
 * Assign value to specified property by its path.  
 * **WARNING!** This function modifies source object!
 * 
 * Temporary supports only single-nested arrays, e.g. you cannot 
 * set value to path like `foo.bar[0][1][2].baz`  
 * Indexes `1` and `2` will be ignored.
 * 
 * ```typescript
 * var someObject = {}
 * assignValueByPath(someObject, "foo.bar[0].baz", 2)
 * 
 * console.log(someObject) 
 * // ^ will now be 
 * // { 
 * //   foo: { 
 * //     bar: [
 * //       { baz: 2 }
 * //     ]
 * //   }
 * // }
 * ```
 */
  export const assignValueByPath = (
	target: any,
	path: string,
	value: any
): void => {
	const [head, ...tail] = path.split(".")
	const [key, index] = head.split(/[\[\]]/g)
	const numericIndex = Number(index)
	const hasTail = tail.length > 0

	if (index) {
		if (!Array.isArray(target[key]))
			target[key] = []
		if (hasTail) {
			if (typeof target[key][numericIndex] != "object")
				target[key][numericIndex] = {}
			assignValueByPath(target[key][numericIndex], tail.join("."), value)
		} else
			target[key][numericIndex] = value
	} else {
		if (hasTail) {
			if (typeof target[key] != "object")
				target[key] = {}
			assignValueByPath(target[key], tail.join("."), value)
		} else
			target[key] = value
	}
}

export const isEqual = (
	object1: any,
	object2: any,
): boolean => {
	return _isEqual(object1, object2)
}

export const set = (
	object: any,
	path: string,
	value: any,
) => {
	_set(object, path, value)
}