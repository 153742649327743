import { SettingsTab } from "typings/Settings"

export const userTabs: SettingsTab[] = [
	"account",
]

export const creatorTabs: SettingsTab[] = [
	"profile",
	"account",
	"content",
]

export const settingsTabsLabels: {
	[key in SettingsTab]: string
} = {
	profile: "Profile",
	account: "Account",
	content: "Content",
}