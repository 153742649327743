import React from "react"
import { observer } from "mobx-react"

import "styles/components/global/header/components/notifications"

import NotificationsStore from "stores/Notifications"

import SmoothCounter from "components/UI/SmoothCounter"
import PopupWithTrigger from "./PopupWithTrigger"

export interface NotificationsProps {

}

export interface NotificationsState {
	animateBell: boolean
}

@observer
export default 
class Notifications
extends React.Component<NotificationsProps, NotificationsState> {
	state
		: NotificationsState
		= {
			animateBell: false,
		}

	tryBellAnimate = () => {
		if (!this.state.animateBell)
			this.startBellAnimate()
	}

	startBellAnimate = () => {
		this.setState({
			animateBell: true
		})
	}

	finishBellAnimate = (
		event: React.AnimationEvent<HTMLElement>
	) => {
		if (event.animationName == "ring")
			this.setState({
				animateBell: false
			})
	}

	render() {
		const { unreadCount, hasUnread } = NotificationsStore
		const { animateBell } = this.state
		return <>
			<PopupWithTrigger
				elementProps={{
					className: "c-header-popup c-notifications-popup",
				}}
				trigger={{
					props: {
						className: `c-header-popup-trigger c-notifications-button u-fade-in ${animateBell ? "ring" : ""}`,
						onMouseEnter: this.tryBellAnimate
					},
					chidren: <>
						<i 
							className="fas fa-bell"
							onAnimationEnd={this.finishBellAnimate}
						/>
						<span className={`nb-counter ${hasUnread ? "" : "hidden"}`}>
							<SmoothCounter 
								duration={360}
								timingFunction="linear"
							>
								{unreadCount}
							</SmoothCounter>
						</span>
					</>,
				}}
			>
				Hey, man, cool content!
			</PopupWithTrigger>
		</>
	}
}