import api from "api"
import SimplePreloader from "components/UI/Preloaders/Simple"
import React from "react"
import Main from "stores/Main"

export interface CustomerEmailProps {
	orderId: DBID
}

export interface CustomerEmailState {
	error: boolean
	email?: string
}

export default
class CustomerEmail
extends React.Component<CustomerEmailProps, CustomerEmailState> {
	private emailReq
		= api.orders.customerEmail()

	state
		: CustomerEmailState
		= {
			email: undefined,
			error: false,
		}

	componentDidMount() {
		const { orderId } = this.props
		this.emailReq.request({ orderId })
			.then(response => {
				this.setState({ email: response.data.email })
			})
			.catch(() => {
				this.setState({ error: true })
			})
	}

	componentWillUnmount() {
		this.emailReq.abort()
	}

	get mailContent(): string {
		return encodeURIComponent(`Dear customer,

			I'm a creator at your order (https://himyfan.com/me/orders/${this.props.orderId}) and I have a couple of questions related to it.
		`)
	}

	render() {
		const { email, error } = this.state
		if (!email)
			return <SimplePreloader color="black" />
		if (error)
			return <span className="u-color negative">
				Failed to retrieve an email
			</span>
		return <>
			<a
				className="u-link"
				href={`mailto:${email}?subject=${Main.projectName}: Clarifications to order ${this.props.orderId}&body=${this.mailContent}`}
				target="_BLANK"
				rel="noopener"
			>
				Email
			</a>
		</>
	}
}