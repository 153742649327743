import React from "react"

import "styles/views/account/views/orders/components/card/components/content/creator"

import Form from "components/Forms/Form"
import { OrderData, OrderStatus } from "typings/api/Orders"
import SimplePreloader from "components/UI/Preloaders/Simple"
import api from "api"
import CustomerEmail from "../../../CustomerEmail"
import VideoUploader from "stores/instantiable/VideoUploader"
import VideoPicker from "components/Forms/Misc/VideoPicker"
import AddVideoToProfile from "../AddVideoToProfile"
import { Video } from "typings/Media"
import { getOrderStatusesArray } from "utils/orders"
import OrderDefaultContent from "./Default"
import Confirms from "stores/Confirms"
import { noop } from "utils"

export interface OrderCreatorContentProps {
	order: OrderData
	onStatusChange: (
		newStatus: OrderStatus,
		additionalData?: Partial<OrderData>
	) => void
}

export interface OrderCreatorContentState {
	loading: boolean
	video?: Video
}

export default
class OrderCreatorContent
extends React.Component<OrderCreatorContentProps, OrderCreatorContentState> {
	private updateReq
		= api.orders.updateAsCreator()

	private uploader
		= new VideoUploader()

	get isStatusCompletable(): boolean {
		const { status } = this.props.order
		return status == "new"
			|| status == "pending"
	}
	
	state
		: OrderCreatorContentState
		= {
			loading: false,
			video: this.props.order.content,
		}

	componentDidMount() {
		if (this.props.order.status == "new")
			api.orders.updateAsCreator().request({
				orderId: this.props.order.id,
				status: "pending",
			})
	}

	componentWillUnmount() {
		this.updateReq.abort()
		this.uploader.abort()
	}

	handleUploadVideo = (
		video: Video,
	) => {
		this.setState({
			video,
		})
	}

	removeVideo = () => {
		this.setState({
			video: undefined,
		})
	}

	updateOrder = () => {
		if (!this.isStatusCompletable)
			return

		if (this.state.loading || !this.state.video)
			return

		this.setState({
			loading: true,
		})

		this.updateReq.request({
			content: this.state.video,
			orderId: this.props.order.id,
			status: "completed",
		}).then(() => {
			this.props.onStatusChange("completed")
		}).finally(() => {
			this.setState({
				loading: false
			})
		})
	}

	upload = async (
		file: File
	) => {
		try {
			const video = await this.uploader.start(file)
			this.handleUploadVideo(video)
		} catch (e) {}
	}

	reject = () => {
		Confirms.prompt(
			prompt => this.updateReq.request({
				orderId: this.props.order.id,
				status: "rejected",
				rejectionReason: prompt,
			}).then(() => {
				this.props.onStatusChange(
					"rejected",
					{
						rejectionReason: prompt,
					}
				)
			}).catch(noop),

			{
				title: "Are you sure?",
				description: "Once you reject the order, you won't be able to complete it and won't receive payment for it. If you are sure you want to reject the order, please consider to specify the reason of rejection:",
				prompt: {
					label: "Rejection reason:",
					placeholder: "Specify the rejection reason",
				},
				actionLabels: {
					yes: "Reject order",
					no: "Cancel",
				},
				classNames: {
					actions: {
						primary: "red",
					},
				},
			}
		)
	}

	render() {
		const { loading, video } = this.state
		const { order } = this.props
		const { status } = order
		return <>
			<div className="c-order-content c-order-creator-content">
				<OrderDefaultContent
					order={order}
				/>
				{getOrderStatusesArray("new", "pending").includes(order.status) &&
					<div className="contact">
						<p className="otc-text light">
							Contact customer by <CustomerEmail orderId={order.id} />
						</p>
					</div>
				}
				{!getOrderStatusesArray("expired", "rejected").includes(order.status) &&
					<Form
						className="video-uploader"
						onSubmit={this.updateOrder}
					>
						<div className={`filepicker ${video ? "has-file" : ""}`}>
							{!video
								? <VideoPicker
									onPick={this.upload}
									uploader={this.uploader}
								/>
								: <>
									<video
										src={video.sources.find(item => item.resolution == "native")?.url}
										controls
									/>
									<div className="content">
										<div className="name">
											<span>
												{video.name}
											</span>
											{this.isStatusCompletable &&
												<i
													onClick={this.removeVideo}
													className="fas fa-trash-alt"
												/>
											}
										</div>
										<div className="duration">
											{video.duration.toTime()}
										</div>
									</div>
								</>
							}
						</div>
						<div className="actions">
							<div>
								{status == "completed" && video && !order.confidential &&
									<AddVideoToProfile
										orderId={this.props.order.id}
										video={video}
									/>
								}
							</div>
							<div className="u-actions justify-end">
								{getOrderStatusesArray("new", "pending").includes(status) &&
									<div
										className="u-button red smaller"
										onClick={this.reject}
									>
										<div className="ub-inner-wrapper">
											Reject
										</div>
									</div>
								}
								{status == "completed"
									? <div className="u-button green-invert nohover smaller">
										<div className="ub-inner-wrapper">
											<i className="fas fa-check" />
											<span>
												Video submitted
											</span>
										</div>
									</div>
									: <button className={`u-button default smaller ${!video || loading ? "disabled" : ""}`}>
										<div className="ub-inner-wrapper">
											{loading
												? <>
													<SimplePreloader color="white" />
													<span>
														Submitting...
													</span>
												</>
												: <span>
													Submit
												</span>
											}
										</div>
									</button>
								}
							</div>
						</div>
					</Form>
				}
			</div>
		</>
	}
}