import React from "react"
import { Switch, Route } from "react-router-dom"
import { observer } from "mobx-react"
import { Helmet } from "react-helmet"

import "typings/extensions/Number"
import "typings/extensions/String"
import "typings/Data"
import "typings/React"

import App from "./App"
import { DefaultData } from "typings"
import Main from "stores/Main"
import UserItem from "stores/UserItem"

export interface ProviderProps {
	defaultData?: DefaultData
}

export interface ProviderState {
	
}

@observer
export default
class Provider
extends React.Component<ProviderProps, ProviderState> {
	constructor(props: ProviderProps) {
		super(props)
		const { defaultData } = props

		if (defaultData) {
			Main.setDefaults(defaultData.dictionaries)
			UserItem.setDefault(defaultData.user)
		}
	}

	render() {
		return <>
			<Helmet>
				<link 
					rel="stylesheet" 
					href="/static/shared/font-awesome/style.css"
				/>
				<link
					rel="manifest"
					href="/static/pwa.webmanifest"
				/>
				<link
					rel="icon"
					type="image/png"
					href="/static/images/logos/favicon.png"
				/>
			</Helmet>

			<Switch>
				<Route
					path="/"
					component={App}
				/>
			</Switch>
		</>
	}
}