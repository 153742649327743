import React from "react"
import { noop } from "utils"

import "styles/components/forms/ranges/simple"

export interface SimpleRangeProps {
	name?: string
	label?: React.ReactNode
	min: number
	max: number
	step?: number
	defaultValue?: number
	renderDelimiters?: boolean
	renderValue?: (value: number) => React.ReactNode
}

export interface SimpleRangeState {
	value: number
	dragged: boolean
}

export default
class SimpleRange
extends React.Component<SimpleRangeProps, SimpleRangeState> {
	static defaultProps
		= {
			step: 1,
			renderValue: (value: number) => value
		}

	private dragStartValue
		: number
		= 0

	private accumulatedMovement
		: number
		= 0

	private stepPixelReference
		: number
		= 0

	state
		: SimpleRangeState
		= {
			value: (this.props.defaultValue || 0).bound(this.props.min, this.props.max),
			dragged: false,
		}

	get segmentsCount(): number {
		const { min, max, step } = this.props
		return (max - min) / step!
	}

	get width(): number {
		const { min, max } = this.props
		const { value } = this.state
		return (value - min) / (max - min) * 100
	}

	componentDidMount() {
		document.addEventListener("mousemove", this.moveDrag)
		document.addEventListener("mouseup", this.finishDrag)
	}

	componentWillUnmount() {
		document.removeEventListener("mousemove", this.moveDrag)
		document.removeEventListener("mouseup", this.finishDrag)
	}

	startDrag = (
		event: React.MouseEvent<HTMLDivElement>
	) => {
		const parent = event.currentTarget.parentElement
		if (!parent)
			return

		this.dragStartValue = this.state.value
		this.accumulatedMovement = 0
		this.stepPixelReference = parent.offsetWidth / this.segmentsCount

		document.body.classList.add("usnone")
		this.setState({ dragged: true })
	}

	moveDrag = (
		event: MouseEvent
	) => {
		if (!this.state.dragged)
			return

		const { movementX } = event
		this.accumulatedMovement += movementX
		if (Math.abs(this.accumulatedMovement) >= this.stepPixelReference) {
			const { step } = this.props
			const isPositive = this.accumulatedMovement > 0
			const incrementSteps = Math[isPositive ? "floor" : "ceil"](this.accumulatedMovement / this.stepPixelReference)
			const incrementValue = incrementSteps * step!
			const newValue = this.dragStartValue + incrementValue

			this.setState({ 
				value: newValue.bound(this.props.min, this.props.max)
			})
			this.accumulatedMovement = this.accumulatedMovement - incrementValue * this.stepPixelReference
			this.dragStartValue = newValue
		}
	}

	finishDrag = () => {
		document.body.classList.remove("usnone")
		this.setState({
			dragged: false
		})
	}

	render() {
		const { value } = this.state
		return <>
			<div className="c-simple-range">
				<label className="u-input-label">
					<span className="label-content">
						{this.props.label}
					</span>
					<span className="value">
						{this.props.renderValue?.(value)}
					</span>
				</label>
				<div className="u-range">
					<div className="track">
						{this.props.renderDelimiters &&
							[...Array(this.segmentsCount + 1)].map((_, i) => {
								return <div key={i} className="delimiter" />
							})
						}
					</div>
					<div
						style={{
							left: 0,
							width: `${this.width}%`
						}}
						className="track active"
					/>
					<div
						className={`thumb ${this.state.dragged ? "dragged" : ""}`}
						onMouseDown={this.startDrag}
						style={{ left: `calc(${this.width}% - 8px)` }}
					/>
				</div>
			</div>
			{this.props.name &&
				<input
					type="number"
					name={this.props.name}
					value={value}
					onChange={noop}
					style={{ display: "none" }}
				/>
			}
		</>
	}
}