import React from "react"
import { observer } from "mobx-react"
import { Element as Tooltip } from "@sounds.of.limbo/tooltip"

import "styles/components/global/video-upload-progress"
import Auth from "stores/Auth"
import Progress from "components/UI/Progress"

export interface VideoUploadProgressProps {

}

export interface VideoUploadProgressState {
	isHidden: boolean
}

@observer
export default
class VideoUploadProgress
extends React.Component<VideoUploadProgressProps, VideoUploadProgressState> {
	state
		: VideoUploadProgressState
		= {
			isHidden: true,
		}

	aborted
		: boolean
		= false

	componentDidUpdate() {
		if (Auth.videoProfile?.uploader.isInProgress && this.state.isHidden)
			this.show()
	}

	show = () => {
		this.aborted = false
		this.setState({
			isHidden: false,
		})
	}

	hide = () => {
		this.aborted = false
		this.setState({
			isHidden: true,
		})
	}

	handleAnimationEnd = (
		event: React.AnimationEvent<HTMLDivElement>
	) => {
		if (event.animationName == "fadeOut" && !this.state.isHidden)
			this.hide()
	}

	abort = () => {
		this.aborted = true
		Auth.videoProfile?.uploader.abort?.()
	}

	render() {
		Auth.videoProfile?.uploader.isInProgress // leave this row to make it basically react to this change

		if (!Auth.videoProfile || this.state.isHidden)
			return null

		const { uploader } = Auth.videoProfile
		const { stage, progress, isInProgress } = uploader
		const { name } = uploader.getUploadingVideoMetadata?.() || {}

		const isFinished = !isInProgress

		return <>
			<div
				className={`c-video-upload-progress ${isFinished ? "finished" : ""} ${stage == "failed" ? "failed" : ""}`}
				onAnimationEnd={this.handleAnimationEnd}
			>
				<div className="vup-progress">
					<Progress
						maxProgress={100}
						currentProgress={isFinished ? 100 : progress}
					>
						{currentProgress => {
							return <Tooltip
								element="span"
								elementProps={{
									className: "vup-count-wrapper",
									onClick: this.abort,
								} as React.HTMLAttributes<HTMLSpanElement>}
								content="Abort"
							>
								<span className="vup-count">
									{Math.round(currentProgress)}%
								</span>
								<i className="fas fa-times" />
							</Tooltip>
						}}
					</Progress>
					{isFinished &&
						<div className={`vup-finish ${stage == "failed" ? "fail" : "success"}`}>
							<i className={`fas fa-${stage == "failed" ? "times" : "check"}`} />
						</div>
					}
					
				</div>
				<div className="vup-info">
					<strong>{name}</strong><br />
					{isInProgress
						? stage?.capitalize().split("_").join(" ")
						: stage == "finished"
							? "Finished uploading"
							: this.aborted
								? "Aborted uploading"
								: "Failed to upload"
					}{isInProgress ? "..." : ""}
				</div>
			</div>		
		</>
	}
}