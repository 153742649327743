export type BadFilesListType =
	| "empty"
	| "tooMuch"
	| "badFormat"
	| "totalSizeExceeded"
	| "singleSizeExceeded"

export interface FilesListValidationProps {
	/** Max allowed files count. Default is 1 */
	maxCount?: number
	/** Max files summary size in bytes */
	maxTotalSize?: number
	/** Max single file size */
	maxSingleSize?: number
	/** Allowed MIME-types of files */
	allowedMimeTypes?: string[]
}

export const validateFilesList = (
	files: FileList,
	props?: FilesListValidationProps,
): BadFilesListType | undefined => {
	const {
		maxCount = 1,
		maxTotalSize,
		maxSingleSize,
		allowedMimeTypes = [],
	} = props || {}

	const totalSize = [...files].reduce((acc, file) => {
		return acc + file.size
	}, 0)
	const biggestFileSize = [...files].reduce((acc, file) => {
		return acc >= file.size
			? acc
			: file.size
	}, 0)

	switch (true) {
		case !files?.length:
			return "empty"
		case files.length > maxCount:
			return "tooMuch"
		case allowedMimeTypes.length > 0 && [...files].some(item => {
			return !allowedMimeTypes.includes(item.type)
		}):
			return "badFormat"
		case typeof maxSingleSize != "undefined" && maxSingleSize < biggestFileSize:
			return "singleSizeExceeded"
		case typeof maxTotalSize != "undefined" && maxTotalSize < totalSize:
			return "totalSizeExceeded"
	}
}

export const toBase64 = (
	file: File
): Promise<string> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.addEventListener("load", () => resolve(reader.result as string))
		reader.addEventListener("error", reject)
		reader.readAsDataURL(file)
	})
}