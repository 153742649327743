import React from "react"

import Routing from "stores/Routing"

import { isEqual } from "utils/object"

import Form from "components/Forms/Form"
import FloatSubmit from "./FloatSubmit"
import { observer } from "mobx-react"

export interface BlockingFormProps<T>
extends PropsWithChildren {
	data: T
	className?: string
	__debugName?: string
	onSubmit: (data: T) => Promise<void>
}

export interface BlockingFormState {
	isChanged: boolean,
	isLoading: boolean,
}

@observer
export default
class BlockingForm<T>
extends React.Component<BlockingFormProps<T>, BlockingFormState> {
	state
		: BlockingFormState
		= {
			isChanged: false,
			isLoading: false,
		}

	componentWillUnmount() {
		Routing.transitionsManager.unsetTransitionBlocker("dirtySettings")
	}

	handleSubmit = (
		data: any
	) => {
		if (!this.state.isChanged || this.state.isLoading)
			return

		this.setState({
			isLoading: true,
		})

		this.props.onSubmit(data)
			.then(() => {
				this.setState({
					isLoading: false,
					isChanged: false,
				})

				Routing.transitionsManager.unsetTransitionBlocker("dirtySettings")
			})
			.catch(() => {
				this.setState({
					isLoading: false,
				})
			})
	}

	handleDifference = (
		data: any
	) => {
		console.log(this.props.data, { ...this.props.data, ...data })
		const isChanged = !isEqual(this.props.data, { ...this.props.data, ...data })
		if (isChanged)
			Routing.transitionsManager.setTransitionBlocker("dirtySettings")
		else
			Routing.transitionsManager.unsetTransitionBlocker("dirtySettings")

		this.setState({ isChanged })
	}

	render() {
		const { isChanged, isLoading } = this.state
		const { className = "" } = this.props
		return <>
			<Form
				className={`c-settings-form u-fade-in ${className}`}
				onSubmit={this.handleSubmit}
				onChange={this.handleDifference}
				__debugName={this.props.__debugName}
			>
				{this.props.children}
				<FloatSubmit
					isActive={isChanged}
					isLoading={isLoading}
				>
					Save settings
				</FloatSubmit>
			</Form>
		</>
	}
}