import React from "react"
import { observer } from "mobx-react"

import { sortingOptions } from "consts/filters"

import Search from "stores/Search"
import Meta from "stores/Meta"

import SwitchTabs from "components/UI/Tabs/Switch"
import Form from "components/Forms/Form"
import RadioGroup from "components/Forms/Misc/RadioGroup"
import TwoSidedRange from "components/Forms/Ranges/TwoSided"
import SimpleRange from "components/Forms/Ranges/Simple"
import AnimatedAccordion from "components/UI/AnimatedAccordion"
import { SelectOption } from "typings/Form"
import { SearchUserType } from "typings/Search"

export interface SearchAsideFormProps {

}

export interface SearchAsideFormState {
	expanded: boolean
}

@observer
export default
class SearchAsideForm
extends React.Component<SearchAsideFormProps, SearchAsideFormState> {
	state
		: SearchAsideFormState
		= {
			expanded: false
		}

	handleSubmit = (
		data: any
	) => {
		Search.setFilters(data)
		this.collapse()
	}

	toggleExpanded = () => {
		this.setState({
			expanded: !this.state.expanded,
		})
	}

	expand = () => {
		this.setState({
			expanded: true,
		})
	}

	collapse = () => {
		this.setState({
			expanded: false,
		})
	}

	customTypeSelect = (
		value: SearchUserType
	) => {
		if (!Meta.screenConditions.tabletBook || this.state.expanded)
			return

		Search.setSingleFilter("type", value)
	}

	render() {
		const { expanded } = this.state
		const filters = Search.defaultizedFilters
		return <>
			<AnimatedAccordion
				className="vs-left u-paper"
				minHeight={100}
				expanded={!Meta.screenConditions.tabletBook
					? true
					: expanded
				}
			>
				<Form
					key={Search.filtersVersion}
					onSubmit={this.handleSubmit}
				>
					<div className="switch-n-search">
						<SwitchTabs
							defaultTab={filters.type}
							name="type"
							onSelect={this.customTypeSelect}
							tabs={[
								{
									label: "Talents",
									value: "talents"
								},
								{
									label: "Celebrities",
									value: "celebrities"
								}
							] as SelectOption<SearchUserType>[]}
						/>
						{Meta.screenConditions.tabletBook &&
							<>
								<div
									className={`filters-switch ${expanded ? "expanded" : ""}`}
									onClick={this.toggleExpanded}
								>
									<span className="fs-burger" />
								</div>
							</>
						}
					</div>

					<RadioGroup
						items={Object.keys(sortingOptions)}
						defaultValue={filters.sort}
						label="Sort by"
						name="sort"
					>
						{item => sortingOptions[item]}
					</RadioGroup>

					<TwoSidedRange
						min={1}
						max={250}
						step={1}
						label="Price"
						defaultFrom={filters.priceFrom}
						defaultTo={filters.priceTo}
						name={which => `price${which.capitalize()}`}
					/>

					<SimpleRange
						name="response"
						min={1}
						max={16}
						step={1}
						label="Response time"
						defaultValue={filters.response}
						renderDelimiters
						renderValue={value => {
							return value > 15
								? "more than 15 days"
								: `up to ${value} day${value == 1 ? "" : "s"}`
						}}
					/>

					<div className="actions">
						<button className="u-button default">
							<div className="ub-inner-wrapper">
								<span>
									Apply filters
								</span>
							</div>
						</button>
					</div>
				</Form>
			</AnimatedAccordion>
		</>
	}
}