import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg"

import Logger from "utils/entities/Logger"
import Uploader from "utils/entities/Uploader"

import Main from "stores/Main"

import { Coords2D } from "typings/Geometry"

export type ImageProcessingData = {
	crop: {
		point: Coords2D
		size: {
			width: number
			height: number
		}
	}
	scale: {
		width: number
		height: number
	}
}

export type ImageProcessingType = keyof ImageProcessingData

export type ImageProcessingItem<T extends ImageProcessingType = ImageProcessingType> = {
	type: T
	data: ImageProcessingData[T]
}

export default
class ImageProcessor {
	private logger
		= new Logger("ImageProcessor")

	private uploader
		= new Uploader()

	private processingArgsReferrence
		: {
			[key in ImageProcessingType]: (
				data: ImageProcessingData[key]
			) => string[]
		}
		= {
			crop: (
				data,
			) => {
				const { x, y } = data.point
				const { width, height } = data.size
				return [
					`crop=${width}:${height}:${x}:${y}`
				]
			},
			scale: (
				data,
			) => {
				const { width, height } = data
				return [
					`scale=${width}:${height}`
				]
			}
		}

	processImage = async (
		image: File,
		processings: ImageProcessingItem[]
	): Promise<File> => {
		const { name, size, type } = image

		const clearName = name.split(".").slice(0, -1).join(".")
		const processingName = `processing_${name}`

		this.logger.info(`Picked *${clearName}* *(${size} bytes)* with MIME-type *${type}*. Processing name is *${processingName}`)

		const ffmpeg = createFFmpeg({
			corePath: `${window.location.origin}/static/utils/ffmpeg-core.js`,
			log: Main.isDev,
		})

		if (!ffmpeg.isLoaded())
			await ffmpeg.load()

		ffmpeg.FS("writeFile", processingName, await fetchFile(image))
		const newName = `${clearName}.jpg`

		await ffmpeg.run(
			"-i", processingName,
			"-vf", processings.reduce((acc, item) => {
				return [
					...acc,
					...this.processingArgsReferrence[item.type](item.data as any)
				]
			}, [] as string[]).join(","),
			newName,
		)

		const { buffer } = ffmpeg.FS("readFile", newName)
		const processedImage = new File([buffer], newName, { type: "image/jpeg" })

		this.logger.ok(`Successfully processed image, output size is *${ processedImage.size } bytes*`)

		ffmpeg.exit()

		return processedImage
	}

	processAndUpload = async (
		image: File,
		processings: ImageProcessingItem[]
	): Promise<string> => {
		const processedImage = await this.processImage(image, processings)
		return new Promise((resolve, reject) => {
			this.uploader.addEventListener("success", data => {
				this.uploader.removeEventListener("success")
				resolve(data[0])
			})
			this.uploader.addEventListener("error", data => {
				this.uploader.removeEventListener("error")
				reject(data)
			})
			this.uploader.upload([processedImage])
		})
	}
}