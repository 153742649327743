import React from "react"
import { RouteComponentProps } from "react-router-dom"
import { TooltipElement } from "@sounds.of.limbo/tooltip/dist/element"

import "styles/views/account/views/settings/views/account"

import { noop } from "utils"

import Auth from "stores/Auth"

import { SettingsItemDefaultProps } from "typings/Settings"

import SimpleInput from "components/Forms/Inputs/Simple"
import Datepicker from "components/Forms/Misc/Datepicker"
import Filepicker from "components/Forms/Misc/Filepicker"
import BlockingForm from "../../components/BlockingForm"
import AvatarManagement from "./components/AvatarManagement"
import ImageCropper from "components/Media/ImageCropper"

export interface AccountSettingsProps
extends RouteComponentProps<any>, SettingsItemDefaultProps {

}

export interface AccountSettingsState {
	avatarFile?: string
	pickedImage?: File
}

export default
class AccountSettings
extends React.Component<AccountSettingsProps, AccountSettingsState> {
	private avatarInput
		: HTMLInputElement

	private get defaultData() {
		return {
			avatar: Auth.user?.avatar || "",
			fullname: Auth.user!.fullname,
			birthday: Auth.user!.birthday,
			email: Auth.user!.email,
		}
	}

	state
		: AccountSettingsState
		= {
			avatarFile: Auth.user?.avatar,
			pickedImage: undefined,
		}

	private setAvatar = (
		url?: string
	) => {
		const valueSetter = Object.getOwnPropertyDescriptor(
			window.HTMLInputElement.prototype,
			"value",
		)

		valueSetter?.set?.call(this.avatarInput, url || "")
		const event = new Event("input", { bubbles: true })
		this.avatarInput?.dispatchEvent(event)

		this.setState({
			avatarFile: url
		})
	}

	private pickFile = (
		file: File
	) => {
		this.setState({
			pickedImage: file,
		})
	}

	render() {
		return <>
			{this.state.pickedImage &&
				<ImageCropper
					image={this.state.pickedImage}
					onApply={this.setAvatar}
					onClose={() => this.setState({ pickedImage: undefined, })}
				/>
			}
			<BlockingForm
				data={this.defaultData}
				className="c-account-settings"
				onSubmit={this.props.onSubmit}
			>
				<div className="csf-inner-wrapper two-sided-layout">
					<div className="avatar-wrapper">
						<div className="u-input-label">
							Avatar
						</div>
						<div className="u-square">
							<input
								ref={r => this.avatarInput = r!}
								name="avatar"
								value={this.state.avatarFile || ""}
								onChange={noop}
								style={{
									display: "none",
								}}
							/>
							{this.state.avatarFile
								? <AvatarManagement
									avatar={this.state.avatarFile}
									onAvatarRemove={() => this.setAvatar()}
								/>
								: <Filepicker
									allowedMimeTypes={["image/jpeg", "image/png"]}
									maxCount={1}
									noUpload
									onPick={([file]) => this.pickFile(file)}
									// onUploadEnd={([url]) => this.setAvatar(url)}
								>
									Add photo
								</Filepicker>
							}
						</div>
					</div>
					<div className="rest-form">
						<SimpleInput
							name="fullname"
							label="Full name"
							required
							defaultValue={this.defaultData.fullname}
						/>
						<Datepicker
							name="birthday"
							label="Birthday"
							required
							defaultValue={this.defaultData.birthday}
						/>
						<SimpleInput
							type="email"
							name="email"
							disabled
							label="Email"
							defaultValue={this.defaultData.email}
							endDecoration={Auth.isNotVerified
								? <div
									className="u-button default x-small"
									onClick={Auth.emailVerification.show}
								>
									<div className="ub-inner-wrapper">
										Verify
									</div>
								</div>
								: <TooltipElement
									element="i"
									elementProps={{
										className: "fas fa-check",
										style: {
											color: "#19C98A",
										},
									}}
									content="Email verified"
								>{}</TooltipElement>
							}
						/>
					</div>
				</div>
			</BlockingForm>
		</>
	}
}