import ImageCropper from "components/Media/ImageCropper"
import React from "react"

export interface ImageCropperTestProps {

}

export interface ImageCropperTestState {
	image?: File
	processed?: string
}

export default
class ImageCropperTest
extends React.Component<ImageCropperTestProps, ImageCropperTestState> {
	state
		: ImageCropperTestState
		= {
			processed: undefined,
		}

	handleChange = (
		event: React.FormEvent<HTMLInputElement>
	) => {
		const { files } = event.currentTarget
		if (!files?.length)
			return

		const image = files[0]
		this.setState({ image })

		event.currentTarget.value = ""
	}

	handleApply = (
		image: string
	) => {
		this.setState({
			processed: image,
		})
	}

	render() {
		return <>
			<input
				type="file"
				accept="image/*"
				onChange={this.handleChange}
			/>
			{this.state.image &&
				<ImageCropper
					image={this.state.image}
					onApply={this.handleApply}
					onClose={() => this.setState({ image: undefined, })}
				/>
			}
			{this.state.processed &&
				<img src={this.state.processed} alt="kappa" />
			}
		</>
	}
}