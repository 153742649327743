import React from "react"
import { set } from "utils/object"

export interface FormProps
extends PropsWithChildren {
	__debugName?: string
	className?: string
	onSubmit: (data: any) => void
	onChange?: (data: any) => void
}

export interface FormState {

}

export default
class Form
extends React.Component<FormProps, FormState> {
	static defaultProps
		= {
			__debugName: "Form"
		}

	private getFormData = (
		form: HTMLFormElement
	): object => {
		var data = {}
		;[...form.elements].map((element: HTMLInputElement) => {
			if (!element.name)
				return

			const { name, value } = element
			const subtype = element.getAttribute("data-subtype")

			switch (true) {
				case subtype == "array":
					const separator = element.getAttribute("data-subtype-array-separator") || "|"
					set(data, name, !value ? [] : value.split(separator))
					break
				case element.type == "checkbox":
					set(data, name, element.checked || false)
					break
				case element.type == "radio":
					if (!element.checked)
						return

					set(data, name, value)
					break
				case element.type == "number":
				case subtype == "number":
					set(data, name, Number(value))
					break
				default:
					set(data, name, value)
			}
		})
		console.nfo(`｢ *${this.props.__debugName}* ｣: Form data collected:`, data)

		return data
	}

	handleSubmit = (
		event: React.FormEvent<HTMLFormElement>
	) => {
		event.preventDefault()
		try {
			const data = this.getFormData(event.currentTarget)

			this.props.onSubmit(data)
		} catch (e) {
			console.no(`[${this.props.__debugName}]: *Error during collecting data*: ${e}`)
		}
		return false
	}

	handleChange = (
		event: React.FormEvent<HTMLFormElement>
	) => {
		if (!this.props.onChange)
			return

		try {
			const data = this.getFormData(event.currentTarget)

			this.props.onChange(data)
		} catch (e) {
			console.no(`[${this.props.__debugName}]: *Error during collecting data*: ${e}`)
		}
	}

	render() {
		return <>
			<form
				className={this.props.className}
				onSubmit={this.handleSubmit}
				onChange={this.handleChange}
			>
				{this.props.children}
			</form>
		</>
	}
}