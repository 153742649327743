import Form from "components/Forms/Form"
import React from "react"

import SignUpDefaultStep, { SignUpDefaultStepProps, SignUpDefaultStepState } from "./DefaultStep"

export interface SignUpStepPlaceholderProps
extends SignUpDefaultStepProps {
	stepIndex: number
}

export interface SignUpStepPlaceholderState
extends SignUpDefaultStepState {
	loading: boolean
	fadeOut: boolean
}

export default
class SignUpStepPlaceholder
extends SignUpDefaultStep<SignUpStepPlaceholderProps, SignUpStepPlaceholderState> {
	state
		: SignUpStepPlaceholderState
		= {
			loading: false,
			fadeOut: false,
		}

	render() {
		const { fadeOut } = this.state 
		const { stepIndex } = this.props
		return <>
			<div
				className={`section-content fade-${fadeOut ? "out" : "in"} ${this.props.direction}`}
				onAnimationEnd={this.handleAnimationEnd}
			>
				<h1>
					Step {stepIndex + 1} placeholder
				</h1>
				<Form
					className="su-step"
					onSubmit={this.handleSubmit}
				>
					{this.renderActions()}
				</Form>
			</div>
		</>
	}
}