import React from "react"
import { observer } from "mobx-react"

import "styles/components/global/user/avatars/round"
import UserIcon from "components/UI/Decorations/Static/Icons/User"
import SmoothImage from "components/UI/SmoothImage"

export interface AvatarRoundProps {
	src?: string
}

export interface AvatarRoundState {

}

@observer
export default
class AvatarRound
extends React.Component<AvatarRoundProps, AvatarRoundState> {
	private get hasAvatar(): boolean {
		return !!this.props.src
	}

	get noAvatar(): React.ReactNode {
		return <>
			<div className="ar-extra-wrapper">
				<UserIcon />
			</div>
		</>
	}

	get avatar(): React.ReactNode {
		return <>
			<SmoothImage
				src={this.props.src!}
			/>
		</>
	}

	render() {
		const { hasAvatar } = this
		return <>
			<div className={`c-avatar-round u-square ${hasAvatar ? "" : "no-avatar"}`}>
				<div className="ar-inner-wrapper">
					{hasAvatar
						? this.avatar
						: this.noAvatar
					}
				</div>
			</div>
		</>
	}
}