import React from "react"
import { observer } from "mobx-react"
import { RouteComponentProps } from "react-router-dom"

import "styles/views/search"

import Search from "stores/Search"
import UsersList from "stores/UsersList"

import SimplePreloader from "components/UI/Preloaders/Simple"
import SearchAsideForm from "./components/AsideForm"
import UserListComponent from "./components/List"
import SearchAdditionalFilters from "./components/AdditionalFilters"
import Helmet from "components/Global/Helmet"

export interface SearchViewProps
extends RouteComponentProps<any> {

}

export interface SearchViewState {

}

@observer
export default
class SearchView
extends React.Component<SearchViewProps, SearchViewState> {
	constructor(props: SearchViewProps) {
		super(props)

		Search.restoreStateFromQuery(props.location.search)
	}

	componentDidMount() {
		UsersList.nextPage()
	}

	componentDidUpdate(prevProps: SearchViewProps) {
		if (this.props.location.search != prevProps.location.search) {
			Search.restoreStateFromQuery(this.props.location.search)
		}
	}

	componentWillUnmount() {
		Search.clearFilters()
		UsersList.clearStore()
	}

	render() {
		const { list } = UsersList

		return <>
			<Helmet
				title="Search creators"
				description="Search your favourite among numerous creators"
			/>
			<main className="v-search u-grey-view u-fade-in">
				<div className="vs-content">
					<SearchAsideForm />

					<div className="vs-right">
						<SearchAdditionalFilters
							selected={Search.filters.category}
							onSelect={value => Search.setSingleFilter("category", value)}
						/>

						{!list
							? <SimplePreloader />
							: !list.length
								? <p className="u-dimmed">
									No users found
								</p>
								: <UserListComponent />
						}
					</div>
				</div>
			</main>
		</>
	}
}