import React from "react"
import Preloader from "components/UI/Preloaders/Simple"
import { SignUpData } from "typings/Auth"
import { SignUpStepProps } from "views/SignUp"

export interface SignUpDefaultStepProps
extends SignUpStepProps {

}

export interface SignUpDefaultStepState {
	fadeOut: boolean
	loading: boolean
	error?: string
}

export default
class SignUpDefaultStep<
	P extends SignUpDefaultStepProps = SignUpDefaultStepProps,
	S extends SignUpDefaultStepState = SignUpDefaultStepState
> extends React.Component<P, S> {
	static defaultProps
		= {
			onBeforeSubmit: () => new Promise<void>(resolve => resolve())
		}

	data
		: Partial<SignUpData>
		= {}

	fadeOut = () => {
		this.setState({ fadeOut: true })
	}

	isDisabled = (
		additionalFlag: boolean = false
	) => {
		return this.state.loading
			|| additionalFlag
	}

	handleAnimationEnd = (
		event: React.AnimationEvent<HTMLDivElement>
	) => {
		if (event.target != event.currentTarget)
			return 
		const { animationName } = event
		switch (animationName) {
			case "shiftOutX":
				return this.props.onSubmit(this.data)
			case "shiftOutXReverse":
				return this.props.onBack()
		}
	}

	handleBackClick = () => {
		if (this.state.loading)
			return

		this.props.changeDirection("backward")
		this.fadeOut()
	}

	handleSubmit = (
		data: any,
		additionalDisabledFlag?: boolean
	) => {
		if (this.isDisabled(additionalDisabledFlag))
			return

		this.data = data
		this.setState({ loading: true })

		this.props.onBeforeSubmit?.(data).then(() => {
			this.props.changeDirection("forward")
			this.fadeOut()
		}).catch((error) => {
			this.setState({
				loading: false,
				error: `${error}`
			})
		})
	}

	renderActions = (
		additionalDisabledFlag: boolean = false,
		renderBackButton: boolean = true,
		nextLabel: React.ReactNode = "Next",
	): React.ReactNode => {
		const { loading } = this.state
		const isDisabled = this.isDisabled(additionalDisabledFlag)
		return <>
			<div className="u-actions auto-size">
				{renderBackButton &&
					<div 
						className="u-button outline shrink"
						onClick={this.handleBackClick}
					>
						<div className="ub-inner-wrapper">
							<span>
								Back
							</span>
						</div>
					</div>
				}

				<button
					className={`u-button default ${isDisabled ? "disabled" : ""}`}
					disabled={isDisabled}
				>
					<div className="ub-inner-wrapper">
						<span>
							{loading
								? <Preloader />
								: nextLabel
							}
						</span>
					</div>
				</button>
			</div>
		</>
	}
}