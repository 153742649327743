import SimpleModal from "components/Modals/Simple"
import { Element as Tooltip } from "@sounds.of.limbo/tooltip"
import React from "react"

import "styles/views/error-boundary"

export interface ErrorBoundaryProps
extends PropsWithChildren {
	modal?: boolean
}

export interface ErrorBoundaryState {
	error?: Error
}

export default
class ErrorBoundary
extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
	static NO_MESSAGE
		: string
		= "No error message provided"

	state
		: ErrorBoundaryState
		= {
			error: undefined,
		}

	resetState = () => {
		this.setState({
			error: undefined,
		})
	}

	componentDidCatch(error: Error) {
		this.setState({ error })
	}

	renderError = (
		error: Error
	): React.ReactNode => {
		return <main className={`v-error-boundary ${!this.props.modal ? "u-fs-view u-fade-in" : "modal"}`}>
			<h1>
				An error occured in application
			</h1>
			<div className="eb-error-stack">
				{(error.stack || error.message || ErrorBoundary.NO_MESSAGE).split("\n").map((item, i) => {
					const [start, end] = item.split(/\([^)]/g)
					const formattedEnd = end?.replace(/\)$/g, "") || ""
					return <p key={i}>
						<span className="start">
							{i == 0 ? item : start}
						</span>
						{i > 0 && end && <>
							<span className="line" />
							<Tooltip
								element="span"
								elementProps={{
									className: "end u-dimmed",
								} as React.HTMLAttributes<HTMLSpanElement>}
								content={formattedEnd.split("/").filter(text => {
									return text && text != "." && !text.startsWith("webpack-internal")
								}).map((text, i) => {
									if (!text || text.startsWith("webpack-internal"))
										return null
									return <div key={i}>
										<span className="u-transparent">
											{">".repeat(i)}
										</span>{i != 0 && "> "}{text}
									</div>
								})}
							>
								{formattedEnd.split("/").pop()}/...
							</Tooltip>
						</>}
					</p>
				})}
			</div>
		</main>
	}

	render() {
		const { error } = this.state
		return !error
			? this.props.children
			: this.props.modal
				? <SimpleModal
					closeOnOutsideClick
					onClose={this.resetState}
					classNames={{
						innerWrapper: "c-error-boundary-modal"
					}}
				>
					{close => {
						return <>
							{this.renderError(error)}
							<div className="u-actions">
								<div
									className="u-button default"
									onClick={close}
								>
									<div className="ub-inner-wrapper">
										<span>Try reset state</span>
									</div>
								</div>
							</div>
						</>
					}}
				</SimpleModal>
				: this.renderError(error)
	}
}