import React from "react"
import { observer } from "mobx-react"

import Meta from "stores/Meta"
import OrderBadge, { OrderBadgeProps } from "../../Badge"
import { reaction } from "mobx"

export interface OrderExpirationTimerProps {
	expiresAt: number
	onExpired: () => void
}

export interface OrderExpirationTimerState {

}

@observer
export default
class OrderExpirationTimer
extends React.Component<OrderExpirationTimerProps, OrderExpirationTimerState> {
	isExpirationFired
		: boolean
		= false

	disposeTimeReaction = reaction(
		() => Meta.now,
		() => {
			if (this.isExpirationFired)
				return

			const diff = this.props.expiresAt - Meta.now
			if (diff < 0) {
				this.isExpirationFired = true
				this.props.onExpired()
			}
		},
		{
			fireImmediately: true,
		},
	)

	componentWillUnmount() {
		this.disposeTimeReaction()
	}

	render() {
		const time = (this.props.expiresAt - Meta.now) / 1000 | 0
		const isDangerous = time < 3600 * 24
		const additionalAppearance: NonNullable<OrderBadgeProps["appearance"]> = isDangerous
			? ["negative"]
			: []

		if (time < 0)
			return null
		
		return <>
			<OrderBadge
				label="Expires in"
				appearance={["compact", ...additionalAppearance]}
			>
				{time.toTime(true)}
			</OrderBadge>
		</>
	}
}