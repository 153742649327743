import { action, computed, makeObservable, observable } from "mobx"
import { RatedItem } from "typings/api"

import { SelectOption } from "typings/Form"
import { IFillable, ISettable } from "typings/Stores"

export class DictionaryStore
implements IFillable, ISettable<RatedItem[]>{
	@observable
	private _list?
		: SelectOption<string>[]
		= undefined

	private sortRatedItem = (
		items: RatedItem[]
	): RatedItem[] => {
		return items.sort((a, b) => a.rating - b.rating)
	}

	@action
	private setList = (
		items: SelectOption<string>[]
	) => {
		this._list = items
	}

	constructor(
		private readonly fetch: () => Promise<RatedItem[]>
	) {
		makeObservable(this)
	}

	@computed
	get list() {
		return this._list
	}

	fill = () => {
		if (!this.list)
			this.fetch().then(this.set)
	}

	set = (
		items?: RatedItem[]
	) => {
		if (!items)
			return

		const options: SelectOption<string>[] = this.sortRatedItem(items).map(item => {
			return {
				label: item.name,
				value: item.id,
			}
		})

		this.setList(options)
	}

	getListByIds = (
		ids: string[]
	) => {
		if (!this._list)
			return []

		return this._list.filter(item => {
			return ids.includes(item.value)
		})
	}

	filter = (
		query: string
	) => {
		if (!this._list)
			return []

		const uppercaseQuery = query.toUpperCase()
		return this._list.filter(item => {
			return item.label.toUpperCase().includes(uppercaseQuery)
		})
	}
}