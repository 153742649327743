import React from "react"

import "styles/components/forms/misc/filepicker"

import { 
	BadFilesListType,
	FilesListValidationProps,
	validateFilesList
} from "utils/files"
import DelayAction from "utils/entities/DelayAction"
import Uploader from "utils/entities/Uploader"

import Progress from "components/UI/Progress"

export interface FilepickerProps
extends FilesListValidationProps, PropsWithChildren {
	noUpload?: boolean
	onPick?: (files: File[]) => void
	onUploadEnd?: (urls: string[]) => void
}

export interface FilepickerState {
	isDropOver: boolean
	filesInvalidation?: BadFilesListType
	uploading: boolean
	progress: number
	error?: string
}

export default
class Filepicker
extends React.Component<FilepickerProps, FilepickerState> {
	static defaultProps
		= {}

	private errors = {
		413: "File is too large",
		default: "Unknown error occured"
	}

	private delayAction = new DelayAction()
	private uploader = new Uploader()

	private input
		: HTMLInputElement | null

	private handleProgress = (
		progress: number
	) => {
		this.setState({ progress })
	}

	private handleError = (
		code: number
	) => {
		this.stopUploading()
		this.setState({
			error: this.errors[code] || `${this.errors.default}, code ${code}`,
		})
	}

	private startUploading = () => {
		this.setState({ uploading: true })
	}

	private stopUploading = () => {
		this.setState({ uploading: false })
	}

	private endUploading = (
		urls: string[]
	) => {
		this.stopUploading()
		this.props.onUploadEnd?.(urls)
	}

	state
		: FilepickerState
		= {
			isDropOver: false,
			filesInvalidation: undefined,
			uploading: false,
			progress: 0,
			error: undefined
		}

	componentDidMount() {
		window.addEventListener("drop", this.preventDefaultDrop)

		this.uploader.addEventListener("progress", this.handleProgress)
		this.uploader.addEventListener("success", this.endUploading)
		this.uploader.addEventListener("error", this.handleError)
	}

	componentWillUnmount() {
		window.removeEventListener("drop", this.preventDefaultDrop)
		this.delayAction.clear()

		this.uploader.removeEventListener("progress", this.handleProgress)
		this.uploader.removeEventListener("success", this.endUploading)
		this.uploader.removeEventListener("error", this.handleError)
		this.uploader.cancel()
	}

	get isPickerEnabled(): boolean {
		return !this.state.uploading
	}

	preventDefaultDrop = (
		event: DragEvent
	) => {
		event.preventDefault()
	}

	highlight = () => {
		if (this.isPickerEnabled) {
			this.delayAction.clear()
			this.setState({ 
				isDropOver: true,
				filesInvalidation: undefined,
			})
		}
	}

	unhighlight = () => {
		this.setState({ 
			isDropOver: false,
		})
	}

	setInvalidation = (
		filesInvalidation: BadFilesListType
	) => {
		this.setState({
			filesInvalidation
		})
	}

	clearInvalidation = () => {
		this.setState({
			filesInvalidation: undefined,
			error: undefined,
		})
	}

	handleDrop = (
		event: React.DragEvent<HTMLDivElement>
	) => {
		event.preventDefault()
		if (this.isPickerEnabled) {
			const { dataTransfer } = event
			this.handleFiles(dataTransfer.files)
			this.unhighlight()
		}
	}

	openFilepicker = () => {
		if (this.isPickerEnabled)
			this.input?.click()
	}

	handleFilepickerChange = (
		event: React.FormEvent<HTMLInputElement>
	) => {
		if (this.isPickerEnabled) {
			const { files } = event.currentTarget
			if (files)
				this.handleFiles(files)
		}
	}

	handleFiles = (
		files: FileList
	) => {
		const validation = validateFilesList(files, this.props)
		if (validation) {
			this.setState({
				filesInvalidation: validation
			})
			console.no(`Bad files on drop: *${validation}*`)
		} else {
			this.delayAction.clear()
			this.clearInvalidation()
			console.nfo(`*${files.length} file(s) successfully dropped!`, files)

			this.props.onPick?.([...files])
			if (!this.props.noUpload) {
				this.startUploading()
				this.uploader.upload(files)
			}

			this.input!.value = ""
		}
	}

	handleAnimationEnd = (
		event: React.AnimationEvent<HTMLDivElement>
	) => {
		const { animationName } = event

		switch (animationName) {
			case "shakeX":
				this.delayAction.exec(this.clearInvalidation, 2400)
		}
	}

	render() {
		const { isDropOver, filesInvalidation, uploading, progress, error } = this.state
		const overallError = filesInvalidation || error
		return <>
			<input
				type="file"
				ref={r => this.input = r}
				multiple={this.props.maxCount! > 1}
				style={{ display: "none" }}
				accept={this.props.allowedMimeTypes?.join(",") || undefined}
				onChange={this.handleFilepickerChange}
			/>
			<div className="c-filepicker">
				<div
					className={`fp-drop-area ${
						uploading ? "uploading" : "" } ${
						isDropOver ? "highlighted" : "" } ${
						overallError ? "invalid" : ""
					}`}
					onDragOver={event => event.preventDefault()}
					onDragEnter={this.highlight}
					onDragLeave={this.unhighlight}
					onDrop={this.handleDrop}
					onClick={this.openFilepicker}
					onAnimationEnd={this.handleAnimationEnd}
				>
					{uploading
						? <>
							<Progress
								currentProgress={progress}
								maxProgress={100}
							>
								{progress => `${Math.floor(progress)}%`}
							</Progress>
						</>
						: <>
							<i className={`fas fa-${ overallError ? "ban" : "plus" }`} />
							<div className="fp-label">
								{overallError || this.props.children}
							</div>
						</>
					}
				</div>
			</div>
		</>
	}
}