import React from "react"

import "styles/components/modals/complex/login"

import Auth from "stores/Auth"

import SimpleModal from "../../Simple"
import LoginForm from "./components/LoginForm"
import PasswordResetForm from "./components/PasswordResetForm"

export interface LoginModalFormProps {
	close: () => void
	setOutsideClosableStatus: (
		shouldClose: boolean
	) => void
	onError: (
		error?: string,
	) => void
}

export interface LoginModalProps {

}

export interface LoginModalState {
	forgotPassword: boolean
	isClosable: boolean
	error?: string
}

export default
class LoginModal
extends React.Component<LoginModalProps, LoginModalState> {
	state
		: LoginModalState
		= {
			forgotPassword: false,
			isClosable: true,
			error: undefined,
		}

	showForgotPassword = () => {
		this.setState({
			forgotPassword: true,
		})
	}

	hideForgotPassword = () => {
		this.setState({
			forgotPassword: false,
		})
	}

	setError = (
		error?: string
	) => {
		this.setState({
			error,
		})
	}

	setOutsideClosableStatus = (
		isClosable: boolean
	) => {
		this.setState({
			isClosable,
		})
	}

	render() {
		const { isClosable, error } = this.state
		return <>
			<SimpleModal
				classNames={{
					innerWrapper: `c-login-modal ${error ? "error" : ""}`
				}}
				closeOnOutsideClick={isClosable}
				onClose={Auth.loginModal.hide}
			>
				{close => {
					return this.state.forgotPassword
						? <PasswordResetForm
							close={close}
							setOutsideClosableStatus={this.setOutsideClosableStatus}
							onError={this.setError}
							onBack={this.hideForgotPassword}
						/>
						: <LoginForm
							close={close}
							setOutsideClosableStatus={this.setOutsideClosableStatus}
							onError={this.setError}
							onForgotPassword={this.showForgotPassword}
						/>
				}}
			</SimpleModal>
		</>
	}
}