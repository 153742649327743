import { computed, makeObservable, observable } from "mobx"

class NotificationsStore {
	constructor() {
		makeObservable(this)
	}

	@observable
	private _unreadCount
		: number
		= 0

	@computed
	get unreadCount(): number {
		return this._unreadCount
	}
	
	set unreadCount(
		newCount: number
	) {
		this._unreadCount = newCount
	}

	@computed
	get hasUnread(): boolean {
		return !!this._unreadCount
	}
}

export default new NotificationsStore()