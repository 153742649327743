import React from "react"

import "styles/views/user/views/profile/components/reviews/all"

import SimpleModal from "components/Modals/Simple"
import { ReviewsListData } from "typings/api/Reviews"
import api from "api"
import SimplePreloader from "components/UI/Preloaders/Simple"
import UserReviewItem from "./Item"
import Pagination from "components/UI/Pagination"

export interface UserAllReviewsProps {
	userId: DBID
	onClose: () => void
}

export interface UserAllReviewsState {
	data?: ReviewsListData
	loading: boolean
}

export default
class UserAllReviews
extends React.Component<UserAllReviewsProps, UserAllReviewsState> {
	private pageSize
		: number
		= 3

	private request
		= api.reviews.list()

	state
		: UserAllReviewsState
		= {
			data: undefined,
			loading: false,
		}

	componentDidMount() {
		this.request.request({
			pageNumber: 1,
			pageSize: this.pageSize,
			accountId: this.props.userId
		}).then(response => {
			this.setState({
				data: response.data
			})
		})
	}

	componentWillUnmount() {
		this.request.abort()
	}

	handlePageChange = (
		newPage: number
	) => {
		if (this.state.loading)
			return

		this.setState({
			loading: true,
		})

		this.request.request({
			pageNumber: newPage,
			pageSize: this.pageSize,
			accountId: this.props.userId,
		}).then(response => {
			this.setState({
				data: response.data,
				loading: false
			})
		})
	}

	render() {
		const { loading, data } = this.state
		return <>
			<SimpleModal
				closeOnOutsideClick
				onClose={this.props.onClose}
				classNames={{
					innerWrapper: "c-user-all-reviews c-user-profile-section-modal"
				}}
			>
				{close => {
					return <>
						<div className="upsm-header">
							<h1>
								Reviews {data && `(${data.totalEntries})`}
							</h1>
							<div
								className="u-button"
								onClick={close}
							>
								<div className="ub-inner-wrapper">
									<i className="fas fa-times" />
								</div>
							</div>
						</div>
						<div className={`uar-content ${loading ? "disabled" : ""}`}>
							{!data
								? <div className="u-center">
									<SimplePreloader color="black" />
								</div>
								: data.reviews.map(review => {
									return <UserReviewItem
										key={review.id}
										item={review}
									/>
								})
							}
						</div>
						
						{data?.totalPages! > 1 &&
							<Pagination
								disabled={loading}
								totalPages={data!.totalPages}
								onPageChange={this.handlePageChange}
							/>
						}
					</>
				}}
			</SimpleModal>
		</>
	}
}