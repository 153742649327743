export class Scroller {
	constructor(
		private readonly scrollableElement?: HTMLElement
	) {}

	get scrollTop(): number {
		if (this.scrollableElement)
			return this.scrollableElement.scrollTop

		return window.scrollY
	}

	set scrollTop(
		top: number
	) {
		if (this.scrollableElement)
			this.scrollableElement.scrollTo(this.scrollableElement.scrollLeft, top)
		else
			window.scrollTo(window.scrollX, top)
	}

	get scrollLeft(): number {
		if (this.scrollableElement)
			return this.scrollableElement.scrollLeft

		return window.scrollX
	}

	set scrollLeft(
		left: number
	) {
		if (this.scrollableElement)
			this.scrollableElement.scrollTo(left, this.scrollableElement.scrollTop)
		else
			window.scrollTo(left, window.scrollY)
	}
}