import React from "react"

import "styles/components/ui/decorations/interactive/chip"

export interface ChipProps
extends PropsWithChildren {
	onRemove?: () => void
}

export interface ChipState {

}

export default
class Chip
extends React.Component<ChipProps, ChipState> {
	render() {
		return <>
			<div className="c-chip">
				<span className="content">
					{this.props.children}
				</span>
				{this.props.onRemove &&
					<i
						className="fas fa-times"
						onClick={this.props.onRemove}
					/>
				}
			</div>
		</>
	}
}