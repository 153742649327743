import React from "react"
import { observer } from "mobx-react"
import { Link, matchPath, NavLink, RouteComponentProps, withRouter } from "react-router-dom"

import "styles/components/global/header"

import { parseQuery } from "utils/search"

import Routing from "stores/Routing"
import Auth from "stores/Auth"
import Meta from "stores/Meta"
import Search from "stores/Search"

import Logo from "./components/Logo"
import Notifications from "./components/Notifications"
import Searchbox from "components/Forms/Inputs/Searchbox"
import UserMenu from "./components/UserMenu"
import Burger from "./components/Burger"

export interface HeaderProps
extends RouteComponentProps<any> {

}

export interface HeaderState {

}

@observer
class Header
extends React.Component<HeaderProps, HeaderState> {
	hideForRoutes
		: {
			path: string
			exact?: boolean
		}[]
		= [
			{
				path: Routing.patterns.signup,
				exact: true
			}
		]

	get isHomepage() {
		return !!matchPath(this.props.location.pathname, {
			path: Routing.patterns.homepage,
			exact: true
		})
	}

	get isHidden(): boolean {
		return this.hideForRoutes.some(route => {
			return !!matchPath(this.props.location.pathname, { ...route })
		})
	}

	componentDidUpdate(prevProps: HeaderProps) {
		if (this.props.location.pathname != prevProps.location.pathname)
			this.hideMenu()
	}

	hideMenu = () => {
		this.setState({
			isMenuShown: false
		})
	}

	get router(): React.ReactNode {
		return <>
			<div className="ah-router">
				<NavLink
					to={Routing.compile.homepage()}
					exact
					className="route"
				>
					<span className="caption">
						Home
					</span>
				</NavLink>

				<NavLink
					to={Routing.compile.account.orders.list()}
					className="route"
				>
					<span className="caption">
						Orders
					</span>
					{/* TODO: orders counter here */}
				</NavLink>
			</div>
		</>
	}

	get authActions(): React.ReactNode {
		return <>
			<div className="u-actions auth-actions">
				<div
					className="u-button outline x-small"
					onClick={Auth.loginModal.show}
				>
					<div className="ub-inner-wrapper">
						<span>
							Log in
						</span>
					</div>
				</div>
				<Link
					to={Routing.compile.signup()}
					className="u-button default x-small"
				>
					<div className="ub-inner-wrapper">
						<span>Sign up</span>
					</div>
				</Link>
			</div>
		</>
	}

	render() {
		const { screenConditions } = Meta
		const { isLoggedIn, isAuthChecked } = Auth
		const currentQuery = parseQuery(this.props.location.search).query || ""
		return <>
			<header className={`c-app-header ${this.isHidden ? "hidden" : ""}`}>
				<div className="ah-inner-wrapper">
					<div className="ah-left">
						<Logo noLink={this.isHomepage} />
					</div>
					{!screenConditions.tabletBook &&
						this.router
					}
					<div className="ah-right">
						{/* {!Meta.screenConditions.tabletBook && */}
							<Searchbox
								key={currentQuery}
								placeholder="Search creators"	
								defaultValue={currentQuery}
								onSearch={value =>{ console.log(value);Search.setSingleFilter("query", value)}}
							/>
						{/* } */}
						{isLoggedIn	
							? <>
								<Notifications />
								<UserMenu />
							</>
							: isAuthChecked && !screenConditions.tabletBook
								? this.authActions
								: null
						}
						{screenConditions.tabletBook &&
							<>
								<Burger>
									{isShown => <>
										<div className={`c-header-burger-content ${isShown ? "" : "hidden"}`}>
											{this.router}
											{isAuthChecked && !isLoggedIn &&
												this.authActions
											}
										</div>
									</>}
								</Burger>
							</>
						}
					</div>
				</div>
			</header>
		</>
	}
}

export default withRouter(Header)