import React from "react"
import SimplePreloader from "../Preloaders/Simple"

export interface SimpleButtonProps
extends PropsWithChildren {
	submit?: boolean
	style?: string
	loading?: boolean
	onClick?: () => void
}

export interface SimpleButtonState {

}

export default
class SimpleButton
extends React.Component<SimpleButtonProps, SimpleButtonState> {
	static defaultProps = {
		style: "default"
	}

	render() {
		const { loading, submit, style, onClick } = this.props

		const innerContent = <div className="ub-inner-wrapper">
			{loading
				? <SimplePreloader color="white" />
				: <span>
					{this.props.children}
				</span>
			}
		</div>

		const className = `u-button ${style} ${loading ? "disabled" : ""}`

		const props: React.HTMLAttributes<HTMLElement> = {
			className,
			onClick,
		}

		return submit
			? <button {...props}>
				{innerContent}
			</button>
			: <div {...props}>
				{innerContent}
			</div>
	}
}