import React from "react"

export interface SimplePreloaderProps {
	color?: string
}

export interface SimplePreloaderState {

}

export default
class SimplePreloader
extends React.Component<SimplePreloaderProps, SimplePreloaderState> {
	render() {
		return <>
			<i 
				className="fas fa-pulse fa-spinner"
				style={{
					color: this.props.color
				}}
			/>
		</>
	}
}