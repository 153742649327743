import { noop } from "utils"

export const isEventTargetNode = (
	eventTarget: EventTarget | null
): eventTarget is Node => {
	return eventTarget instanceof Node
}

export const isEventFiredInsideElement = (
	eventTarget: EventTarget | null,
	element: HTMLElement
) => {
	return eventTarget == element
		|| (isEventTargetNode(eventTarget) && element.contains(eventTarget))
}

export const preloadImage = (
	url: string,
	abort: Promise<void> = new Promise(noop)
): Promise<void> => {
	const image = new Image()

	return Promise.race([
		new Promise<void>(resolve => {
			image.crossOrigin = "anonymous"
			image.src = url
			image.addEventListener("load", () => resolve())
		}),
		abort,
	])
}