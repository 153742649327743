import React from "react"

import "styles/views/sign-up/components/steps/step-3"

import Main from "stores/Main"

import SignUpDefaultStep, { SignUpDefaultStepProps, SignUpDefaultStepState } from "./DefaultStep"

import Form from "components/Forms/Form"
import SimpleInput from "components/Forms/Inputs/Simple"
import Datepicker from "components/Forms/Misc/Datepicker"
import ChipsAutocomplete from "components/Forms/Selects/ChipsAutocomplete"
import ReactiveDependant from "components/UI/ReactiveDependant"

import { getYearsDistantDate } from "utils/date"
import { getValidationForKey } from "utils/validation"

export interface SignUpStep3Props
extends SignUpDefaultStepProps {

}

export interface SignUpStep3State
extends SignUpDefaultStepState {

}

export default
class SignUpStep3
extends SignUpDefaultStep<SignUpStep3Props, SignUpStep3State> {
	state
		: SignUpStep3State
		= {
			fadeOut: false,
			loading: false,
		}

	render() {
		const { fadeOut } = this.state
		const { defaultData } = this.props
		const { categories } = Main.dictionaries
		return <>
			<div 
				className={`section-content fade-${fadeOut ? "out" : "in"} ${this.props.direction}`}
				onAnimationEnd={this.handleAnimationEnd}
			>
				<h1>
					Welcome to {Main.projectName}
				</h1>
				<Form
					className="su-step step-3"
					onSubmit={this.handleSubmit}
				>
					<SimpleInput
						name="fullname"
						required
						label="Full name"
						placeholder="John Doe"
						defaultValue={defaultData?.fullname}
						renderInvalidMessage={getValidationForKey("name")}
					/>

					<Datepicker
						name="birthday"
						required
						label="Birthday"
						placeholder="26.05.1986"
						defaultValue={defaultData?.birthday}
						maxDate={getYearsDistantDate(-18)}
						renderInvalidMessage={getValidationForKey("birthday")}
					/>

					{this.props.defaultData?.type == "creator" &&
						<ReactiveDependant
							store={categories}
							watch="list"
						>
							{() => {
								return <ChipsAutocomplete
									name="categories"
									label="Categories"
									required
									defaultItems={defaultData?.categories}
									fetch={async query => categories.filter(query)}
									fetchDefault={async ids => categories.getListByIds(ids)}
								/>
							}}
						</ReactiveDependant>
					}

					{this.renderActions()}
				</Form>
			</div>
		</>
	}
}

