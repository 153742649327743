import Form from "components/Forms/Form"
import React from "react"
import { noop } from "utils"
import SignUpSocialsList from "../SocialsList"

import SignUpDefaultStep, { SignUpDefaultStepProps, SignUpDefaultStepState } from "./DefaultStep"

export interface SignUpStep2Props
extends SignUpDefaultStepProps {

}

export interface SignUpStep2State
extends SignUpDefaultStepState {
	isCeleba: boolean
}

export default
class SignUpStep2
extends SignUpDefaultStep<SignUpStep2Props, SignUpStep2State> {
	state
		: SignUpStep2State
		= {
			fadeOut: false,
			loading: false,
			isCeleba: this.props.defaultData?.famous || false,
		}

	toggleCeleba = () => {
		this.setState({
			isCeleba: !this.state.isCeleba,
		})
	}

	render() {
		const { direction } = this.props
		const { fadeOut, isCeleba } = this.state
		return <>
			<div
				className={`section-content fade-${fadeOut ? "out" : "in"} ${direction}`}
				onAnimationEnd={this.handleAnimationEnd}
			>
				<h1>
					Are you a celebrity?
				</h1>
				<Form
					className="su-step"
					onSubmit={this.handleSubmit}
				>
					<div className="radio">
						<div
							className={`radio-item ${isCeleba ? "selected" : ""}`}
							onClick={this.toggleCeleba}
						>
							<div className={`u-checkbox ${isCeleba ? "checked" : ""}`}>
								<i className="fas fa-check" />
							</div>
							<div className="text-content">
								<p className="type-name">
									I consider myself as a famous person
								</p>
							</div>
							<input
								name="famous"
								type="checkbox"
								checked={this.state.isCeleba}
								style={{
									display: "none",
								}}
								onChange={noop}
							/>
						</div>
					</div>
					{isCeleba &&
						<SignUpSocialsList

						/>
					}
					{this.renderActions(undefined, undefined, isCeleba ? "KYC" : undefined)}
				</Form>
			</div>
		</>
	}
}