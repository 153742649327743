import { observable, makeObservable, computed, action } from "mobx"

export class ModalMarker {
	@observable
	private _isShown
		: boolean
		= this.defaultVisible

	constructor(
		private readonly defaultVisible: boolean = false,
	) {
		makeObservable(this)
	}

	@computed
	get isShown(): boolean {
		return this._isShown
	}

	@action
	show = () => {
		this._isShown = true
	}

	@action
	hide = () => {
		this._isShown = false
	}
}