import React from "react"
import { computed } from "mobx"
import { observer } from "mobx-react"

import SimplePreloader from "./Preloaders/Simple"

export interface ReactiveDependantProps<T, K extends keyof T = keyof T> {
	store: T
	watch: K
	children: (
		store: NonNullable<T[K]>
	) => React.ReactNode
}

export interface ReactiveDependantState {

}

@observer
export default
class ReactiveDependant<T, K extends keyof T>
extends React.Component<ReactiveDependantProps<T, K>, ReactiveDependantState> {
	@computed
	get dependency() {
		return this.props.store[this.props.watch]
	}

	render() {
		return <>
			{!this.dependency
				? <SimplePreloader />
				: this.props.children(this.dependency!)
			}
		</>
	}
}