import React from "react"
import { observer } from "mobx-react"

import "styles/components/modals/simple"
import "styles/components/modals/confirm"

import Confirms from "stores/Confirms"

import SimplePreloader from "components/UI/Preloaders/Simple"
import Textarea from "components/Forms/Inputs/Textarea"

export interface GlobalConfirmsProps {

}

export interface GlobalConfirmsState {

}

@observer
export default
class GlobalConfirms
extends React.Component<GlobalConfirmsProps, GlobalConfirmsState> {
	render() {
		return <>
			{Confirms.list.map(item => {
				const {
					id,
					props,
					error,
					loading,
					confirm,
					cancel,
				} = item

				const {
					title,
					description,
					actionLabels = {
						yes: "Yes",
						no: "No"
					},
					classNames = {
						mainWindow: "",
						outerWrapper: "",
						innerWrapper: "",
						actions: {
							primary: "",
							secondary: "",
						},
					},
					prompt,
					type,
				} = props

				return <div
					key={id}
					className={`c-simple-modal c-confirm-modal u-fade-in ${classNames?.mainWindow || ""}`}
				>
					<div className={`sm-outer-wrapper ${classNames?.outerWrapper || ""}`}>
						<div className={`sm-inner-wrapper ${classNames?.innerWrapper || ""}`}>
							<h1>
								{title}
							</h1>
							<p className="content">
								{description}
							</p>
							{type == "prompt" &&
								<Textarea
									label={prompt?.label}
									placeholder={prompt?.placeholder}
									maxLength={prompt?.maxLength || 120}
									onChange={item.updatePrompt}
								/>
							}
							<p className={`u-invalidation-message independent ${error ? "visible" : ""}`}>
								{error || <>&nbsp;</>}
							</p>
							<div className="cm-actions">
								{!loading &&
									<div
										className={`u-button outline smaller ${classNames.actions?.secondary}`}
										onClick={cancel}
									>
										<div className="ub-inner-wrapper">
											<span>{actionLabels.no}</span>
										</div>
									</div>
								}
								
								<div
									className={`u-button default smaller ${loading ? "disabled" : ""} ${classNames.actions?.primary}`}
									onClick={confirm}
								>
									<div className="ub-inner-wrapper">
										{loading
											? <SimplePreloader color="white" />
											: <span>{actionLabels.yes}</span>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			})}
		</>
	}
}