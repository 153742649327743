import React from "react"
import { observer } from "mobx-react"

import "styles/views/account/views/orders/components/card/components/content/user"

import { OrderData } from "typings/api/Orders"

import api from "api"

import { isDefined } from "utils/type"
import { getValidationForKey } from "utils/validation"

import Meta from "stores/Meta"

import Rate from "components/UI/Rate"
import Form from "components/Forms/Form"
import Video from "components/Media/Video"
import Textarea from "components/Forms/Inputs/Textarea"
import SimplePreloader from "components/UI/Preloaders/Simple"
import OrderDefaultContent from "./Default"

export interface OrderUserContentProps {
	order: OrderData
}

export interface OrderUserContentState {
	loading: boolean
	rating?: number
	ratingInvalid: boolean
	submitted: boolean
}

@observer
export default
class OrderUserContent
extends React.Component<OrderUserContentProps, OrderUserContentState> {
	private updateReq
		= api.orders.updateAsFan()

	state
		: OrderUserContentState
		= {
			loading: false,
			rating: this.props.order.rating,
			ratingInvalid: false,
			submitted: isDefined(this.props.order.review)
		}

	componentWillUnmount() {
		this.updateReq.abort()
	}

	handleRatingChange = (
		rating: number
	) => {
		this.setState({
			rating,
			ratingInvalid: false,
		})
	}

	handleSubmit = (
		data: {
			review: string
		}
	) => {
		if (this.state.loading || this.state.submitted)
			return

		const { rating } = this.state
		if (!rating)
			return this.setState({
				ratingInvalid: true
			})

		const { review } = data
		this.setState({
			loading: true,
		})

		this.updateReq.request({
			orderId: this.props.order.id,
			rating,
			review,
		}).then(() => {
			this.setState({
				loading: false,
				submitted: true,
			})
		})
	}

	render() {
		const { order } = this.props

		const { rating, loading, submitted, ratingInvalid } = this.state
		const { content } = order
			
		return <>
			<div className="c-order-content c-order-user-content">
				<OrderDefaultContent
					order={order}
				/>
				{content &&
					<Form
						className="video-review"
						onSubmit={this.handleSubmit}
					>
						<div className="data">
							<div className="video-wrapper">
								<div className="video-aspect-ratio-wrapper">
									<Video
										video={content}
									/>
								</div>
							</div>
							<div className="form-wrapper">
								<div className="rate-n-like">
									<Rate
										label="Rate this:"
										isInvalid={ratingInvalid}
										defaultRate={rating}
										onRateChange={this.handleRatingChange}
									/>
								</div>
								<div className="textarea">
									<Textarea
										name="review"
										defaultValue={order.review}
										disabled={loading || submitted}
										label="Review"
										maxLength={400}
										disableAutoResize={!Meta.screenConditions.tabletBook}
										renderInvalidMessage={getValidationForKey("default")}
									/>
								</div>
							</div>
						</div>
						<div className="actions">
							<div className="share">
								{/* <span
									className="u-link"
									onClick={() => navigator.share?.()}
								>
									Share // TODO Share API
								</span> */}
							</div>
							<div className="buttons">
								<a
									className="u-button outline bright"
									href={content.sources.find(item => item.resolution == "native")?.url}
									download={content.name}
									target="_BLANK"
									rel="noopener"
								>
									<div className="ub-inner-wrapper">
										<i className="fas fa-download" />
										<strong>
											Download
										</strong>
									</div>
								</a>
								{submitted
									? <div className="u-button green-invert nohover">
										<div className="ub-inner-wrapper">
											<i className="fas fa-check" />
											<span>
												Review submitted
											</span>
										</div>
									</div>
									: <button className="u-button default">
										<div className="ub-inner-wrapper">
											{loading
												? <>
													<SimplePreloader color="white" />
													<span>
														Submitting...
													</span>
												</>
												: <span>
													Submit review
												</span>
											}
										</div>
									</button>
								}
							</div>
						</div>
					</Form>
				}
			</div>
		</>
	}
}