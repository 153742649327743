import React from "react"
import { v4 as uuid } from "uuid"

import { FormElementInputLikeProps, InvalidationKey } from "typings/Form"

import { checkValidation } from "utils/forms"

export interface TextareaProps
extends FormElementInputLikeProps<string> {
	minLength?: number
	maxLength?: number
	disableAutoResize?: boolean
	onChange?: (
		value: string
	) => void
}

export interface TextareaState {
	value: string
	invalid?: InvalidationKey
}

export default
class Textarea
extends React.Component<TextareaProps, TextareaState> {
	static defaultProps
		: Partial<TextareaProps>
		= {
			renderInvalidMessage: key => key
		}

	private minHeight
		: number
		= 110

	private id
		: string
		= this.props.id || uuid()

	private lastInvalidationKey
		: InvalidationKey
		= "valueMissing"

	state
		: TextareaState
		= {
			value: this.props.defaultValue || "",
			invalid: undefined
		}

	autoresize = (
		textarea: HTMLTextAreaElement,
	) => {
		if (this.props.disableAutoResize)
			return
			
		textarea.style.minHeight = `0px`
		textarea.style.height = `0px`

		const newHeight = textarea.scrollHeight < this.minHeight
			? this.minHeight
			: textarea.scrollHeight

		textarea.style.minHeight = `${newHeight}px`
	}

	handleChange = (
		event: React.FormEvent<HTMLTextAreaElement>
	) => {
		if (this.props.disabled)
			return

		const textarea = event.currentTarget
		this.autoresize(textarea)
		var { value } = textarea
		this.props.onChange?.(value)

		this.setState({
			value,
			invalid: undefined
		})
	}

	handleInvalid = (
		event: React.FormEvent<HTMLTextAreaElement>
	) => {
		event.preventDefault()

		const invalidationKey = checkValidation(event.currentTarget)
		this.lastInvalidationKey = invalidationKey || this.lastInvalidationKey
		this.setState({
			invalid: invalidationKey
		})
	}

	render() {
		const { value, invalid } = this.state
		const { required, maxLength } = this.props
		const isInvalid = !!invalid
		const isFilled = !!value
		return <>
			<div className={`c-textarea ${isInvalid ? "invalid" : ""}`}>
				{this.props.label &&
					<label
						htmlFor={this.id}
						className="u-input-label"
					>
						<span className="label-content">
							{this.props.label}
						</span>
						{this.props.maxLength &&
							<span>
								{value.length}/{maxLength}
							</span>
						}
					</label>
				}

				<div className="textarea-wrapper">
					<textarea
						id={this.id}
						className={`u-input ${ isFilled ? "filled" : "" }`}
						value={value}
						placeholder={this.props.placeholder}
						name={this.props.name}
						required={required}
						minLength={this.props.minLength}
						maxLength={maxLength}
						onChange={this.handleChange}
						onInvalid={this.handleInvalid}
						disabled={this.props.disabled}
					/>
				</div>

				<div 
					className={`u-invalidation-message ${
						isInvalid ? "" : "hidden"
					}`}
				>
					{this.props.renderInvalidMessage?.(this.lastInvalidationKey)}
				</div>
			</div>
		</>
	}
}