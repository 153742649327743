import React from "react"
import { observer } from "mobx-react"

import "styles/views/user/views/order/components/occasion"

import Main from "stores/Main"

import { noop } from "utils"

import SelectableChip from "components/UI/Decorations/Interactive/SelectableChip"
import SimplePreloader from "components/UI/Preloaders/Simple"

export interface OrderOccasionProps {
	name?: string
}

export interface OrderOccasionState {
	selected?: string
	invalid: boolean
}

@observer
export default
class OrderOccasion
extends React.Component<OrderOccasionProps, OrderOccasionState> {
	state
		: OrderOccasionState
		= {
			selected: undefined,
			invalid: false,
		}

	selectItem = (
		item: string
	) => {
		this.setState({
			selected: item,
			invalid: false,
		})
	}

	handleInvalid = (
		event: React.FormEvent<HTMLInputElement>
	) => {
		event.preventDefault()
		
		this.setState({
			invalid: true,
		})
	}

	render() {
		const { occasions } = Main.dictionaries
		const { selected, invalid } = this.state
		return <>
			<div className={`c-order-occasion ${invalid ? "invalid" : ""}`}>
				<label className="u-input-label">
					What's the occasion?
				</label>

				{this.props.name &&
					<input
						name={this.props.name}
						value={selected || ""}
						required
						onInvalid={this.handleInvalid}
						onChange={noop}
						style={{ display: "none" }}
					/>
				}
				<div className="items">
					{!occasions.list
						? <SimplePreloader />
						: occasions.list.map(item => {
							const isSelected = item.value == selected

							return <SelectableChip
								key={item.value}
								isSelected={isSelected}
								onClick={() => this.selectItem(item.value)}
							>
								{item.label}
							</SelectableChip>
						})
					}
				</div>
			</div>
		</>
	}
}