import React from "react"
import { observer } from "mobx-react"

import "styles/views/private-view"

import Auth from "stores/Auth"
import SimplePreloader from "components/UI/Preloaders/Simple"
import { Link } from "react-router-dom"
import Routing from "stores/Routing"
import Helmet from "components/Global/Helmet"

export interface PrivateRouteProps
extends PropsWithChildren {

}

export interface PrivateRouteState {

}

@observer
export default
class PrivateRoute
extends React.Component<PrivateRouteProps, PrivateRouteState> {
	render() {
		const { isAuthChecked, isLoggedIn } = Auth
		
		const title = "Access denied"
		const description = "You have to be logged in to see this page."

		return <>
			{!isAuthChecked
				? <>
					<Helmet
						title="Loading..."
					/>
					<div className="u-fs-view">
						<SimplePreloader />
					</div>
				</>
				: isLoggedIn
					? this.props.children
					: <>
						<Helmet
							title={title}
							description={description}
						/>
						<main
							key="yomamma"
							className="v-private-view u-fade-in u-fs-view "
						>
							<h1>
								{title}
							</h1>
							<p>
								{description}
							</p>
							<div className="u-actions">
								<Link
									to={Routing.compile.signup()}
									className="u-button outline"
								>
									<div className="ub-inner-wrapper">
										Sign up
									</div>
								</Link>
								<div
									className="u-button default"
									onClick={Auth.loginModal.show}
								>
									<div className="ub-inner-wrapper">
										Log in
									</div>
								</div>
							</div>
						</main>
					</>
			}
		</>
	}
}