import SimplePreloader from "components/UI/Preloaders/Simple"
import React from "react"

export interface FloatSubmitProps
extends PropsWithChildren {
	isActive: boolean
	isLoading: boolean
}

export interface FloatSubmitState {
	isFloat: boolean
}

export default
class FloatSubmit
extends React.PureComponent<FloatSubmitProps, FloatSubmitState> {
	private wrapper
		: HTMLDivElement | null

	private checkPosition = () => {
		if (!this.wrapper)
			return

		const box = this.wrapper.getBoundingClientRect()

		this.setState({
			isFloat: box.bottom == document.body.clientHeight
		})
	}

	state
		: FloatSubmitState
		= {
			isFloat: false
		}

	componentDidMount() {
		document.addEventListener("scroll", this.handleScroll)
		this.checkPosition()
	}

	componentDidUpdate(prevProps: FloatSubmitProps) {
		if (this.props.isActive && !prevProps.isActive)
			this.checkPosition()
	}

	componentWillUnmount() {
		document.removeEventListener("scroll", this.handleScroll)
	}

	handleScroll = (
		_event: Event
	) => {
		this.checkPosition()
	}

	render() {
		const { isActive, isLoading } = this.props
		const { isFloat } = this.state
		return <>
			<div
				ref={r => this.wrapper = r}
				className={`c-settings-form-actions ${isActive ? "active" : ""} ${isFloat ? "float" : ""}`}
			>
				<div className="sfa-inner-wrapper">
					<button
						className="u-button default"
						disabled={!isActive || isLoading}
					>
						<div className="ub-inner-wrapper">
							{isLoading
								? <>
									<SimplePreloader color="white" />
									<span>
										Saving...
									</span>
								</>
								: <span>
									Save settings
								</span>
							}
						</div>
					</button>
				</div>
			</div>
		</>
	}
}