import React from "react"
import { observer } from "mobx-react"
import { RouteComponentProps } from "react-router"

import "styles/views/account/views/home"

export interface AccountHomeProps
extends RouteComponentProps<any> {

}

export interface AccountHomeState {

}

@observer
export default
class AccountHome
extends React.Component<AccountHomeProps, AccountHomeState> {
	render() {
		return <>
			<main className="v-account-home">
				<h1>Account home</h1>
			</main>
		</>
	}
}