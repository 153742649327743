import React from "react"
import { Link } from "react-router-dom"

import "styles/views/homepage/components/sections/actions"

import { objectToQueryString } from "utils/search"

import Routing from "stores/Routing"

export interface HomepageActionsSectionProps {

}

export interface HomepageActionsSectionState {

}

export default
class HomepageActionsSection
extends React.Component<HomepageActionsSectionProps, HomepageActionsSectionState> {
	data
		: {
			text: string
			caption: string
			type: "user" | "creator"
		}[]
		= [
			{
				text: "Earn cash for my videos",
				caption: "I'm a Video creator",
				type: "creator",
			},
			{
				text: "Get some fun or congratulations from star",
				caption: "I'm a Fan",
				type: "user",
			}
		]
	
	render() {
		return <>
			<section className="c-homepage-actions">
				<div className="ha-content ho-wrapper">
					{this.data.map(item => {
						const { type, text, caption } = item
						return <Link
							key={type}
							className={type}
							to={Routing.compile.signup() + objectToQueryString({ defaultType: type })}
						>
							<span className="text">
								{text}
							</span>
							<span className="caption">
								<span>
									{caption}
								</span>
								<i className="fas fa-arrow-right" />
							</span>
						</Link>
					})}
				</div>
			</section>
		</>
	}
}