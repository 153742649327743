import React from "react"
import { Element as Tooltip } from "@sounds.of.limbo/tooltip"

import "styles/components/ui/rate"

export interface RateProps {
	label?: React.ReactNode
	isInvalid?: boolean
	/**
	 * Default rate number from 1 to 5
	 */
	defaultRate?: number
	onRateChange: (
		/**
		 * Number from 1 to 5
		 */
		value: number
	) => void
}

export interface RateState {
	currentRate: number
	ratePlaced: boolean
}

export default
class Rate
extends React.Component<RateProps, RateState> {
	private get isRateable(): boolean {
		return !this.state.ratePlaced
	}

	private comments
		: string[]
		= [
			"Awful",
			"Bad",
			"Suitable",
			"Good",
			"Awesome!",
		]

	state
		: RateState
		= {
			currentRate: this.props.defaultRate || 0,
			ratePlaced: typeof this.props.defaultRate != "undefined"
		}

	handleRateMouseEnter = (
		value: number
	) => {
		if (!this.isRateable)
			return

		this.setState({
			currentRate: value,
		})
	}

	handleRateMouseLeave = () => {
		if (!this.isRateable)
			return

		this.setState({
			currentRate: 0
		})
	}

	handleRateClick = () => {
		if (!this.isRateable || this.state.currentRate === 0)
			return

		this.props.onRateChange(this.state.currentRate)
		this.setState({
			ratePlaced: true,
		})
	}

	render() {
		const { currentRate, ratePlaced } = this.state
		return <>
			<div className={`c-rate ${this.props.isInvalid ? "invalid" : ""}`}>
				{this.props.label &&
					<label className="u-input-label">
						{this.props.label}
					</label>
				}
				<div
					className={`stars ${ratePlaced ? "rate-placed" : ""}`}
					onMouseLeave={this.handleRateMouseLeave}
				>
					{[...Array(5)].map((_, i) => {
						const value = i + 1
						const isFilled = value <= currentRate
						return <Tooltip
							key={value}
							element="i"
							elementProps={{
								className: `fas fa-star ${isFilled ? "filled" : ""}`,
								onMouseEnter: () => this.handleRateMouseEnter(value),
								onClick: this.handleRateClick
							}}
							content={this.comments[i]}
						>{null}</Tooltip>
					})}
				</div>
			</div>
		</>
	}
}