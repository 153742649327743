import { action, computed, makeObservable, observable } from "mobx"
import { IFillable, ISettable } from "typings/Stores"

export class PlainReactiveStore<T>
implements IFillable, ISettable<T>{
	constructor(
		private readonly fetch: () => Promise<T>,
	) {
		makeObservable(this)
	}

	@observable
	data?
		: T
		= undefined

	@computed
	get isReady(): boolean {
		return !!this.data
	}

	fill = () => {
		if (!this.isReady)
			this.fetch().then(this.set)
	}

	@action
	set = (
		data: T
	) => {
		this.data = data
	}
}