import React from "react"
import { observer } from "mobx-react"
import { RouteComponentProps, Switch, Route } from "react-router"
import { Display } from "@sounds.of.limbo/tooltip"

import "@sounds.of.limbo/tooltip/styles/sass/tooltip.sass"
import "styles/animations"
import "styles/fonts"
import "styles/main"
import "styles/uni"

import Routing from "stores/Routing"
import Auth from "stores/Auth"

import Header from "components/Global/Header"
import LoginModal from "components/Modals/complex/Login"

import Playground from "views/Playground"
import NotFound from "views/NotFound"
import ErrorBoundary from "views/ErrorBoundary"
import SignUpView from "views/SignUp"
import UserRoute from "views/User"
import SearchView from "views/Search"
import AccountView from "views/Account"
import RouteTransitionConfirms from "components/Global/RouteTransitionConfirms"
import PrivateRoute from "views/PrivateView"
import Main from "stores/Main"
import Footer from "components/Global/Footer"
import GlobalConfirms from "components/Modals/complex/GlobalConfirms"
import NotVerified from "components/Global/NotVerified"
import Homepage from "views/Homepage"
import NetworkStatus from "components/Global/NetworkStatus"
import VideoUploadProgress from "components/Global/VideoUploadProgress"
import LegalRoute from "views/Legal"
import Captcha from "stores/Captcha"
import VerifyEmailModal from "components/Modals/complex/VerifyEmail"

export interface AppProps
extends RouteComponentProps<any> {

}

export interface AppState {

}

@observer
export default
class App
extends React.Component<AppProps, AppState> {
	constructor(props: AppProps) {
		super(props)
		Display.fadeOutAnimationName = "fadeOut"
	}

	componentDidMount() {
		Auth.identity()
		Main.fillDictionaries()
		Captcha.load()
	}

	componentDidUpdate(prevProps: AppProps) {
		if (this.props.location.pathname != prevProps.location.pathname) {
			document.body.scrollTop = 0
			document.documentElement.scrollTop = 0
			window.scrollTo({ top: 0 })
		}
	}

	renderComponentWithErrorBoundary = (
		props: RouteComponentProps,
		ComponentClass: React.ComponentClass<RouteComponentProps, any>,
		params: {
			key?: string
			isPrivate?: boolean
		} = {}
	): React.ReactNode => {
		const {
			key = props.location.pathname,
			isPrivate = false,
		} = params
		const Wrapper = isPrivate
			? PrivateRoute
			: React.Fragment

		return <ErrorBoundary key={key}>
			<Wrapper>
				<ComponentClass {...props} />
			</Wrapper>
		</ErrorBoundary>
	}

	renderWithModalErrorBoundary = (
		content: React.ReactNode
	): React.ReactNode => {
		return <ErrorBoundary modal>
			{content}
		</ErrorBoundary>
	}

	render() {
		return <>
			{this.renderWithModalErrorBoundary(<Header />)}

			<Switch>
				<Route
					path={Routing.compile.homepage()}
					exact
					render={props => this.renderComponentWithErrorBoundary(props, Homepage)}
				/>
				
				<Route 
					path={Routing.patterns.__playground}
					render={props => this.renderComponentWithErrorBoundary(props, Playground)}
				/>

				{/* ---- Scopes ---- */}

				<Route
					path={Routing.scopes.user}
					render={props => this.renderComponentWithErrorBoundary(props, UserRoute, {
						key: "user"
					})}
				/>
				<Route
					path={Routing.scopes.account}
					render={props => this.renderComponentWithErrorBoundary(props, AccountView, {
						key: "me",
						isPrivate: true,
					})}
				/>
				<Route
					path={Routing.scopes.legal}
					render={props => this.renderComponentWithErrorBoundary(props, LegalRoute, {
						key: "legal",
					})}
				/>
				{/* TODO FAQ scope */}
				
				{/* ---- Scopes end ---- */}

				<Route
					exact
					path={Routing.patterns.signup}
					render={props => this.renderComponentWithErrorBoundary(props, SignUpView)}
				/>
				<Route
					exact
					path={Routing.patterns.search}
					render={props => this.renderComponentWithErrorBoundary(props, SearchView)}
				/>

				{/* ---- Not found ---- */}
				<Route
					render={props => this.renderComponentWithErrorBoundary(props, NotFound)}
				/>
			</Switch>

			{this.renderWithModalErrorBoundary(<Footer />)}
				
			{Auth.loginModal.isShown &&
				this.renderWithModalErrorBoundary(<LoginModal />)
			}

			{Auth.emailVerification.isShown && Auth.isLoggedIn &&
				this.renderWithModalErrorBoundary(<VerifyEmailModal />)
			}

			{Auth.isLoggedIn && Auth.isNotVerified &&
				this.renderWithModalErrorBoundary(<NotVerified />)
			}

			{this.renderWithModalErrorBoundary(<GlobalConfirms />)}
			{this.renderWithModalErrorBoundary(<RouteTransitionConfirms />)}
			{this.renderWithModalErrorBoundary(<Display />)}

			{Auth.isLoggedIn && Auth.isCreator &&
				this.renderWithModalErrorBoundary(<VideoUploadProgress />)
			}

			<NetworkStatus />
		</>
	}
}