import { action, computed, makeObservable, observable } from "mobx"
import { v4 as uuid } from "uuid"

type ScreenSize =
	| "desktopSmall"
	| "tabletAlbum"
	| "tabletBook"
	| "mobileLarge"
	| "mobileSmall"

type NetworkStatus =
	| "online"
	| "offline"

class MetaStore {
	private screenSizesMaxWidths
		: {
			[key in ScreenSize]: number
		}
		= {
			desktopSmall: 1300,
			tabletAlbum: 1100,
			tabletBook: 800,
			mobileLarge: 600,
			mobileSmall: 320,
		}

	@observable
	private _now
		: number
		= Date.now()

	@action
	private handleResize = () => {
		const screenSizes = Object.keys(this.screenSizesMaxWidths) as ScreenSize[]
		screenSizes.forEach(screen => {
			const maxWidth = this.screenSizesMaxWidths[screen]
			this.screenConditions[screen] = window.innerWidth <= maxWidth
		})
	}

	@action
	private incrementNow = () => {
		this._now += 1000
	}

	@observable
	private networkStatus
		: NetworkStatus
		= "online"

	constructor() {
		makeObservable(this)

		if (typeof window != "undefined") {
			window.addEventListener("resize", this.handleResize)
			this.handleResize()

			window.setInterval(this.incrementNow, 1000)
		}
	}

	@observable
	tabId
		: string
		= uuid()

	@observable
	screenConditions
		: {
			[key in ScreenSize]: boolean
		}
		= {
			desktopSmall: false,
			tabletAlbum: false,
			tabletBook: false,
			mobileLarge: false,
			mobileSmall: false,
		}

	@computed
	get isOffline(): boolean {
		return this.networkStatus == "offline"
	}

	@computed
	get now(): number {
		return this._now
	}

	@action
	setNetworkStatus = (
		newStatus: NetworkStatus
	) => {
		this.networkStatus = newStatus
	}
}

export default new MetaStore()