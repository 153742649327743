import React from "react"
import { observer } from "mobx-react"
import { computed, makeObservable } from "mobx"

import Auth from "stores/Auth"

import SimplePreloader from "components/UI/Preloaders/Simple"
import { isDefined } from "utils/type"
import { Video } from "typings/Media"

export interface AddVideoToProfileProps {
	orderId: DBID
	video: Video
}

export interface AddVideoToProfileState {

}

@observer
export default
class AddVideoToProfile
extends React.Component<AddVideoToProfileProps, AddVideoToProfileState> {
	constructor(props: AddVideoToProfileProps) {
		super(props)

		makeObservable(this)
	}

	@computed
	get isAdded(): boolean {
		return !!Auth.videoProfile?.list.find(item => item.id == this.props.orderId)
	}

	upload = () => {
		if (!Auth.videoProfile)
			return

		const { list } = Auth.videoProfile
		const lastItem = list.slice(-1)[0]
		const newIndex = isDefined(lastItem)
			? lastItem.order + 1
			: 0

		Auth.videoProfile.update([
			...list,
			{
				...this.props.video,
				id: `${this.props.orderId}`,
				order: newIndex,
			}
		])
	}

	render() {
		return <>
			{!Auth.videoProfile?.isReady
				? <SimplePreloader color="#2E8ADF" />
				: this.isAdded
					? <div className="u-button default-invert smaller nohover">
						<div className="ub-inner-wrapper">
							<i className="fas fa-plus" />
							<span>
								Video added to profile
							</span>
						</div>
					</div>
					: <div
						className="u-button outline bright smaller"
						onClick={this.upload}
					>
						<div className="ub-inner-wrapper">
							{Auth.videoProfile?.isUpdating
								? <SimplePreloader color="#2E8ADF" />
								: <>
									<i className="fas fa-plus" />
									<span>
										Add this video to my profile
									</span>
								</>
							}
						</div>
					</div>
			}
			
		</>
	}
}