import { InvalidationKey } from "typings/Form"

export const checkValidation = (
	input: HTMLInputElement | HTMLTextAreaElement,
): InvalidationKey | undefined => {
	for (var key in input.validity) {
		if (input.validity[key])
			break
	}

	return key! == "valid"
		? undefined
		: key! as InvalidationKey
}