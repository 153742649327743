import React from "react"

import "styles/components/media/video-grid"
import { Video } from "typings/Media"
import VideoComponent from "./Video"

export interface VideoGridProps {
	videos: Video[]
	strictHeight?: number
}

export interface VideoGridState {
	rows?: {
		height: number
		videos: VideoGridProps["videos"]
	}[]
}

export default
class VideoGrid
extends React.Component<VideoGridProps, VideoGridState> {
	private gridWrapper
		: HTMLElement

	private minRowHeight
		: number
		= this.props.strictHeight || 240

	private maxRowHeight
		: number
		= this.props.strictHeight || 480

	private gap
		: number
		= 15

	state
		: VideoGridState
		= {
			rows: undefined,
		}

	componentDidMount() {
		this.calculate()
		window.addEventListener("resize", this.calculate)
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.calculate)
	}

	getRelativeWidth = (
		video: Video,
		rowHeight: number,
	): number => {
		const { width, height } = video
		const aspectRatio = width / height
		return rowHeight * aspectRatio
	}

	getRelativeHeight = (
		video: Video,
		forWidth: number
	): number => {
		const { width, height } = video
		const aspectRatio = width / height
		return forWidth / aspectRatio
	}

	calculate = () => {
		const { videos } = this.props
		if (this.props.strictHeight) {
			this.setState({
				rows: [{
					videos,
					height: this.props.strictHeight,
				}]
			})
			return
		}

		const { offsetWidth: wrapperWidth } = this.gridWrapper
		const rows: NonNullable<VideoGridState["rows"]> = []

		videos.forEach((video, i) => {
			if (!i) {
				rows.push({
					videos: [video],
					height: this.minRowHeight,
				})
				return
			}

			const currentRow = rows[rows.length - 1]
			const currentWidth = currentRow.videos.reduce((acc, item) => {
				return acc + this.getRelativeWidth(item, currentRow.height) + this.gap
			}, 0)
			const relativeWidth = this.getRelativeWidth(video, currentRow.height)

			if (currentWidth + relativeWidth > wrapperWidth)
				rows.push({
					videos: [video],
					height: this.minRowHeight,
				})
			else
				currentRow.videos.push(video)
		})

		rows.forEach(row => {
			const relativeWidths = row.videos.map(video => {
				// TODO fix double calculation
				return this.getRelativeWidth(video, row.height)
			})
			const relativeWidthsSum = relativeWidths.reduce((acc, item) => acc + item, 0)
			const relativeWidthsPercentage = relativeWidths.map(width => width / relativeWidthsSum)
			const gaps = (row.videos.length - 1) * this.gap
			const totalCurrentWidth = relativeWidthsSum + gaps
			const emptySpace = wrapperWidth - totalCurrentWidth

			const calculatedHeight = this.getRelativeHeight(row.videos[0], relativeWidths[0] + (emptySpace * relativeWidthsPercentage[0]))
			row.height = calculatedHeight > this.maxRowHeight
				? this.maxRowHeight
				: calculatedHeight
		})

		this.setState({
			rows,
		})
	}

	render() {
		return <>
			<section
				ref={r => this.gridWrapper = r!}
				className="c-video-grid"
			>
				{this.state.rows?.map((row, i) => {
					const { videos, height } = row

					return <div
						key={i}
						className="cvg-row"
					>
						{videos.map((video, j) => {
							const width = this.getRelativeWidth(video, height)
							return <div
								key={`${i}+${j}`}
								className="temp-item"
								style={{
									width,
									height,
								}}
							>
								<VideoComponent
									video={video}
								/>
							</div>
						})}
					</div>
				})}
			</section>
		</>
	}
}