import React from "react"
import { observer } from "mobx-react"
import { Link, Redirect, RouteComponentProps } from "react-router-dom"

import "styles/views/sign-up"

import api from "api"

import Routing from "stores/Routing"
import Auth from "stores/Auth"

import { SignUpData, UserType } from "typings/Auth"

import { sha256 } from "utils/crypto"

import Logo from "components/Global/Header/components/Logo"
import Stepper from "./components/Stepper"
import SignUpStep1 from "./components/steps/Step1"
import SignUpStep2 from "./components/steps/Step2"
import SignUpStep3 from "./components/steps/Step3"
import SignUpStep4 from "./components/steps/Step4"
import SignUpStepPlaceholder from "./components/steps/StepPlaceholder"
import { parseQuery } from "utils/search"
import Helmet from "components/Global/Helmet"
import Main from "stores/Main"

export type SignUpStepAnimationDirection =
	| "forward"
	| "backward"

export interface SignUpStepProps {
	direction: SignUpStepAnimationDirection
	changeDirection: (newDirection: SignUpStepAnimationDirection) => void

	defaultData?: Partial<SignUpData>
	onBack: () => void
	onSubmit: (data: Partial<SignUpData>) => void
	onBeforeSubmit?: (data: any) => Promise<any>
}

export interface SignUpProps
extends RouteComponentProps<any> {

}

export interface SignUpState {
	currentStep: number
	direction: SignUpStepAnimationDirection
}

export type SignUpQuery = {
	defaultType?: UserType
	redirect?: string
}

const defaultSignUpData: SignUpData = {
	birthday: "",
	email: "",
	fullname: "",
	password: "",
	type: undefined as any,
}

const userTypes: UserType[] = [
	"creator",
	"user",
]

@observer
export default
class SignUpView
extends React.Component<SignUpProps, SignUpState> {
	private signUpReq
		= api.account.signUp()

	private stepsCount
		: number
		= 4

	private query
		= parseQuery<SignUpQuery>(this.props.location.search)

	private localDefaultData
		: Partial<SignUpData>
		= {
			type: userTypes.includes(this.query.defaultType!)
				? this.query.defaultType
				: undefined
		}

	private signUp = () => {
		const passHash = sha256(this.data.password)
		return new Promise<void>((_, reject) => {
			this.signUpReq.request({
				birthday: this.data.birthday,
				email: this.data.email,
				fullname: this.data.fullname,
				type: this.data.type,
				isCelebrity: this.data.famous || false,
				contentTypes: this.data.contentTypes,
				categories: this.data.categories,
				password: passHash,
			}).then(() => {
				Auth.login(this.data.email, this.data.password)
					.catch(reject)
			}).catch(error => {
				reject(error?.status)
			})
		})
	}

	state
		: SignUpState
		= {
			currentStep: this.localDefaultData.type
				? this.localDefaultData.type == "user"
					? 2
					: 1
				: 0,
			direction: "forward"
		}

	data
		: SignUpData
		= {
			...defaultSignUpData,
			...this.localDefaultData
		}

	get isCreator(): boolean {
		return this.data.type == "creator"
	}

	componentWillUnmount() {
		this.signUpReq.abort()
	}

	goNext = () => {
		this.goToStep(this.state.currentStep + 1)
	}

	goBack = () => {
		this.goToStep(this.state.currentStep - 1)
	}

	goToStep = (
		stepIndex: number
	) => {
		this.setState({
			currentStep: stepIndex
		})
	}

	handleStepSubmit = (
		data: Partial<SignUpData>,
		forceStep?: number
	) => {
		if (this.data.type && data.type && this.data.type != data.type)
			this.data = {
				...defaultSignUpData,
				...data,
			}
		else
			this.data = {
				...this.data,
				...data,
			}

		if (forceStep)
			this.goToStep(forceStep)
		else
			this.goNext()
	}

	renderStep = (
		step: number
	) => {
		const defaultProps: SignUpStepProps = {
			defaultData: this.data,
			direction: this.state.direction,
			changeDirection: this.changeDirection,
			onBack: this.goBack,
			onSubmit: this.handleStepSubmit,
		}

		switch (step) {
			case 0:
				return <SignUpStep1
					{...defaultProps}
					onSubmit={data => {
						this.handleStepSubmit(
							data,
							data.type == "user" ? 2 : undefined,
						)
					}}
				/>
			case 1:
				return <SignUpStep2
					{...defaultProps}
				/>
			case 2:
				return <SignUpStep3
					{...defaultProps}
					onBack={() => {
						if (this.data.type == "user")
							this.goToStep(0)
						else
							this.goBack()
					}}
				/>
			case 3:
				return <SignUpStep4
					{...defaultProps}
					onBeforeSubmit={data => {
						this.data = {
							...this.data,
							...data,
						}
						return this.signUp()
					}}
				/>
			default:
				return <SignUpStepPlaceholder
					key={step}
					stepIndex={step}
					{...defaultProps}
				/>
		}
	}

	changeDirection = (
		direction: SignUpStepAnimationDirection
	) => {
		this.setState({ direction })
	}

	render() {
		if (Auth.isAuthChecked && Auth.isLoggedIn)
			return <Redirect
				to={this.query.redirect || Routing.compile.account.settings.home()}
			/>

		const { currentStep } = this.state
		return <>
			<Helmet
				title={currentStep == 0
					? "Sign up"
					: `Sign up as ${this.isCreator ? "creator" : "fan"}`
				}
				description={currentStep == 0
					? `Sign up to ${Main.projectName}. Become a creator and start earning cash for videos or sign up as a fan and get some fun or congratulations from your favourite stars`
					: this.isCreator
						? `Sign up to ${Main.projectName} as creator and start earning cash for making videos`
						: `Sign up to ${Main.projectName} as fan and order videos from your favourite creators`
				}
			/>
			<main className="v-sign-up u-fade-in">
				<section className="side-image">

				</section>

				<section className="main-content">
					<div className="main-content-inner-wrapper">
						<header>
							<Logo />
							<Stepper
								steps={this.stepsCount}
								current={currentStep}
							/>
						</header>
						{this.renderStep(currentStep)}
						<footer>
							<Link
								to={Routing.compile.legal.terms()}
								className="u-link dimmed"
							>
								Terms of Use
							</Link>
							<Link
								to={Routing.compile.legal.privacy()}
								className="u-link dimmed"
							>
								Privacy Policy
							</Link>
						</footer>
					</div>
				</section>
			</main>
		</>
	}
}