import ScrollArea from "components/UI/ScrollArea"
import React from "react"
import ReactDOM from "react-dom"
import { dayShortNames, monthNames } from "utils/date"

export interface DatepickerSelectProps {
	selected?: Date
	position: React.CSSProperties
	rangeChecker: (
		date: Date
	) => boolean
	onSelect: (
		date: Date
	) => void
}

export interface DatepickerSelectState {
	year: number
	month: number
	overlayFadeout: boolean
	overlayDirection: "forward" | "backward"
	overlay?: "year" | "month"
}

export default
class DatepickerSelect
extends React.Component<DatepickerSelectProps, DatepickerSelectState> {
	private get today(): Date {
		return new Date()
	}

	state
		: DatepickerSelectState
		= {
			year: (this.props.selected || this.today).getFullYear(),
			month: (this.props.selected || this.today).getMonth(),
			overlayFadeout: false,
			overlayDirection: "forward",
			overlay: undefined,
		}

	showOverlay = () => {
		this.setState({
			overlay: "month"
		})
	}

	hideOverlay = () => {
		this.setState({
			overlay: undefined
		})
	}

	handleOverlayAnimationEnd = (
		event: React.AnimationEvent<HTMLDivElement>
	) => {
		const { overlay } = this.state
		const { animationName } = event
		switch (animationName) {
			case "shiftOutX":
				if (overlay == "month")
					this.setState({
						overlay: "year",
						overlayFadeout: false,
					})
				return
			case "shiftOutXReverse":
				if (overlay == "year")
					this.setState({
						overlay: "month",
						overlayFadeout: false,
					})
		}
	}

	selectMonth = (
		month: number
	) => {
		this.setState({
			month,
			// overlayFadeout: true,
			// overlayDirection: "forward",
		})
	}

	selectYear = (
		year: number
	) => {
		this.setState({
			year,
			// overlay: undefined
		})
	}

	switchToMonth = () => {
		const { overlay } = this.state
		if (overlay == "month")
			return

		this.setState({
			overlayDirection: "backward",
			overlayFadeout: true,
		})
	}

	switchToYear = () => {
		const { overlay } = this.state
		if (overlay == "year")
			return

		this.setState({
			overlayDirection: "forward",
			overlayFadeout: true,
		})
	}

	get monthYearOverlay(): React.ReactNode {
		const { overlayFadeout, overlay, overlayDirection } = this.state
		return <div
			className="month-year-overlay"
			onAnimationEnd={this.handleOverlayAnimationEnd}
		>
			<div className={`switch ${overlay}`}>
				<div
					className="item month"
					onClick={this.switchToMonth}
				>
					{monthNames[this.state.month]}
				</div>
				<div
					className="item year"
					onClick={this.switchToYear}
				>
					{this.state.year}
				</div>
				<i
					className="fas fa-times default-hover" 
					onClick={this.hideOverlay}
				/>
			</div>
			<div
				key={overlay}
				className={`overlay-content ${overlay} ${overlayDirection} ${overlayFadeout ? "fade-out" : ""}`}
			>
				<div className="inner-wrapper">
					{overlay == "month"
						? <ScrollArea
							key="months"
							items={monthNames.map((label, value) => ({ label, value }))}
							defaultSelected={this.state.month}
							onSelect={this.selectMonth}
						/>
						: <ScrollArea
							key="years"
							items={[...Array(100)].map((_, i) => {
								const year = this.today.getFullYear() - i
								return {
									label: `${year}`,
									value: year,
								}
							})}
							defaultSelected={this.state.year}
							onSelect={this.selectYear}
						/>
					}
				</div>
			</div>
		</div>
	}

	prevMonth = () => {
		const { year, month } = this.state
		if (month <= 0)
			return this.setState({
				year: year - 1,
				month: 11
			})

		this.setState({
			month: month - 1
		})
	}

	nextMonth = () => {
		const { year, month } = this.state
		if (month >= 11)
			return this.setState({
				year: year + 1,
				month: 0
			})
		this.setState({
			month: month + 1
		})
	}

	selectDate = (
		day: number
	) => {
		this.props.onSelect(new Date(this.state.year, this.state.month, day, 0, -new Date().getTimezoneOffset(), 0))
	}

	get monthDates(): (number | undefined)[][] {
		const { year, month } = this.state

		const startDate = new Date(year, month, 1)
		const endDate = new Date(year, month + 1, 0)

		var firstIndex = startDate.getDay()
		firstIndex = !firstIndex ? 6 : firstIndex - 1

		const head = [...Array(firstIndex)]
		const main = [...Array(endDate.getDate())].map((_, i) => i + 1)
		const tail = [...Array(42 - head.length - main.length)]

		return [...head, ...main, ...tail].reduce((acc, item) => {
			const last = acc[acc.length - 1]
			if (!last || last.length >= 7)
				return [
					...acc,
					[item]
				]
			last.push(item)
			return acc
		}, [] as number[][])
	}

	isToday = (
		day: number
	): boolean => {
		const { month, year } = this.state
		const todayYear = this.today.getFullYear()
		const todayMonth = this.today.getMonth()
		const todayDay = this.today.getDate()

		return todayDay == day
			&& todayMonth == month
			&& todayYear == year
	}

	isSelected = (
		day: number
	): boolean => {
		const { selected } = this.props
		if (!selected)
			return false

		const { month, year } = this.state
		const selectedYear = selected.getFullYear()
		const selectedMonth = selected.getMonth()
		const selectedDay = selected.getDate()

		return selectedDay == day
			&& selectedMonth == month
			&& selectedYear == year
	}

	render() {
		const { year, month, overlay } = this.state

		return ReactDOM.createPortal(
			<div
				className="c-datepicker-select"
				style={this.props.position}
				onClick={event => {
					event.stopPropagation()
					event.nativeEvent.stopImmediatePropagation()
				}}
			>
				<div className="cds-inner-wrapper">
					<div className="month-year-switch">
						<i
							className="fas fa-chevron-left default-hover"
							onClick={this.prevMonth}
						/>
						<div 
							onClick={this.showOverlay}
							className="default-hover"
						>
							{monthNames[month]}, {year}
						</div>
						<i
							className="fas fa-chevron-right default-hover"
							onClick={this.nextMonth}
						/>
					</div>

					<div className="days">
						<div className="daynames row">
							{dayShortNames.map(day => {
								return <div key={day}>
									{day}
								</div>
							})}
						</div>
						{this.monthDates.map((week, i) => {
							return <div
								key={i}
								className="row"
							>
								{week.map((day, j) => {
									if (!day)
										return <div
											key={`${i}${j}`}
											className="empty"
										/>
									const isInRange = this.props.rangeChecker(new Date(this.state.year, this.state.month, day))
									return <div
										key={`${i}${j}`}
										className={`${
											this.isToday(day) ? "today" : "" } ${
											this.isSelected(day) ? "selected" : "" } ${
											isInRange ? "" : "disabled"
										}`}
										onClick={() => isInRange && this.selectDate(day)}
									>
										{day}
									</div>
								})}
							</div>
						})}
					</div>

					{overlay && this.monthYearOverlay}
				</div>
			</div>,
			document.body
		)
	}
}