import React from "react"
import { Link, RouteComponentProps } from "react-router-dom"

import "styles/views/error-page"

import Routing from "stores/Routing"
import Helmet from "components/Global/Helmet"

export interface ErrorPageProps
extends RouteComponentProps<any>, PropsWithChildren {
	message: React.ReactNode
	code?: number
}

export interface ErrorPageState {

}

export default
class ErrorPage
extends React.Component<ErrorPageProps, ErrorPageState> {
	render() {
		const { code, message, staticContext } = this.props

		if (code && staticContext)
			staticContext.statusCode = code

		return <>
			<Helmet
				title={`${message}`}
			/>
			<main className="v-error-page u-fs-view u-fade-in">
				<h1>{message}</h1>
				{code &&
					<p className="error-code">
						Status code: <strong>{code}</strong>
					</p>
				}
				{this.props.children}

				{/* FEATURE consider some button for let us know whether there is a bug */}
				<div className="actions">
					<Link
						to={Routing.compile.homepage()}
						className="u-button default"
					>
						<div className="ub-inner-wrapper">
							<i className="fas fa-home" />
							<span>
								To homepage
							</span>
						</div>
					</Link>
				</div>
			</main>
		</>
	}
}