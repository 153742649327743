import "@sounds.of.limbo/extensions/dist/Console"

type LoggerLog = (
	message: string,
	...additionalLogData: any[]
) => void

export default
class Logger {
	constructor(
		private readonly name: string,
	) {}

	private makeNamedMessage = (
		message: string
	): string => {
		return `｢ *${this.name}* ｣: ${message}`
	}

	log: LoggerLog = (
		message,
		...additional
	) => {
		console.say(" *>* " + this.makeNamedMessage(message), ...additional)
	}

	ok: LoggerLog = (
		message,
		...additional
	) => {
		console.ok(" *✔* " + this.makeNamedMessage(message), ...additional)
	}

	info: LoggerLog = (
		message,
		...additional
	) => {
		console.nfo(" *i* " + this.makeNamedMessage(message), ...additional)
	}

	warn: LoggerLog = (
		message,
		...additional
	) => {
		console.hey(" *!* " + this.makeNamedMessage(message), ...additional)
	}

	error: LoggerLog = (
		message,
		...additional
	) => {
		console.no(" *X* " + this.makeNamedMessage(message), ...additional)
	}
}