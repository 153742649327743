import React from "react"

import "styles/components/forms/ranges/two-sided"

export interface TwoSidedRangeProps {
	label?: React.ReactNode
	min: number
	max: number
	step?: number
	defaultFrom?: number
	defaultTo?: number
	name?: (which: TwoSidedWhich) => string
}

export interface TwoSidedRangeState {
	from: number
	to: number
	dragged?: TwoSidedWhich
}

type TwoSidedWhich = keyof Pick<TwoSidedRangeState, "from" | "to">

export default
class TwoSidedRange
extends React.Component<TwoSidedRangeProps, TwoSidedRangeState> {
	static defaultProps
		= {
			step: 1,
			name: (which: TwoSidedWhich) => which
		}

	private dragStartValue
		: number
		= 0

	private accumulatedMovement
		: number
		= 0
	
	private stepPixelReference
		: number
		= 0

	state
		: TwoSidedRangeState
		= {
			from: this.props.defaultFrom || this.props.min,
			to: this.props.defaultTo || this.props.max,
			dragged: undefined,
		}

	get minMaxPosition(): {
		min: number,
		max: number,
	} {
		const { from, to } = this.state
		const { min, max } = this.props
		return {
			min: (from - min) / (max - min) * 100,
			max: (to - min) / (max - min) * 100,
		}
	}

	get segmentsCount(): number {
		return (this.props.max - this.props.min) / this.props.step!
	}

	componentDidMount() {
		document.addEventListener("mousemove", this.moveDrag)
		document.addEventListener("mouseup", this.finishDrag)
	}

	componentWillUnmount() {
		document.removeEventListener("mousemove", this.moveDrag)
		document.removeEventListener("mouseup", this.finishDrag)
	}

	getMinMax = (
		which: TwoSidedWhich
	): {
		min: number,
		max: number,
	} => {
		if (which == "from")
			return {
				min: this.props.min,
				max: this.state.to - this.props.step!,
			}
		return {
			min: this.state.from + this.props.step!,
			max: this.props.max,
		}
	}

	handleInputChange = (
		which: TwoSidedWhich,
		value: string,
	) => {
		const newValue = Number(value)
		if (isNaN(newValue))
			return

		this.setValue(which, newValue)
	}

	setValue = (
		which: TwoSidedWhich,
		value: number,
	) => {
		const { min, max } = this.getMinMax(which)
			
		this.setState({ [which]: value.bound(min, max) } as any)
	}

	startDrag = (
		which: TwoSidedWhich,
		event: React.MouseEvent<HTMLDivElement>
	) => {
		const parent = event.currentTarget.parentElement
		if (!parent)
			return

		this.dragStartValue = this.state[which]
		this.accumulatedMovement = 0
		this.stepPixelReference = parent.offsetWidth / this.segmentsCount

		document.body.classList.add("usnone")
		this.setState({ dragged: which })
	}

	moveDrag = (
		event: MouseEvent
	) => {
		if (!this.state.dragged)
			return

		const { movementX } = event
		this.accumulatedMovement += movementX
		if (Math.abs(this.accumulatedMovement) >= this.stepPixelReference) {
			const { step } = this.props
			const { dragged } = this.state
			
			const incrementSteps = Math.trunc(this.accumulatedMovement / this.stepPixelReference)
			const incrementValue = incrementSteps * step!
			const newValue = this.dragStartValue + incrementValue
			this.accumulatedMovement = this.accumulatedMovement - incrementSteps * this.stepPixelReference
			this.dragStartValue = newValue
			this.setValue(dragged, newValue)
		}
	}

	finishDrag = () => {
		document.body.classList.remove("usnone")
		this.setState({
			dragged: undefined
		})
	}

	handleKeyDown = (
		which: TwoSidedWhich,
		event: React.KeyboardEvent<HTMLInputElement>,
	) => {
		const { key } = event

		switch (key) {
			case "ArrowDown":
				event.preventDefault()
				this.setValue(which, this.state[which] - this.props.step!)
				break
			case "ArrowUp":
				event.preventDefault()
				this.setValue(which, this.state[which] + this.props.step!)
				break
			default:
				return
		}
	}

	renderInput = (
		which: TwoSidedWhich
	): React.ReactNode => {
		which
		return <div className="input-wrapper">
			<span className="decoration">
				$
			</span>
			<input
				data-subtype="number"
				type="text"
				name={this.props.name?.(which)}
				value={this.state[which]}
				onChange={event => this.handleInputChange(which, event.currentTarget.value)}
				onKeyDown={event => this.handleKeyDown(which, event)}
			/>
		</div>
	}
	
	render() {
		const { min, max } = this.minMaxPosition
		const { dragged } = this.state
		return <>
			<div className="c-two-sided-range">
				{this.props.label &&
					<label className="u-input-label">
						<span className="label-content">
							{this.props.label}
						</span>
					</label>
				}
				<div className="inputs-wrapper">
					{this.renderInput("from")}
					<span className="connect-word">
						to
					</span>
					{this.renderInput("to")}
				</div>

				<div className="u-range">
					<div className="track" />
					<div
						className="track active"
						style={{
							left: `${min}%`,
							right: `${100 - max}%`,
						}}
					/>
					<div
						className={`thumb ${dragged == "from" ? "dragged" : ""}`}
						style={{ left: `calc(${min}% - 8px)` }}
						onMouseDown={event => this.startDrag("from", event)}
					/>
					<div
						className={`thumb ${dragged == "to" ? "dragged" : ""}`}
						style={{ left: `calc(${max}% - 8px)` }}
						onMouseDown={event => this.startDrag("to", event)}
					/>
				</div>
			</div>
		</>
	}
}