import React from "react"

import "styles/components/modals/complex/verify-email"

import api from "api"

import Auth from "stores/Auth"

import { getValidationForKey } from "utils/validation"

import Form from "components/Forms/Form"
import SimpleInput from "components/Forms/Inputs/Simple"
import SimpleButton from "components/UI/Buttons/Simple"
import SimpleModal from "components/Modals/Simple"

export interface VerifyEmailModalProps {

}

export interface VerifyEmailModalState {
	isLoading: boolean
	error?: string 
}

export default
class VerifyEmailModal
extends React.Component<VerifyEmailModalProps, VerifyEmailModalState> {
	private sendCode
		= api.account.sendCode()

	private verifyEmail
		= api.account.verifyEmail()

	private manualClose
		: () => void

	private setError = (
		error: any
	) => {
		this.setState({
			error: `${error}`
		})
	}

	private setLoading = () => {
		this.setState({
			isLoading: true,
			error: undefined,
		})
	}

	private unsetLoading = () => {
		this.setState({
			isLoading: false,
		})
	}

	state
		: VerifyEmailModalState
		= {
			isLoading: true,
			error: undefined,
		}

	componentDidMount() {
		if (!Auth.isLoggedIn)
			return

		this.sendCode.request({
			email: Auth.user?.email!,
		}).catch(this.setError).finally(this.unsetLoading)
	}

	componentWillUnmount() {
		this.sendCode.abort()
		this.verifyEmail.abort()
	}

	verify = (
		data: {
			code: string,
		},
	) => {
		if (this.state.isLoading)
			return

		this.setLoading()

		this.verifyEmail.request({
			confirmCode: data.code,
		}).then(() => {
			Auth.update({
				isEmailVerified: true,
			})
			this.manualClose()
		}).catch(this.setError).finally(this.unsetLoading)
	}

	render() {
		const { isLoading } = this.state

		return <>
			<SimpleModal
				closeOnOutsideClick={!this.state.isLoading}
				useManualClose={r => this.manualClose = r}
				onClose={Auth.emailVerification.hide}
				classNames={{
					innerWrapper: `c-email-verification-modal`
				}}
			>
				{close => {
					return <div className="ev-content">
						<h1>
							Email verification
						</h1>
						<p>
							We've send a verification code to email <strong>
								{Auth.user?.email}
							</strong>
						</p>
						<Form
							onSubmit={this.verify}
						>
							<SimpleInput
								name="code"
								required
								inputmode="numeric"
								disabled={isLoading}
								label="Verification code"
								onBeforeChange={value => value.replace(/[^0-9]/g, "")}
								pattern="[0-9]{6}"
								renderInvalidMessage={getValidationForKey("emailCode")}
							/>
							<div className="u-actions">
								<SimpleButton
									style="outline"
									onClick={isLoading ? undefined : close}
								>
									Cancel
								</SimpleButton>
								<SimpleButton
									submit
									loading={isLoading}
								>
									Verify email
								</SimpleButton>
							</div>
						</Form>
							
					</div>
				}}
			</SimpleModal>
		</>
	}
}