import SmoothImage from "components/UI/SmoothImage"
import React from "react"

export interface AvatarManagementProps {
	avatar: string
	onAvatarRemove: () => void
}

export interface AvatarManagementState {

}

export default
class AvatarManagement
extends React.Component<AvatarManagementProps, AvatarManagementState> {
	render() {
		return <>
			<div className="c-avatar-management">
				<SmoothImage
					src={this.props.avatar}
				/>
				<div className="actions-overlay">
					<i
						className="fas fa-trash-alt"
						onClick={this.props.onAvatarRemove}
					/>
				</div>
			</div>
		</>
	}
}