import React from "react"
import { observer } from "mobx-react"
import { RouteComponentProps } from "react-router-dom"

import "styles/views/account/views/orders/views/item"

import api from "api"

import { OrderData, OrderStatus } from "typings/api/Orders"

import Routing from "stores/Routing"

import SimplePreloader from "components/UI/Preloaders/Simple"
import OrderCardNew from "../../components/Card"
import Back from "components/UI/Buttons/Back"

export interface AccountOrderItemProps
extends RouteComponentProps<{ order: string }> {

}

export interface AccountOrderItemState {
	item?: OrderData
}

@observer
export default
class AccountOrderItem
extends React.Component<AccountOrderItemProps, AccountOrderItemState> {
	private itemRequest
		= api.orders.item()

	state
		: AccountOrderItemState
		= {
			item: undefined,
		}

	fetch = () => {
		this.itemRequest.abort()

		this.itemRequest.request({
			orderId: this.props.match.params.order,
		}).then(response => {
			this.setState({
				item: response.data.order,
			})
		}).catch(() => {
			this.props.history.replace(Routing.compile.account.orders.list())
		})
	}

	componentDidMount() {
		this.fetch()
	}

	componentWillUnmount() {
		this.itemRequest.abort()
	}

	updateStatus = (
		newStatus: OrderStatus,
		additionalData: Partial<OrderData> = {}
	) => {
		const { item } = this.state
		if (!item)
			return

		this.setState({
			item: {
				...item,
				...additionalData,
				status: newStatus,
			}
		})
	}

	render() {
		const { item } = this.state
		return <>
			<main className="v-account-order-item u-grey-view u-fade-in">
				<div className="vaoi-inner-wrapper">
					{!item
						? <div className="u-center">
							<SimplePreloader />
						</div>
						: <>
							<div className="vaoi-subactions">
								<Back to={Routing.compile.account.orders.list()}>
									Back to orders list
								</Back>
							</div>
							<OrderCardNew
								order={item}
								isExpanded
								onStatusChange={this.updateStatus}
							/>
						</>
					}
				</div>
			</main>
		</>
	}
}