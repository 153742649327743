import React from "react"

import "styles/components/ui/preloaders/image"

export interface ImagePreloaderProps {
	color?: string
}

export interface ImagePreloaderState {

}

export default
class ImagePreloader
extends React.Component<ImagePreloaderProps, ImagePreloaderState> {
	static defaultProps = {
		color: "black"
	}

	render() {
		return <>
			<div className="c-image-preloader">
				<svg viewBox="0 0 100 70">
					<polyline
						fill="none"
						points="2,70 2,2 98,2 98,68 10,68 30,30 40,50 60,10 80,50"
						stroke={this.props.color}
						strokeWidth={4}
						pathLength={100}
					/>
				</svg>
			</div>
		</>
	}
}