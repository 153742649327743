import api from "api"
import SimplePreloader from "components/UI/Preloaders/Simple"
import React from "react"

import "styles/views/user/views/profile/components/reviews/item"

import { Review } from "typings/api/Reviews"
import { PublicUserData } from "typings/api/User"
import { noop } from "utils"

export interface UserReviewItemProps {
	item: Review
}

export interface UserReviewItemState {
	user?: PublicUserData
}

export default
class UserReviewItem
extends React.Component<UserReviewItemProps, UserReviewItemState> {
	private userReq
		= api.account.item()

	state
		: UserReviewItemState
		= {
			user: undefined,
		}

	componentDidMount() {
		this.userReq.request({
			accountId: this.props.item.reviewerId,
		}).then(response => {
			this.setState({
				user: response.data.account
			})
		}).catch(noop)
	}

	componentWillUnmount() {
		this.userReq.abort()
	}

	render() {
		const { user } = this.state
		const { rating, text } = this.props.item
		return <>
			<div className="c-user-review">
				<div className="ur-header">
					<div className="user">
						{!user
							? <span style={{ opacity: .54 }}>
								<SimplePreloader color="black" />
							</span>
							: user.fullname
						}
					</div>
					<div className="rating">
						<div className="stars">
							{[...Array(rating)].map((_, i) => {
								return <i key={i} className="fas fa-star" />
							})}
							{[...Array(5 - rating)].map((_, i) => {
								return <i key={i} className="far fa-star" />
							})}
						</div>
						<span>
							{rating}
						</span>
					</div>
				</div>
				{text &&
					<div className="ur-content">
						<p>
							{text}
						</p>
					</div>
				}
			</div>
		</>
	}
}