import React from "react"

import "styles/components/ui/pagination"

export interface PaginationProps {
	totalPages: number
	onPageChange?: (
		nextPage: number
	) => void
	defaultPage?: number
	disabled?: boolean
}

export interface PaginationState {
	currentPage: number
}

export default
class Pagination
extends React.Component<PaginationProps, PaginationState> {
	private squashTreshold
		: number
		= 12

	private wrapTreshold
		: number
		= 2

	private tailsSize
		: number
		= 3

	state
		: PaginationState
		= {
			currentPage: this.props.defaultPage || 1
		}

	get hasNext(): boolean {
		return this.state.currentPage < this.props.totalPages
	}

	get hasPrev(): boolean {
		return this.state.currentPage > 1
	}

	get plainData(): number[] {
		return [...Array(this.props.totalPages)].map((_, i) => i + 1)
	}

	get squashedData(): (number | string)[] {
		const { currentPage } = this.state
		const { totalPages } = this.props

		const head = [...Array(this.tailsSize)].map((_, i) => i + 1)
		const tail = [...Array(this.tailsSize)].map((_, i) => totalPages - this.tailsSize + (i + 1))

		const wrapSize = this.wrapTreshold * 2 + 1
		let wrap = [...Array(wrapSize)].map((_, i) => currentPage - this.wrapTreshold + i)

		console.log(head, tail, wrap)

		if (head[head.length - 1] >= wrap[0]) {
			wrap.forEach(item => {
				if (item >= 1 && !head.includes(item))
					head.push(item)
			})
			wrap = []
		}

		if (wrap.length && wrap[wrap.length - 1] >= tail[0]) {
			wrap.reverse().forEach(item => {
				if (item <= totalPages && !tail.includes(item))
					tail.unshift(item)
			})
			wrap = []
		}

		const result = wrap.length
			? [
				...head,
				"-",
				...wrap,
				"-",
				...tail,
			]
			: [
				...head,
				"-",
				...tail,
			]

		console.log(result)
		return result
	}

	goToPage = (
		nextPage: number
	) => {
		if (this.props.disabled)
			return

		this.setState({
			currentPage: nextPage,
		})
		this.props.onPageChange?.(nextPage)
	}

	render() {
		const { totalPages } = this.props
		const { currentPage } = this.state

		return <>
			<div className={`c-pagination ${this.props.disabled ? "disabled" : ""}`}>
				<i
					className={`fas fa-chevron-left ${this.hasPrev ? "" : "hidden"}`}
					onClick={() => this.hasPrev && this.goToPage(currentPage - 1)}
				/>

				{(totalPages <= this.squashTreshold
					? this.plainData
					: this.squashedData	
				).map((page, i) => {
					if (typeof page == "string")
						return <span
							key={`delimiter_${i}`}
							className="page delimiter"
						>
							...
						</span>

					const isCurrent = page == currentPage
					return <span
						key={page}
						className={`page ${isCurrent ? "current" : "default"}`}
						onClick={() => !isCurrent && this.goToPage(page)}
					>
						{page}
					</span>
				})}

				<i
					className={`fas fa-chevron-right ${this.hasNext ? "" : "hidden"}`}
					onClick={() => this.hasNext && this.goToPage(currentPage + 1)}
				/>
			</div>
		</>
	}
}