export const monthNames: string[] = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
]

export const monthShortNames: string[] = monthNames.map(item => item.slice(0, 3))

export const dayNames: string[] = [
	"Monday",
	"Tuesday",
	"Wednesday",
	"Thursday",
	"Friday",
	"Saturday",
	"Sunday",
]

export const dayShortNames: string[] = dayNames.map(item => item.slice(0, 2))

export const getISODate = (
	date: Date
): string => {
	return date.toISOString().split("T")[0]
}

export const parseDate = (
	from: string
): Date | undefined => {
	if (!from.match(/[0-9]{2}\.[0-9]{2}\.[0-9]{4}/g))
		return undefined

	const parts = from.split(".")
	const [ day, month, year] = parts

	const date = new Date(`${year}-${month}-${day}T00:00:00z`)
	return isNaN(date.getTime())
		? undefined
		: date
}

export const getTimeString = (
	date: Date
): string => {
	return `${
		date.getHours().padStart(2) }:${
		date.getMinutes().padStart(2) }:${
		date.getSeconds().padStart(2)
	}`
}

export const dateToDateString = (
	date: Date
): string => {
	return `${
		date.getDate().padStart(2) }.${
		(date.getMonth() + 1).padStart(2) }.${
		date.getFullYear()
	}`
}

export const dateStringToIsoDateString = (
	dateString: string
): string => {
	return dateString.split(".").reverse().join("-")
}

export const isoDateStringToDateString = (
	isoString: string
): string => {
	return isoString.split("-").reverse().join(".")
}

// TODO think about test approaches
// or consider to make support function that could be tested
export const getYearsDistantDate = (
	years: number
): Date => {
	const date = new Date()
	date.setHours(0, 0, 0, 0)
	date.setFullYear(date.getFullYear() + years)
	return date
}