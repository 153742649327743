import React from "react"

import "styles/components/media/image-cropper"

import SimpleModal from "components/Modals/Simple"
import { toBase64 } from "utils/files"
import SimplePreloader from "components/UI/Preloaders/Simple"
import ImageProcessor from "stores/instantiable/ImageProcessor"

export interface ImageCropperProps {
	image: File
	onApply: (
		imageUrl: string
	) => void
	onClose: () => void
}

export interface ImageCropperState {
	point: [number, number]
	size: number
	isLoaded: boolean
	isLoading: boolean
}

type ResizeType =
	| "top-left"
	| "top-right"
	| "bottom-left"
	| "bottom-right"

export default
class ImageCropper
extends React.Component<ImageCropperProps, ImageCropperState> {
	private base64
		: string

	private imageSize
		: {
			width: number,
			height: number,
		}
		= {
			width: 0,
			height: 0,
		}

	private isDragged
		: boolean
		= false

	private nowResizing?
		: ResizeType
		= undefined

	private startPoint
		: [number, number]
		= [0, 0]

	private initialState
		: {
			point: [number, number],
			size: number,
		}
		= {
			point: [0, 0],
			size: 0,
		}

	private wrapper
		: HTMLDivElement

	private get aspectRatio(): number {
		return this.imageSize.width / this.imageSize.height
	}

	state
		: ImageCropperState
		= {
			point: [0, 0],
			size: 0,
			isLoaded: false,
			isLoading: false,
		}

	componentDidMount() {
		toBase64(this.props.image).then(base64 => {
			this.base64 = base64
			const image = new Image()
			image.src = this.base64
			image.addEventListener("load", () => {
				this.imageSize.width = image.width
				this.imageSize.height = image.height
				this.setState({
					isLoaded: true
				}, this.fill)
			})
		})

		document.addEventListener("mousemove", this.move)
		document.addEventListener("mouseup", this.moveEnd)
	}

	componentWillUnmount() {
		document.removeEventListener("mousemove", this.move)
		document.removeEventListener("mouseup", this.moveEnd)
	}

	fill = () => {
		const { offsetWidth: width, offsetHeight: height } = this.wrapper

		const size = Math.min(width, height)
		const point: [number, number] = this.aspectRatio < 1
			? [0, height / 2 - size / 2]
			: [width / 2 - size / 2, 0]

		this.setState({
			point,
			size,
		})
	}

	moveStart = (
		event: React.MouseEvent<HTMLDivElement>
	) => {
		if (this.isDragged || this.nowResizing || event.button != 0 || !this.state.isLoaded || this.state.isLoading)
			return

		this.isDragged = true
		this.startPoint = [event.clientX, event.clientY]

		const { point, size } = this.state
		this.initialState = { point, size, }
	}

	resizeStart = (
		event: React.MouseEvent,
		type: ResizeType,
	) => {
		if (this.isDragged || this.nowResizing || event.button != 0 || !this.state.isLoaded || this.state.isLoading)
			return

		this.nowResizing = type
		this.startPoint = [event.clientX, event.clientY]

		const { point, size } = this.state
		this.initialState = { point, size, }
	}

	move = (
		event: MouseEvent,
	) => {
		if (this.nowResizing)
			return this.resize(event)

		if (!this.isDragged)
			return

		const { point, size } = this.initialState
		const [ sx, sy ] = this.startPoint
		const { clientX: x, clientY: y } = event

		const max = [
			this.wrapper.offsetWidth - size,
			this.wrapper.offsetHeight - size,
		]
		
		this.setState({
			point: [
				(point[0] + (x - sx)).bound(0, max[0]),
				(point[1] + (y - sy)).bound(0, max[1]),
			]
		})
	}

	resize = (
		event: MouseEvent,
	) => {
		if (!this.nowResizing)
			return

		const { clientX: x, clientY: y } = event
		const [ sx, sy ] = this.startPoint
		const { point, size } = this.initialState
		const minSize = 40

		const [ dx, dy ] = [
			(x - sx),
			(y - sy),
		]

		const [ bx, by ] = [
			point[0] + size,
			point[1] + size,
		]

		switch (this.nowResizing) {
			case "top-left":
				(() => {
					const delta = dx > dy ? dy : dx
					const maxSize = size + Math.min(...point)
					const [ minX, minY ] = [
						bx - maxSize,
						by - maxSize,
					]
					const [ maxX, maxY ] = [
						bx - minSize,
						by - minSize,
					]
					this.setState({
						point: [
							(point[0] + delta).bound(minX, maxX),
							(point[1] + delta).bound(minY, maxY),
						],
						size: (size - delta).bound(minSize, maxSize),
					})
				})()
				break
			case "top-right":
				(() => {
					const delta = dx > -dy ? dx : -dy
					const maxSize = size + Math.min(this.wrapper.offsetWidth - point[0] - size, point[1])
					const minY = by - maxSize
					const maxY = by - minSize
					this.setState({
						point: [
							point[0],
							(point[1] - delta).bound(minY, maxY)
						],
						size: (size + delta).bound(minSize, maxSize)
					})
				})()
				break
			case "bottom-left":
				(() => {
					const delta = dy > -dx ? dy : -dx
					const maxSize = Math.min(bx, this.wrapper.offsetHeight - point[1])
					const minX = bx - maxSize
					const maxX = bx - minSize
					this.setState({
						point: [
							(point[0] - delta).bound(minX, maxX),
							point[1],
						],
						size: (size + delta).bound(minSize, maxSize)
					})
				})()
				break
			case "bottom-right":
				(() => {
					const delta = dx > dy ? dx : dy
					const maxSize = Math.min(this.wrapper.offsetWidth - point[0], this.wrapper.offsetHeight - point[1])
					this.setState({
						size: (size + delta).bound(minSize, maxSize),
					})
				})()
				break
		}
	}

	moveEnd = () => {
		this.isDragged = false
		this.nowResizing = undefined
	}

	apply = (
		close: () => void,
	) => {
		if (!this.state.isLoaded || this.state.isLoading)
			return

		this.setState({
			isLoading: true,
		})

		const { point, size } = this.state
		const { width, height } = this.imageSize

		new ImageProcessor().processAndUpload(
			this.props.image,
			[
				{
					type: "crop",
					data: {
						point: {
							x: point[0] / this.wrapper.offsetWidth * width,
							y: point[1] / this.wrapper.offsetHeight * height,
						},
						size: {
							width: size / this.wrapper.offsetWidth * width,
							height: size / this.wrapper.offsetHeight * height
						},
					},
				},
				{
					type: "scale",
					data: {
						width: 256,
						height: 256,
					},
				},
			]
		).then(value => {
			this.props.onApply(value)
			close()
		}).catch(error => {
			console.error(error)
			this.setState({
				isLoading: false,
			})
		})
	}

	render() {
		const { isLoaded, isLoading, point, size } = this.state

		return <>
			<SimpleModal
				onClose={this.props.onClose}
				classNames={{
					innerWrapper: `c-image-cropper ${this.aspectRatio > 1 ? "" : "shrink"}`
				}}
			>
				{close => {
					return <>
						<header>
							Select area
						</header>
						{!isLoaded
							? <SimplePreloader color="#000000AA" />
							: <div
								ref={r => this.wrapper = r!}
								className="cropper-content"
							>
								<img
									src={this.base64}
									draggable={false}
								/>
								<div
									className="overlay"
									style={{
										clipPath: `polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%, 0% 0%, ${
											`${point[0]}px ${point[1]}px` }, ${ `${point[0] + size}px ${point[1]}px` }, ${
											`${point[0] + size}px ${point[1] + size}px` }, ${ `${point[0]}px ${point[1] + size}px` }, ${
											`${point[0]}px ${point[1]}px`
										})`
									}}
								/>
								<div
									onMouseDown={this.moveStart}
									className="area"
									style={{
										top: point[1],
										left: point[0],
										width: size,
										height: size,
									}}
								>
									<span onMouseDown={event => this.resizeStart(event, "top-left")} />
									<span onMouseDown={event => this.resizeStart(event, "top-right")} />
									<span onMouseDown={event => this.resizeStart(event, "bottom-right")} />
									<span onMouseDown={event => this.resizeStart(event, "bottom-left")} />
								</div>
							</div>
						}
						<div className="actions u-actions justify-end">
							{!isLoading && isLoaded &&
								<div
									className="u-button smaller outline"
									onClick={close}
								>
									<div className="ub-inner-wrapper">
										Cancel
									</div>
								</div>
							}
							<div
								className={`u-button smaller default ${isLoading || !isLoaded ? "disabled" : ""}`}
								onClick={() => this.apply(close)}
							>
								<div className="ub-inner-wrapper">
									{isLoading || !isLoaded
										? <SimplePreloader color="white" />
										: "Apply"
									}
								</div>
							</div>
						</div>
					</>
				}}
			</SimpleModal>
		</>
	}
}