interface Number {
	/**
	 * Pad start of the number to match specified lenth with specified character
	 */
	padStart: (
		maxLength: number,
		/**
		 * Character to pad number with.  
		 * Defaults to "0"
		 */
		padWith?: string,
	) => string

	/**
	 * Bounds value to provided min and max values
	 */
	bound: (min: number, max: number) => number

	/**
	 * Place space each 3 numbers
	 */
	formatThousands: () => string

	/**
	 * Treat number as seconds and return verbose time string.  
	 * For example:  
	 * **42 sec**  
	 * **4 min 20 sec**  
	 * **1 h 15 min 1 sec**
	 */
	toVerboseTime: () => string

	/**
	 * Treat number as bytes and return verbose size string.  
	 * For example:
	 * **921 bytes**
	 * **128 Kb**
	 * **1.2 Mb**
	 * **12.28 Gb**
	 */
	toVerboseSize: () => string

	/**
	 * Treat number as seconds and return time string.  
	 * For example:  
	 * **00:42**  
	 * **04:20**  
	 * **1:15:01**  
	 */
	toTime: (
		/**
		 * If there is more than 24 hours, whether there should be extra 'days' label at start or not.  
		 * Default: `false`
		 */
		separateDays?: boolean
	) => string

	/**
	 * Turn number into pluralized string, e.g.  
	 * **1 item**  
	 * **24 points**
	 */
	pluralize: (
		singularForm: string,
		pluralForm: string,
	) => string

	isInRange: (
		from: number,
		to: number
	) => boolean
}

Number.prototype.padStart = function(
	maxLength: number,
	padWith: string = "0",
) {
	const self = this as number
	return `${self}`.padStart(maxLength, padWith)
}

Number.prototype.bound = function(
	min: number,
	max: number,
) {
	const self = Number(this)
	return self < min
		? min
		: self > max
			? max
			: self
}

Number.prototype.formatThousands = function() {
	const self = Number(this)
	const prependSign = self < 0 ? "-" : ""
	return prependSign + `${self}`.split("").reverse().join("").match(/[0-9]{1,3}/g)!.join(" ").split("").reverse().join("")
}

Number.prototype.toVerboseTime = function() {
	const self = Number(this)
	const abs = self < 0 ? -self : self
	const prependSign = self < 0 ? "-" : ""

	const seconds = abs | 0
	const absSeconds = seconds % 60
	const absMinutes = (seconds / 60 | 0) % 60 
	const hours = seconds / 60 / 60 | 0
	return prependSign + (
		(hours ? `${hours} h ` : "") +
		(absMinutes ? `${absMinutes} min ` : "") +
		(absSeconds ? `${absSeconds} sec ` : "")
	).trim() || "0 sec"
}

Number.prototype.toVerboseSize = function() {
	const self = Number(this)
	const kb = self / 1024
	const mb = kb / 1024
	const gb = mb / 1024
	return gb >= 1
		? gb.toFixed(2) + " Gb"
		: mb >= 1
			? mb.toFixed(1) + " Mb"
			: kb >= 1
				? kb.toFixed(1) + " Kb"
				: self + (self == 1 ? " byte" : " bytes")
}

Number.prototype.toTime = function(
	separateDays: boolean = false
) {
	const self = Number(this)
	const abs = self < 0 ? -self : self
	const prependSign = self < 0 ? "-" : ""

	const seconds = abs | 0
	const absSeconds = seconds % 60
	const absMinutes = (seconds / 60 | 0) % 60

	let hours = seconds / 60 / 60 | 0
	let days: number = 0

	if (separateDays) {
		days = hours / 24 | 0
		hours = hours % 24
	}


	return prependSign + `${
		separateDays && days ? `${days} day${days == 1 ? "" : "s"} ` : ""}${
		hours || separateDays ? `${separateDays ? hours.padStart(2) : hours}:` : "" }${
		absMinutes.padStart(2) }:${
		absSeconds.padStart(2)
	}`
}

Number.prototype.pluralize = function(
	singularForm: string,
	pluralForm: string,
) {
	const self = Number(this)
	return Math.abs(self) == 1
		? `${self} ${singularForm}`
		: `${self} ${pluralForm}`
}

Number.prototype.isInRange = function(
	from: number,
	to: number,
) {
	const self = Number(this)
	return self >= from
		&& self <= to
}