import React from "react"
import { observer } from "mobx-react"
import { RouteComponentProps } from "react-router-dom"

import "styles/views/homepage"
import HomepageMainSection from "./components/sections/Main"
import HomepageActionsSection from "./components/sections/Actions"
import HomepageUsersList from "./components/UsersList"
import HomepageCallToActionSection from "./components/sections/CallToAction"
import HomepageHowItWorksSection from "./components/sections/HowItWorks"
import FeaturedWithCategories from "./components/sections/FeaturedWithCategories"
import Main from "stores/Main"
import SimplePreloader from "components/UI/Preloaders/Simple"

export interface HomepageProps
extends RouteComponentProps<any> {

}

export interface HomepageState {

}

@observer
export default
class Homepage
extends React.Component<HomepageProps, HomepageState> {
	render() {
		return <>
			<main className="v-homepage u-grey-view u-fade-in">
				<HomepageMainSection />
				<HomepageActionsSection />

				<section className="ho-featured-users ho-wrapper">
					<h2>Featured</h2>
					<HomepageUsersList
						users={[...Array(10)].map((_, i) => {
							return {
								fullname: `Temp User #${i + 1}`,
								id: `61fe932cde1606947aee954${i}`,
								isCelebrity: true,
								rating: {
									value: Math.random() * 4 + 1,
									count: Math.round(Math.random() * 100)
								},
								type: "creator",
								avatar: `https://picsum.photos/1024/1365/?random&key=${i}`,
								categories: ["61798e65f276b84e007dc225"],
								contentTypes: ["61798ee1f276b84e007dc226"],
							}
						})}
					/>
				</section>

				<HomepageCallToActionSection />

				{!Main.dictionaries.categories.list
					? <SimplePreloader color="black" />
					: <FeaturedWithCategories />
				}

				<HomepageHowItWorksSection />
			</main>
		</>
	}
}