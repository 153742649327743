import React from "react"
import { RouteComponentProps } from "react-router-dom"

export interface LegalTermsViewProps
extends RouteComponentProps<any> {

}

export interface LegalTermsViewState {

}

export default
class LegalTermsView
extends React.Component<LegalTermsViewProps, LegalTermsViewState> {
	render() {
		return <>
			<main className="v-legal-view terms">
				<h1>Terms of use</h1>
			</main>
		</>
	}
}