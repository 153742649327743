import React from "react"
import { observer } from "mobx-react"

import "styles/components/global/user/badge"

import AvatarRound from "./avatars/Round"
import { PublicUserData } from "typings/api/User"

export interface UserBadgeProps
extends PropsWithChildren {
	user: PublicUserData
}

export interface UserBadgeState {

}

@observer
export default
class UserBadge
extends React.Component<UserBadgeProps, UserBadgeState> {
	render() {
		const { fullname, avatar } = this.props.user
		return <>
			<div className="c-user-badge">
				<div className="avatar">
					<AvatarRound
						src={avatar}
					/>
				</div>
				<div className="content">
					<div className="user-name">
						{fullname} <img
							src="/static/images/icons/verified_celebrity.svg"
						/>
					</div>
					<div className="additional-content">
						{this.props.children}
					</div>
				</div>
			</div>
		</>
	}
}