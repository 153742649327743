import React from "react"
import { Link } from "react-router-dom"
import Main from "stores/Main"
import Routing from "stores/Routing"

import "styles/components/global/header/components/logo"

export interface LogoProps {
	noLink?: boolean
}

export interface LogoState {

}

export default
class Logo
extends React.Component<LogoProps, LogoState> {
	get logoContent(): React.ReactNode {
		return <>
			<img
				src="/static/images/logo.svg"
				alt={Main.projectName}
			/>
		</>
	}

	render() {
		const { noLink } = this.props
		return <>
			{noLink
				? <div className="c-logo">
					{this.logoContent}
				</div>
				: <Link
					to={Routing.compile.homepage()}
					className="c-logo"
				>
					{this.logoContent}
				</Link>
			}
		</>
	}
}