import React from "react"
import { observer } from "mobx-react"
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom"

import Routing from "stores/Routing"
import UserItem from "stores/UserItem"

import UserOrder from "./views/Order"
import UserProfile from "./views/Profile"
import ErrorPage from "views/ErrorPage"

export interface UserRouteProps
extends RouteComponentProps {

}

export interface UserRouteState {

}

export default
class UserRoute
extends React.Component<UserRouteProps, UserRouteState> {
	render() {
		return <>
			<Switch>
				<Route
					path={Routing.patterns.user.profile}
					render={(props: RouteComponentProps<{ id: string }>) => {
						return <UserRouteInterceptor key={props.match.params.id} {...props} />
					}}
				/>

				<Redirect to={Routing.patterns.homepage} />
			</Switch>
		</>
	}
}

@observer
class UserRouteInterceptor
extends React.Component<RouteComponentProps<{ id: string }>, {}> {
	private descriptiveErrors
		: {
			[key in number | string | "default"]: (
				userId: DBID
			) => React.ReactNode
		}
		= {
			404: userId => <p>
				User with ID <strong>{userId}</strong> does not exist anymore or yet.
			</p>,
			default: userId => <p>
				Unexpectedly failed to get user with ID <strong>{userId}</strong>
			</p>
		}


	componentDidMount() {
		if (!UserItem.user)
			UserItem.setUser(this.props.match.params.id)
	}

	componentWillUnmount() {
		UserItem.clearStore()
	}

	render() {
		const { error } = UserItem
		if (error)
			return <ErrorPage
				{...this.props}
				code={error.code}
				message={error.message}
			>
				{(this.descriptiveErrors[error.code] || this.descriptiveErrors.default)(this.props.match.params.id)}
			</ErrorPage>

		return <>
			<Switch>
				<Route
					exact
					path={Routing.patterns.user.profile}
					render={(props: RouteComponentProps<{ id: string }>) => {
						return <UserProfile {...props} />
					}}
				/>
				<Route
					exact
					path={Routing.patterns.user.order}
					render={(props: RouteComponentProps<{ id: string }>) => {
						return <UserOrder {...props} />
					}}
				/>

				<Redirect to={Routing.patterns.homepage} />
			</Switch>
		</>
	}
}