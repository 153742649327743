import api from "api"
import { makeObservable, observable, computed, action } from "mobx"
import { load, ReCaptchaInstance } from "recaptcha-v3"
import Logger from "utils/entities/Logger"

const siteKey: string = "6Lccsl8hAAAAAOoDU4vQ7paLNZNAG2kWkRLI7dv1"

export type CaptchaVerificationType =
	| "signup"
	| "login"

class Captcha {
	private logger
		= new Logger("Captcha")

	@observable
	private _ready
		: boolean
		= false

	private captcha
		: ReCaptchaInstance

	constructor() {
		makeObservable(this)
	}

	@computed
	get isReady(): boolean {
		return this._ready
	}

	@action
	setReady = (
		isReady: boolean
	) => {
		this._ready = isReady
	}

	load = () => {
		load(
			siteKey,
			{ autoHideBadge: true }
		).then(instance => {
			this.captcha = instance
			this.setReady(true)
			this.logger.ok("Instance has been loaded")
		}).catch(error => {
			this.logger.error(`Failed to load instance - *${error}*`)
		})
	}

	verify = (
		type: CaptchaVerificationType
	): Promise<void> => {
		this.logger.log(`Executing verification of *${type}*`)

		return this.captcha.execute(type)
			.then(async token => {
				const { data } = await api.commons.verifyRecaptcha().request({ token })
				if (!data.success)
					throw new Error("Verification failed")

				this.logger.ok(`Verification of *${type}* succesfull`)
			})
			.catch(error => {
				this.logger.error(`Failed to verify *${type}* - *${error}*`)

				throw error
			})
	}
}

export default new Captcha()