export type EventEmitterListener<T> = T extends void
	? () => void
	: (data: T) => void

type EventListenerDataReferenceBase<ET extends string> = {
	[key in ET]: any
}

type EventListenersKeeper<
	ET extends string, 
	ER extends EventListenerDataReferenceBase<ET>
> = {
	[key in ET]: EventEmitterListener<ER[key]>[]
}

export class EventEmitter<
	ET extends string, 
	ER extends EventListenerDataReferenceBase<ET>
> {
	constructor (
		private readonly eventTypes: ET[]
	) {}

	private listenersKeeper
		: EventListenersKeeper<ET, ER>
		= this.eventTypes.reduce((acc, event) => {
			return {
				...acc,
				[event]: []
			}
		}, {} as EventListenersKeeper<ET, ER>)

	protected emit = <T extends ET>(
		eventType: T,
		...eventData: ER[T] extends void ? [] : [ER[T]]
	) => {
		this.listenersKeeper[eventType].forEach(eventListener => {
			eventListener(eventData[0])
		})
	}

	addEventListener = <T extends ET>(
		eventType: T,
		eventListener: EventEmitterListener<ER[T]>,
	) => {
		this.listenersKeeper[eventType].push(eventListener)
	}

	removeEventListener = <T extends ET>(
		eventType: T,
		eventListener?: EventEmitterListener<ER[T]>,
	) => {
		if (eventListener) {
			const indexOf = this.listenersKeeper[eventType].indexOf(eventListener)
			if (indexOf != -1)
				this.listenersKeeper[eventType].splice(indexOf, 1)
		} else {
			this.listenersKeeper[eventType] = []
		}
	}
}