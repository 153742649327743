export default
class DelayAction {
	private timeoutId
		: number

	clear = () => {
		clearTimeout(this.timeoutId)
	}

	exec = (
		action: () => void,
		delay: number
	) => {
		this.clear()
		this.timeoutId = window.setTimeout(action, delay)
	}
}