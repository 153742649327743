import React from "react"
import { Link } from "react-router-dom"
import "styles/views/homepage/components/sections/how-it-works"

import Routing from "stores/Routing"

import { UserType } from "typings/Auth"
import { SelectOption } from "typings/Form"

import AnimatedAccordion from "components/UI/AnimatedAccordion"

export interface HomepageHowItWorksSectionProps {
	currentTab?: UserType
	standalone?: boolean
}

export interface HomepageHowItWorksSectionState {
	currentTab: UserType
}

export default
class HomepageHowItWorksSection
extends React.Component<HomepageHowItWorksSectionProps, HomepageHowItWorksSectionState> {
	content
		: {
			[key in UserType]: {
				title: string
				description: string
			}[]
		}
		= {
			user: [
				{
					title: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore voluptatibus porro possimus neque, ea illo vero ex soluta.",
					description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Error tenetur quibusdam iure sit magnam omnis molestiae corporis! Vel, magnam quos error labore sequi quam omnis voluptatibus consequuntur quae quibusdam esse voluptates. Reprehenderit blanditiis fugit commodi tempore quas. Exercitationem, suscipit eaque.",
				},
			],
			creator: [
				{
					title: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus magni saepe quaerat.",
					description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sed sequi, molestiae eius nostrum omnis enim, eum mollitia reiciendis at voluptate perferendis adipisci maxime quaerat, ut iusto provident quia debitis vel earum blanditiis dolores et officiis. Maiores.",
				},
				{
					title: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
					description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Error tenetur quibusdam iure sit magnam omnis molestiae corporis! Vel, magnam quos error labore sequi quam omnis voluptatibus consequuntur quae quibusdam esse voluptates. Reprehenderit blanditiis fugit commodi tempore quas. Exercitationem, suscipit eaque.",
				},
				{
					title: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore voluptatibus porro possimus neque, ea illo vero ex soluta.",
					description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia inventore quaerat iste blanditiis, eos assumenda asperiores animi aut atque repudiandae magnam vel fugiat necessitatibus aperiam! Cumque, provident harum eum totam nihil dicta culpa voluptates nostrum atque hic, optio iusto iste eveniet laudantium perferendis doloribus. Quibusdam, eos rerum. Possimus, incidunt iusto quod, cumque atque magnam nobis, nostrum perferendis iure deserunt natus.",
				},
				{
					title: "Lorem, ipsum dolor.",
					description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit deleniti quo cum dolore expedita voluptatum vero suscipit repudiandae animi? Minus harum qui vero mollitia.",
				}
			]
		}

	tabs
		: SelectOption<UserType>[]
		= [
			{
				label: "Video creator",
				value: "creator",
			},
			{
				label: "User",
				value: "user",
			},
		]

	state
		: HomepageHowItWorksSectionState
		= {
			currentTab: "creator"
		}
	
	get currentTab(): UserType {
		return this.props.standalone
			? this.props.currentTab || "creator"
			: this.state.currentTab
	}

	switchToTab = (
		tab: UserType
	) => {
		if (tab == this.state.currentTab)
			return

		this.setState({
			currentTab: tab,
		})
	}

	render() {
		const { standalone } = this.props
		const title = "How it works"
		const currentTabIndex = this.tabs.findIndex(item => item.value == this.currentTab)

		return <>
			<section className="c-homepage-how-it-works u-paper ho-wrapper">
				{standalone
					? <h1>{title}</h1>
					: <h2>{title}</h2>
				}

				<div className="hhiw-tabs">
					<div className="hhiw-tabs-outer-wrapper">
						<div className="hhiw-tabs-inner-wrapper">
							<span
								className="highlighter"
								style={{
									left: `${100 / this.tabs.length * currentTabIndex}%`,
									width: `${100 / this.tabs.length}%`
								}}
							/>
							{this.tabs.map(tab => {
								return standalone && tab.value != this.currentTab
									? <Link
										key={tab.value}
										to={Routing.compile.faq.tab(tab.value)}
									>
										{tab.label}
									</Link>
									: <div
										key={tab.value}
										onClick={() => this.switchToTab(tab.value)}
									>
										{tab.label}
									</div>
							})}
						</div>
					</div>
				</div>

				<div className="hhiw-faqs">
					{this.content[this.currentTab].map((item, i) => {
						return <HomepageHowItWorksAccordion
							key={`${i} ${this.currentTab}`}
							title={item.title}
							standalone={standalone}
						>
							{item.description}
						</HomepageHowItWorksAccordion>
					})}
				</div>

				<div className="hhiw-cta u-center">
					<Link
						to={Routing.compile.signup()}
						className="ho-action blue"
					>
						Get started
					</Link>
				</div>
			</section>
		</>
	}
}

interface HomepageHowItWorksAccordionProps {
	title: string
	standalone?: boolean
	children: string
}

class HomepageHowItWorksAccordion
extends React.Component<HomepageHowItWorksAccordionProps, { expanded: boolean }> {
	state
		= {
			expanded: false,
		}

	toggle = () => {
		this.setState({
			expanded: !this.state.expanded,
		})
	}

	render() {
		const { expanded } = this.state
		const { standalone, title } = this.props
		return <>
			<article className="c-homepage-how-it-works-accordion">
				<header
					className={expanded ? "expanded" : ""}
					onClick={this.toggle}
				>
					<i className="fas fa-chevron-down"/>
					{standalone
						? <h2>
							{title}
						</h2>
						: <h3>
							{title}
						</h3>
					}
				</header>
				<AnimatedAccordion
					expanded={this.state.expanded}
					duration={250}
				>
					<p>{this.props.children}</p>
				</AnimatedAccordion>
			</article>
		</>
	}
}