import React from "react"

import "styles/components/ui/decorations/interactive/info"

export interface InfoProps
extends PropsWithChildren {
	icon?: string
}

export interface InfoState {
	showToBottom: boolean
	shifted: boolean
}

export default
class Info
extends React.Component<InfoProps, InfoState> {
	static defaultProps
		: Partial<InfoProps>
		= {
			icon: "info-circle"
		}

	private icon
		: HTMLElement
	private popup
		: HTMLDivElement

	state
		: InfoState
		= {
			showToBottom: false,
			shifted: false,
		}

	private healhcheck = () => {
		const box = this.popup.getBoundingClientRect()

		if (box.right >= document.body.offsetWidth)
			this.setState({
				shifted: true,
			})
	}

	componentDidMount() {
		this.healhcheck()
	}

	checkTopAvailability = () => {
		this.setState({
			showToBottom: this.icon!.getBoundingClientRect().top < this.popup!.offsetHeight + 100
		})
	}

	render() {
		const { showToBottom, shifted } = this.state
		return <>
			<div className="c-info">
				<i
					ref={r => this.icon = r!}
					className={`fas fa-${this.props.icon}`}
					onMouseEnter={this.checkTopAvailability}
				/>
				<div 
					ref={r => this.popup = r!}
					className={`popup ${
						showToBottom ? "bottom" : "top"} ${
						shifted ? "shifted" : ""
					}`}
				>
					{this.props.children}
				</div>
			</div>
		</>
	}
}