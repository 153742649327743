import api from "api"

import { Counters, DefaultDictionaries } from "typings"
import { RatedItem } from "typings/api"

import { DictionaryStore } from "./instantiable/Dictionary"
import { PlainReactiveStore } from "./instantiable/PlainReactive"

export interface GlobalDictionaryDataType {
	occasions: [RatedItem[], DictionaryStore]
	categories: [RatedItem[], DictionaryStore]
	categoriesCounters: [Counters, PlainReactiveStore<Counters>]
}

export type GlobalDictionary = keyof GlobalDictionaryDataType

export class MainStore {
	static requests
		: {
			[key in GlobalDictionary]: () => Promise<GlobalDictionaryDataType[key][0]>
		} 
		= {
			occasions: () => api.dictionaries.occasions().request().then(response => response.data.occasions),
			categories: () => api.dictionaries.categories().request().then(response => response.data.categories),
			categoriesCounters: () => new Promise<{ [key: string]: number }>(resolve => setTimeout(() => resolve({
				"ed8eb408-0084-41af-a3d0-a7730dec647e": 12,
				"61798e65f276b84e007dc222": 123,
				"61798e65f276b84e007dc224": 2,
			}), 1000))
		}

	dictionaries
		: {
			[key in GlobalDictionary]: GlobalDictionaryDataType[key][1]
		}
		= {
			occasions: new DictionaryStore(MainStore.requests.occasions),
			categories: new DictionaryStore(MainStore.requests.categories),
			categoriesCounters: new PlainReactiveStore(MainStore.requests.categoriesCounters),
		}

	projectName
		: string
		= "HiMyFan"

	isDev
		: boolean
		= process.env.NODE_ENV == "development"

	get keys(): GlobalDictionary[] {
		const { dictionaries } = this
		return Object.keys(dictionaries) as GlobalDictionary[]
	}

	fillDictionaries = () => {
		this.keys.forEach(key => {
			this.dictionaries[key].fill()
		})
	}

	setDefaults = (
		defaultDictionaries?: DefaultDictionaries
	) => {
		if (!defaultDictionaries)
			return

		this.keys.forEach(key => {
			this.dictionaries[key].set(defaultDictionaries[key] as any)
		})
	}
}

export default new MainStore()