import React from "react"
import { Helmet as ReactHelmet } from "react-helmet"

import Main from "stores/Main"

import { isDefined } from "utils/type"

export interface HelmetProps {
	title?: string
	description?: string
	image?: string
}

export interface HelmetState {

}

export default
class Helmet
extends React.Component<HelmetProps, HelmetState> {
	_<T>(
		array: T[],
		condition: boolean,
	): T[] {
		return condition
			? array
			: []
	}

	fill = (
		keys: string[],
		value: string,
	): [string, string][] => {
		return keys.map(key => [key, value])
	}

	renderItem = (
		key: string,
		value: string,
	): React.ReactNode => {
		switch (true) {
			case key == "title":
				return <title key={key}>{value} • {Main.projectName}</title>
			case key == "description":
				return <meta key={key} name="description" content={value} />
			case key.startsWith("og:"):
				return <meta key={key} property={key} content={value} />
			default:
				return ""
		}
	}

	render() {
		const { _, fill } = this
		const { title, description, image } = this.props

		const items: [string, string][] = [
			..._(
				fill(["title", "og:title"], title!),
				isDefined(title),
			),
			..._(
				fill(["description", "og:description"], description!),
				isDefined(description),
			),
			..._(
				fill(["og:image"], image!),
				isDefined(image),
			),
			["og:site_name", Main.projectName],
		]

		return <>
			<ReactHelmet>
				{items.map(item => {
					return this.renderItem(...item)
				})}
			</ReactHelmet>
		</>
	}
}