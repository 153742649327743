import React from "react"

import "styles/components/forms/selects/chips-autocomplete"

import { FormElementDefaultProps, FormElementInputLikeProps, InvalidationKey, SelectOption } from "typings/Form"

import Chip from "components/UI/Decorations/Interactive/Chip"
import Preloader from "components/UI/Preloaders/Simple"
import { checkValidation } from "utils/forms"
import { noop } from "utils"

export interface ChipsAutocompleteProps<T>
extends Omit<FormElementDefaultProps<T>, "defaultValue"> {
	label: React.ReactNode
	extraItems?: SelectOption<T>[]
	defaultItems?: string[]
	popular?: SelectOption<T>[]
	popularLabel?: React.ReactNode
	renderInvalidMessage?: FormElementInputLikeProps<any>["renderInvalidMessage"]
	fetch: (
		query: string
	) => Promise<SelectOption<T>[]>
	fetchDefault: (
		ids: string[]
	) => Promise<SelectOption<T>[]>
}

export interface ChipsAutocompleteState<T> {
	selected: SelectOption<T>[]
	autocompleteItems?: SelectOption<T>[]
	searchFocused: boolean
	invalid?: InvalidationKey
}

export default
class ChipsAutocomplete<T = string>
extends React.Component<ChipsAutocompleteProps<T>, ChipsAutocompleteState<T>> {
	static defaultProps
		: Partial<ChipsAutocompleteProps<any>>
		= {
			renderInvalidMessage: key => key
		}

	private debounceTime
		: number
		= 100

	private valueInput?
		: HTMLInputElement

	private lastInvalidationKey
		: InvalidationKey
		= "valueMissing"

	private query
		: string
		= ""

	private getStringValue = (
		items: SelectOption<T>[]
	): string => {
		return items.map(item => item.value).join("|")
	}

	private setSelected = (
		selected: SelectOption<T>[],
	) => {
		if (this.valueInput) {
			const valueSetter = Object.getOwnPropertyDescriptor(
				window.HTMLInputElement.prototype,
				"value",
			)
	
			valueSetter?.set?.call(this.valueInput, this.getStringValue(selected))
			const manualEvent = new Event("change", { bubbles: true })
			this.valueInput.dispatchEvent(manualEvent)
		}

		this.setState({
			selected,
			invalid: undefined,
			searchFocused: false,
		})
	}

	state
		: ChipsAutocompleteState<T>
		= {
			selected: [],
			searchFocused: false,
			autocompleteItems: undefined,
			invalid: undefined,
		}

	searchTimeout
		: number

	searchKey
		: number
		= 0

	componentDidMount() {
		const { defaultItems } = this.props
		if (defaultItems?.length) {
			this.props.fetchDefault(defaultItems)
				.then(items => {
					this.setState({
						selected: items,
					})
				})
		}

		this.fetchItems("")
	}

	clearAutocomplete = () => {
		this.setState({
			autocompleteItems: undefined
		})
	}

	handleSearch = (
		event: React.FormEvent<HTMLInputElement>
	) => {
		const { value } = event.currentTarget
		clearTimeout(this.searchTimeout)

		this.query = value

		this.searchTimeout = window.setTimeout(() => {
			this.fetchItems(value)
		}, this.debounceTime)
	}

	handleSearchFocus = (
		event: React.FocusEvent<HTMLInputElement>
	) => {
		const { type } = event
		this.setState({
			searchFocused: type == "focus"
		})
	}

	handleKeyDown = (
		event: React.KeyboardEvent<HTMLInputElement>
	) => {
		switch (event.key) {
			case "ArrowDown":
			case "ArrowUp":
				event.preventDefault()
		}
	}

	fetchItems = (
		value: string
	) => {
		this.props.fetch(value).then(data => {
			this.setState({
				autocompleteItems: data
			})
		})
	}

	selectItem = (
		item: SelectOption<T>
	) => {
		const { selected } = this.state
		selected.push(item)
		this.query = ""
		this.searchKey += 1
		this.fetchItems("")
		this.setSelected(selected)
	}

	removeItem = (
		code: T
	) => {
		const { selected } = this.state
		const index = selected.findIndex(item => item.value == code)
		if (index >= 0) {
			selected.splice(index, 1)
			this.setSelected(selected)
		}
	}

	handleInvalid = (
		event: React.FormEvent<HTMLInputElement>
	) => {
		event.preventDefault()

		const invalidationKey = checkValidation(event.currentTarget)
		this.lastInvalidationKey = invalidationKey || this.lastInvalidationKey
		
		this.setState({
			invalid: invalidationKey,
		})
	}

	render() {
		const { selected, searchFocused, autocompleteItems } = this.state
		const { popular, popularLabel } = this.props
		const isInvalid = this.state.invalid
		const isFilled = selected.length > 0

		return <>
			<div className={`c-chips-autocomplete ${isInvalid ? "invalid" : ""}`}>
				{this.props.label &&
					<label className="u-input-label">
						<div className="label-content">
							{this.props.label}
						</div>
					</label>
				}
				{this.props.name &&
					<input
						ref={r => this.valueInput = r!}
						name={this.props.name}
						required={this.props.required}
						value={this.getStringValue(selected)}
						data-subtype="array"
						style={{
							display: "none",
						}}
						onChange={noop}
						onInvalid={this.handleInvalid}
					/>
				}
				<div
					className={`u-input ${
						isInvalid ? "invalid" : "" } ${
						isFilled ? "filled" : ""
					}`}
				>
					{selected.map(item => {
						return <Chip
							key={`${item.value}` as string}
							onRemove={() => this.removeItem(item.value)}
						>
							{item.label}
						</Chip>
					})}
					<div className="input-wrapper">
						<input
							key={this.searchKey}
							type="text"
							placeholder="Start typing..."
							onChange={this.handleSearch}
							onKeyDown={this.handleKeyDown}
							onFocus={this.handleSearchFocus}
							onBlur={this.handleSearchFocus}
						/>
					</div>
					{searchFocused &&
						<ChipsAutocompleteDropdown
							query={this.query}
							items={autocompleteItems?.filter(item => {
								return !selected.find(sel => sel.value == item.value)
							})}
							onSelect={this.selectItem}
						/>
					}
				</div>
				<div 
					className={`u-invalidation-message ${
						isInvalid ? "" : "hidden"
					}`}
				>
					{this.props.renderInvalidMessage?.(this.lastInvalidationKey)}
				</div>
				{popular &&
					<div className="show-first">
						{popularLabel &&
							<div className="u-input-label">
								{popularLabel}
							</div>
						}
						<div className="items">
							{popular.map(item => {
								if (selected.find(i => i.value == item.value))
									return null
									
								return <span
									key={`${item.value}`}
									className="u-link"
									onClick={() => this.selectItem(item)}
								>
									{item.label}
								</span>
							})}
						</div>
					</div>
				}
			</div>
		</>
	}
}

interface ChipsAutocompleteDropdownProps {
	items?: SelectOption<any>[]
	query?: string
	onSelect: (item: SelectOption<any>) => void
}

interface ChipsAutocompleteDropdownState {
	highlighted: number
}

class ChipsAutocompleteDropdown
extends React.Component<ChipsAutocompleteDropdownProps, ChipsAutocompleteDropdownState> {
	state
		: ChipsAutocompleteDropdownState
		= {
			highlighted: 0
		}

	componentDidMount() {
		document.addEventListener("keydown", this.handleKeyDown)
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.handleKeyDown)
	}

	handleKeyDown = (
		event: KeyboardEvent
	) => {
		switch (event.key) {
			case "ArrowUp":
				this.highlightPrev()
				break
			case "ArrowDown":
				this.highlightNext()
				break
			case "Enter":
				event.preventDefault()
				this.selectHighlighted()
				break
		}
	}

	highlightPrev = () => {
		const { items } = this.props
		if (!items?.length)
			return

		const { highlighted } = this.state 
		this.setState({
			highlighted: highlighted <= 0
				? items.length - 1
				: highlighted - 1
		})
	}

	highlightNext = () => {
		const { items } = this.props
		if (!items?.length)
			return

		const { highlighted } = this.state
		this.setState({
			highlighted: highlighted >= items.length - 1
				? 0
				: highlighted + 1
		})
	}

	selectHighlighted = () => {
		const { items } = this.props
		if (!items?.length)
			return

		this.props.onSelect(items[this.state.highlighted])
	}

	handleSelect = (
		item: SelectOption<any>
	) => {
		this.props.onSelect(item)
	}
	
	highlightByIndex = (
		index: number
	) => {
		this.setState({
			highlighted: index
		})
	}

	render() {
		const { items } = this.props
		return <>
			<div className="autocomplete">
				{!items
					? <Preloader />
					: !items.length
						? <div className="u-dimmed empty">
							Nothing found
						</div>
						: items.map((item, i) => {
							const isHighlighted = i == this.state.highlighted
							return <div
								key={i}
								className={isHighlighted ? "highlighted" : ""}
								onMouseDown={() => this.handleSelect(item)}
								onMouseEnter={() => this.highlightByIndex(i)}
							>
								{!this.props.query
									? isHighlighted
										? <strong>
											{item.label}
										</strong>
										: item.label
									: item.label.split(this.props.query).map((part, i) => {
										return i == 0
											? part
											: <React.Fragment key={i}>
												<strong>{this.props.query}</strong>{part}
											</React.Fragment>
									})
								}
							</div>
						})
				}
			</div>
		</>
	}
}