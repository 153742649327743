import React from "react"
import { observer } from "mobx-react"
import { Route, RouteComponentProps, Switch } from "react-router-dom"

import Routing from "stores/Routing"
import NotFound from "views/NotFound"

import AccountHome from "./views/Home"
import AccountOrders from "./views/Orders"
import SettingsView from "./views/Settings"

export interface AccountViewProps
extends RouteComponentProps {

}

export interface AccountViewState {

}

@observer
export default
class AccountView
extends React.Component<AccountViewProps, AccountViewState> {
	render() {
		return <>
			<Switch>
				<Route
					path={[
						Routing.patterns.account.settings.home,
						Routing.patterns.account.settings.tab,
					]}
					exact
					render={(props: RouteComponentProps<{ tab: string }>) => {
						return <SettingsView {...props} />
					}}
				/>
				<Route
					path={Routing.patterns.account.home}
					exact
					component={AccountHome}
				/>
				<Route
					path={[
						Routing.patterns.account.orders.item,
						Routing.patterns.account.orders.list,
					]}
					exact
					component={AccountOrders}
				/>
				
				<Route
					component={NotFound}
				/>
			</Switch>
		</>
	}
}