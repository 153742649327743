import React from "react"

import "styles/components/ui/ordering/vertical"
import { Coords2D } from "typings/Geometry"
import { Scroller } from "utils/entities/Scroller"

export interface OrderingVerticalRenderProps {
	id: string
	trigger: React.ReactNode
}

export interface OrderingVerticalProps {
	defaultOrder: string[]
	draggedLeftOffset?: number
	classNames?: {
		item?: string
		placemaker?: string
	}
	scrollableParentSelector?: string
	onOrderChange?: (newOrder: string[]) => void
	children: (props: OrderingVerticalRenderProps) => React.ReactNode
}

export interface OrderingVerticalState {
	currentOrder: string[]
	draggedHeight?: number
	dropToIndex?: number
	position?: Coords2D
}

export default
class OrderingVertical
extends React.Component<OrderingVerticalProps, OrderingVerticalState> {
	private scrollTriggerOffset
		: number
		= 40

	private scrollFrame
		: number

	private isScrolling
		: boolean
		= false

	private draggedIndex?
		: number

	private get isDragged(): boolean {
		return typeof this.draggedIndex == "number"
	}

	state
		: OrderingVerticalState
		= {
			currentOrder: [...this.props.defaultOrder],
			draggedHeight: undefined,
			dropToIndex: undefined,
			position: undefined,
		}

	private renderTrigger = (
		index: number
	): React.ReactNode => {
		return <>
			<div
				className="ovi-trigger"
				onMouseDown={event => this.handleDragStart(event, index)}
				onPointerDown={event => this.handleDragStart(event, index)}
			>
				<i className="fas fa-grip-vertical" />
			</div>
		</>
	}

	private renderPlacemaker = (
		index: number
	) => {
		const { dropToIndex } = this.state
		const { draggedIndex } = this
		
		const isDragged = [index, index - 1].includes(draggedIndex!)
		const isHighlighted = dropToIndex == index

		return <>
			<div
				className={`ovi-placemaker ${
					this.props.classNames?.placemaker || "" } ${
					isDragged ? "intact" : "" } ${
					isHighlighted ? "highlighted" : ""
				}`}
				style={{
					height: this.state.draggedHeight,
				}}
				onMouseEnter={() => this.highlightPlacemaker(index)}
				onPointerEnter={() => this.highlightPlacemaker(index)}
				onMouseLeave={() => this.unhighlightPlacemaker()}
				onPointerLeave={() => this.unhighlightPlacemaker()}
			/>
		</>
	}

	componentDidMount() {
		document.addEventListener("mousemove", this.handleDragMove)
		document.addEventListener("mouseup", this.handleDragEnd)

		document.addEventListener("pointermove", this.handleDragMove)
		document.addEventListener("pointerup", this.handleDragEnd)
		document.addEventListener("pointercancel", this.handleDragEnd)
	}

	componentWillUnmount() {
		document.removeEventListener("mousemove", this.handleDragMove)
		document.removeEventListener("mouseup", this.handleDragEnd)

		document.removeEventListener("pointermove", this.handleDragMove)
		document.removeEventListener("pointerup", this.handleDragEnd)
		document.removeEventListener("pointercancel", this.handleDragEnd)

		this.stopScrolling()
	}

	highlightPlacemaker = (
		index: number
	) => {
		if (!this.isDragged || this.isScrolling)
			return
			
		this.setState({
			dropToIndex: index,
		})
	}

	unhighlightPlacemaker = () => {
		this.setState({
			dropToIndex: undefined,
		})
	}

	startScrolling = (
		value: number,
		element?: HTMLElement,
	) => {
		window.cancelAnimationFrame(this.scrollFrame)

		this.isScrolling = true

		const scroller = new Scroller(element)
		const scroll = () => {
			scroller.scrollTop += value
			this.scrollFrame = window.requestAnimationFrame(scroll)
		}

		scroll()
	}

	stopScrolling = () => {
		window.cancelAnimationFrame(this.scrollFrame)
		this.isScrolling = false
	}

	handleDragStart = (
		event: React.MouseEvent | React.PointerEvent,
		draggedIndex: number,
	) => {
		document.body.classList.add("dragged")

		const parent = event.currentTarget.closest(".ov-item")
		if (parent)
			this.setState({
				draggedHeight: parent.getBoundingClientRect().height
			})

		const {
			clientX: x,
			clientY: y,
		} = event.nativeEvent

		this.draggedIndex = draggedIndex

		if (event.nativeEvent instanceof PointerEvent)
			event.currentTarget.releasePointerCapture(event.nativeEvent.pointerId)

		this.setState({
			position: { x, y },
		})
	}

	handleDragMove = (
		event: MouseEvent | PointerEvent
	) => {
		if (!this.isDragged)
			return

		event.preventDefault()

		const {
			clientX: x,
			clientY: y,
		} = event

		const { scrollableParentSelector } = this.props

		if (scrollableParentSelector) {
			// FEATURE custom scroll for ordering not inside body
		} else {
			// FEATURE scroll X if needed
			if (y <= this.scrollTriggerOffset) {
				const offset = Math.min(this.scrollTriggerOffset - y, this.scrollTriggerOffset)
				this.startScrolling((-offset / 4) | 0)
			} else if (y >= window.innerHeight - this.scrollTriggerOffset) {
				const offset = Math.min(y - (window.innerHeight - this.scrollTriggerOffset), this.scrollTriggerOffset)
				this.startScrolling((offset / 4) | 0)

			} else
				this.stopScrolling()
		}

		this.setState({
			position: {
				x,
				y,
			}
		})
	}

	handleDragEnd = () => {
		if (!this.isDragged)
			return
		
		document.body.classList.remove("dragged")
		const { dropToIndex } = this.state	
		if (typeof dropToIndex != "undefined")
			this.moveItem(this.draggedIndex!, dropToIndex)

		this.draggedIndex = undefined
		this.stopScrolling()
		this.setState({
			position: undefined,
			dropToIndex: undefined,
			draggedHeight: undefined,
		})
	}

	moveItem = (
		from: number,
		to: number,
	) => {
		let { currentOrder } = this.state

		const [ item ] = currentOrder.splice(from, 1, "__placeholder__")
		currentOrder.splice(to, 0, item)
		currentOrder = currentOrder.filter(item => item != "__placeholder__")

		this.setState({
			currentOrder,
		}, () => {
			this.props.onOrderChange?.(currentOrder)
		})
	}

	render() {
		const { draggedLeftOffset = 0 } = this.props
		const { currentOrder, draggedHeight = 0 } = this.state
		const { x, y } = this.state.position || {}

		return <>
			<div className="c-ordering-vertical">
				{currentOrder.map((id, i) => {
					const isDragged = i == this.draggedIndex
					return <React.Fragment key={id}>
						{i == 0 &&
							this.renderPlacemaker(0)
						}
						<div
							className={`ov-item ${
								isDragged ? "dragged" : ""} ${
								this.props.classNames?.item || "" 
							}`}
							style={isDragged && {
								height: draggedHeight,
							} || undefined}
						>
							<div
								className="ovi-inner-wrapper"
								style={isDragged
									? {
										position: "fixed",
										left: x! - draggedLeftOffset,
										top: y! - draggedHeight! / 2,
									}
									: {}
								}
							>
								{this.props.children({
									id,
									trigger: this.renderTrigger(i)
								})}
							</div>
						</div>
						{this.renderPlacemaker(i + 1)}
					</React.Fragment>
				})}
			</div>
		</>
	}
}