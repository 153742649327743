import React from "react"
import { observer } from "mobx-react"

import "styles/views/user/views/profile/components/videos/all"

import { UserVideo } from "typings/api/User"

import SimpleModal from "components/Modals/Simple"
import VideoGrid from "components/Media/VideoGrid"

export interface UserAllVideosProps {
	videos: UserVideo[]
	onClose: () => void
}

export interface UserAllVideosState {

}

@observer
export default
class UserAllVideos
extends React.Component<UserAllVideosProps, UserAllVideosState> {
	render() {
		const { videos } = this.props
		return <>
			<SimpleModal
				closeOnOutsideClick
				onClose={this.props.onClose}
				classNames={{
					innerWrapper: "c-user-all-videos c-user-profile-section-modal"
				}}
			>
				{close => {
					return <>
						<div className="upsm-header">
							<h1>
								Videos ({videos.length})
							</h1>
							<div
								className="u-button"
								onClick={close}
							>
								<div className="ub-inner-wrapper">
									<i className="fas fa-times" />
								</div>
							</div>
						</div>
						<VideoGrid
							videos={videos}
						/>
					</>
				}}
			</SimpleModal>
		</>
	}
}