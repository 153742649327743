import React from "react"
import { EasingFunction, EasingFunctions, FRAME_DURATION_60 } from "utils/animation"

export interface SmoothCounterProps {
	duration?: number
	timingFunction?: EasingFunction
	children: number
}

export interface SmoothCounterState {
	currentValue: number
}

export default
class SmoothCounter
extends React.Component<SmoothCounterProps, SmoothCounterState> {
	static defaultProps
		: Partial<SmoothCounterProps>
		= {
			duration: 250,
			timingFunction: "easeOutQuart"
		}

	state
		: SmoothCounterState
		= {
			currentValue: this.props.children
		}

	keyframe
		: number

	startValue
		: number
		= this.props.children

	endValue
		: number
		= this.props.children

	componentDidUpdate(prevProps: SmoothCounterProps) {
		const { children: newValue } = this.props
		const { children: prevValue } = prevProps
		
		if (newValue != prevValue) {
			this.stopAnimation()
			this.startAnimation(newValue)
		}
	}

	startAnimation = (
		endValue: number
	) => {
		this.startValue = this.state.currentValue
		this.endValue = endValue
		this.animate()
	}

	stopAnimation = () => {
		cancelAnimationFrame(this.keyframe)
	}

	animate = (
		timingRel: number = 0
	) => {
		if (timingRel >= 1) {
			this.setState({
				currentValue: this.endValue
			})
		} else {
			const { duration, timingFunction } = this.props
			const diff = this.endValue - this.startValue
			this.setState({
				currentValue: this.startValue + diff * EasingFunctions[timingFunction!](timingRel)
			})

			this.keyframe = requestAnimationFrame(() => this.animate(timingRel + FRAME_DURATION_60 / duration!))
		}
	}

	render() {
		return <>
			{this.state.currentValue | 0}
		</>
	}
}