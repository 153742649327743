import { action, computed, makeObservable, observable } from "mobx"

import api from "api"

import { PublicUserData } from "typings/api/User"

class UserItemStore {
	private itemReq
		= api.account.item()

	private loading
		: boolean
		= false

	@observable
	private _error?
		: {
			code: number
			message: string
		}

	@observable
	private _user?
		: PublicUserData

	@action
	private setUserData = (
		data: PublicUserData
	) => {
		this._user = data
	}

	@action
	private setError = (
		code: number = 502,
		message: string = "Unexpected error",
	) => {
		this._error = {
			code,
			message,
		}
	}

	constructor() {
		makeObservable(this)
	}

	@computed
	get user() {
		return this._user
	}

	@computed
	get error() {
		return this._error
	}

	setUser = (
		accountId: string
	) => {
		if (this.loading || this._user)
			return

		this.loading = true
		this.itemReq.request({ accountId })
			.then(response => {
				this.setUserData(response.data.account)
			})
			.catch(error => {
				if (error.code == "ABORTED")
					return
				this.setError(error?.status, error?.message)
			})
			.finally(() => {
				this.loading = false
			})
	}

	setDefault = (
		defaultUser?: PublicUserData
	) => {
		if (defaultUser)	
			this.setUserData(defaultUser)
	}

	@action
	clearStore = () => {
		this._user = undefined
		this._error = undefined
		this.itemReq.abort()
	}
}

export default new UserItemStore()