import React from "react"
import { Link } from "react-router-dom"
import Routing from "stores/Routing"

import "styles/views/homepage/components/sections/call-to-action"

export interface HomepageCallToActionSectionProps {
	
}

export interface HomepageCallToActionSectionState {

}

export default
class HomepageCallToActionSection
extends React.Component<HomepageCallToActionSectionProps, HomepageCallToActionSectionState> {
	render() {
		return <>
			<section className="c-homepage-call-to-action ho-wrapper">
				<div className="hcta-fancy-background">
					<div />
					<div />
					<div />
					<div />
				</div>
				<div className="hcta-content">
					<h2>
						Create fun videos &mdash;<br />
						get money
					</h2>

					<Link
						to={Routing.compile.signup()}
						className="ho-action white"
					>
						Get started
					</Link>
				</div>
			</section>
		</>
	}
}