type ErrorsList<T extends string> = {
	[key in T]: {
		default: string
		[key: string]: string
	}
}

type AuthErrors =
	| "login"

type OrderErrors =
	| "create"

export const authErrors: ErrorsList<AuthErrors> = {
	login: {
		default: "Unexpected error",
		400: "Wrong login and/or password",
	}
}

export const orderErrors: ErrorsList<OrderErrors> = {
	create: {
		default: "Unexpected error"
	}
}