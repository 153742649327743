import React from "react"
import { RouteComponentProps } from "react-router-dom"
import * as tooltip from "@sounds.of.limbo/tooltip"

import "styles/views/playground"
import ImageCropperTest from "./components/ImageCropperTest"

export interface PlaygroundProps
extends RouteComponentProps<any> {

}

export interface PlaygroundState {

}

export default
class Playground
extends React.Component<PlaygroundProps, PlaygroundState> {

	render() {
		return <>
			<main className="v-playground">
				<tooltip.Element
					element="div"
					elementProps={{
						className: "u-button default",
					}}
					content={
						<>
							<strong>Cool</strong> <i>tooltip</i> has been shown on hover
						</>
					}
				>
					<div className="ub-inner-wrapper">
						<span>
							Hover me!
						</span>
					</div>
				</tooltip.Element>
				<ImageCropperTest />
			</main>
		</>
	}
}