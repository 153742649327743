import React from "react"

import "styles/components/modals/simple"
import "styles/components/modals/confirm"

export interface ConfirmModalProps
extends PropsWithChildren {
	title: React.ReactNode
	classNames?: {
		mainWindow?: string
		outerWrapper?: string
		innerWrapper?: string
	}
	onConfirm: () => void
	onCancel: () => void
}

export interface ConfirmModalState {

}

export default
class ConfirmModal
extends React.Component<ConfirmModalProps, ConfirmModalState> {
	render() {
		const { classNames } = this.props
		return <>
			<div
				className={`c-simple-modal c-confirm-modal u-fade-${
					/* fadeOut */ false ? "out" : "in" } ${
					classNames?.mainWindow || ""
				}`}
				// onAnimationEnd={this.handleAnimationEnd}
			>
				<div className={`sm-outer-wrapper ${classNames?.outerWrapper || ""}`}>
					<div 
						// ref={r => this.innerWrapperElement = r!}
						className={`sm-inner-wrapper ${classNames?.innerWrapper || ""}`}
					>
						<h1>
							{this.props.title}
						</h1>
						<p className="content">
							{this.props.children}
						</p>
						<div className="cm-actions">
							<div
								className="u-button outline smaller"
								onClick={this.props.onCancel}
							>
								<div className="ub-inner-wrapper">
									<span>No</span>
								</div>
							</div>
							
							<div
								className="u-button default smaller"
								onClick={this.props.onConfirm}
							>
								<div className="ub-inner-wrapper">
									<span>Yes</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	}
}