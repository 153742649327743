import React from "react"
import { RouteComponentProps, Switch, Route } from "react-router"

import Routing from "stores/Routing"

import NotFound from "views/NotFound"
import AccountOrderItem from "./views/Item"
import AccountOrdersList from "./views/List"

export interface AccountOrdersProps
extends RouteComponentProps<any> {

}

export interface AccountOrdersState {

}

export default
class AccountOrders
extends React.Component<AccountOrdersProps, AccountOrdersState> {
	render() {
		return <>
			<Switch>
				<Route
					path={Routing.patterns.account.orders.list}
					exact
					component={AccountOrdersList}
				/>
				<Route
					path={Routing.patterns.account.orders.item}
					exact
					render={(props: RouteComponentProps<{ order: string }>) => {
						return <AccountOrderItem
							key={props.match.params.order}
							{...props}
						/>
					}}
				/>
				<Route
					component={NotFound}
				/>
			</Switch>
		</>
	}
}