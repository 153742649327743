import React from "react"
import { Link } from "react-router-dom"

import { SignInRequestData } from "typings/api/SignIn"
import { LoginModalFormProps } from ".."

import Auth from "stores/Auth"
import Main from "stores/Main"
import Routing from "stores/Routing"

import { authErrors } from "consts/text/api"
import { passwordPattern } from "consts/patterns"

import { getValidationForKey } from "utils/validation"

import Form from "components/Forms/Form"
import SimpleInput from "components/Forms/Inputs/Simple"
import SimplePreloader from "components/UI/Preloaders/Simple"

export interface LoginFormProps
extends LoginModalFormProps {
	onForgotPassword: () => void
}

export interface LoginFormState {
	loading: boolean
	error?: string	
}

export default
class LoginForm
extends React.Component<LoginFormProps, LoginFormState> {
	state
		: LoginFormState
		= {
			loading: false,
			error: undefined,
		}

	handleSubmit = (
		data: SignInRequestData,
		closeModal: () => void,
	) => {
		if (this.state.loading)
			return

		this.props.setOutsideClosableStatus(false)
		this.props.onError()
		this.setState({
			loading: true,
			error: undefined,
		})

		Auth.login(data.email, data.password)
			.then(() => {
				closeModal()
			})
			.catch((errorData) => {
				const { login } = authErrors
				const error = login[errorData.status] || login.default

				this.props.setOutsideClosableStatus(true)
				this.props.onError(error)

				this.setState({
					loading: false,
					error,
				})
			})
	}

	render() {
		const { loading, error } = this.state
		return <div className="lm-content u-fade-in">
			<h1>
				Sign in to {Main.projectName}
			</h1>
			<Form
				className="login-form"
				onSubmit={data => this.handleSubmit(data, this.props.close)}
			>
				<SimpleInput
					label="Email"
					placeholder="johndoe@myemail.com"
					type="email"
					name="email"
					required
					disabled={loading}
					renderInvalidMessage={getValidationForKey("email")}
				/>
				<SimpleInput
					label="Password"
					type="password"
					name="password"
					required
					disabled={loading}
					pattern={passwordPattern}
					labelEndContent={
						<span
							className="lm-forgot-password"
							onClick={this.props.onForgotPassword}
						>
							Forgot password?
						</span>
					}
					renderInvalidMessage={getValidationForKey("password")}
				/>
				<p className={`u-invalidation-message independent ${error ? "visible" : ""} center`}>
					<span>
						<span>
							{error || <>&nbsp;</>}
						</span>
					</span>
				</p>
				<div className="u-recaptcha-disclaimer">
					This site is protected by reCAPTCHA and the Google <a
						href="https://policies.google.com/privacy"
						target="_BLANK"
						rel="noopener"
						className="u-link"
					>
						Privacy Policy
					</a> and <a
						href="https://policies.google.com/terms"
						target="_BLANK"
						rel="noopener"
						className="u-link"
					>
						Terms of Service
					</a> apply.
				</div>
				<div className="u-actions column">
					<button className={`u-button default ${loading ? "disabled" : ""}`}>
						<div className="ub-inner-wrapper">
							{loading
								? <SimplePreloader color="white" />
								: <span>
									Sign in
								</span>
							}
						</div>
					</button>
				</div>
				<p className="u-dimmed-text">
					Don't have an account? <Link
						className="u-link"
						to={Routing.compile.signup()}
						onClick={this.props.close}
					>
						Sign up
					</Link>
				</p>
			</Form>
		</div>
	}
}