import React from "react"
import { observer } from "mobx-react"

import UsersList, { UsersListStore } from "stores/UsersList"

import { isDefined } from "utils/type"

import UserCardSearch from "components/Global/User/cards/Search"

export interface UserListComponentProps {

}

export interface UserListComponentState {
	width?: number
}

@observer
export default
class UserListComponent
extends React.Component<UserListComponentProps, UserListComponentState> {
	private wrapper?
		: HTMLDivElement

	state
		: UserListComponentState
		= {
			width: undefined,
		}

	componentDidMount() {
		this.handleResize()
		window.addEventListener("resize", this.handleResize)
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleResize)
	}

	handleResize = () => {
		if (!this.wrapper)
			return

		const { offsetWidth } = this.wrapper
		const minWidth = 180
		const spacing = 30
		const widthWithSpacing = minWidth + spacing

		let countFound = false
		let count: number = 1

		while (!countFound && count < 100) {
			const totalCardsWidth = minWidth * count
			const widthExcludePadding = offsetWidth - spacing * count

			if (widthExcludePadding - totalCardsWidth < widthWithSpacing)
				countFound = true
			else
				count++
		}

		this.setState({
			width: (offsetWidth - spacing * count) / count,
		})
	}

	render() {
		const { list } = UsersList
		const { width } = this.state

		return <>
			<div
				ref={r => this.wrapper = r!}
				className="users-list"
				style={!isDefined(width)
					? undefined
					: {
						"--users-list-item-size": `${width}px`
					} as React.CSSProperties
				}
			>
				{list?.map((user, i) => {
					return <UserCardSearch
						key={user.id}
						user={user}
						index={i % UsersListStore.PAGE_SIZE}
					/>
				})}
			</div>
		</>
	}
}